import {
  Box,
  Button,
  Container,
  Modal,
  Stack,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getApplicationById,
  approveGrantMilestone,
  rejectGrantMilestone,
} from "../../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setToaster,
  toggleAppLoading,
  setAppLoadingText,
} from "../../../store/slices/LoadinAndNotifSlice";
import { grantApplicationView } from "./style";
import moment from "moment";
import Wallets from "../../../wallets";
import algosdk from "algosdk";
import { client, peraWallet } from "../../../../src/wallets/algorand";
import { useWallet } from "@txnlab/use-wallet";
import {
  approveGrantMilestone1Blockchain,
  approveGrantMilestone2Blockchain,
  rejectGrantMilestoneBlockchain,
} from "../../../blockchain";
import { ContentStyle, titleStyle } from "../../auth/login/style";
import {
  modalContainer,
  modalWrapper,
} from "../../../components/common/modals/algoDonationModalStyles";
import { useTranslation } from "react-i18next";

const URL = process.env.REACT_APP_URL;

export interface milestone {
  _id: string;
  amount: number;
  deadline: string;
  description: string;
  status: string;
  title: string;
  milestoneId: string;
  standardReport: undefined | any;
}
export interface grantApplicationRes {
  document: string;
  donorsGoals: string;
  donorsObjective: string;
  facebookURL: string;
  impact: string;
  instagramURL: string;
  milestones: Array<milestone>;
  previousExperience: string;
  status: string;
  twitterURL: string;
}
const GrantMilestoneView = () => {
  const { activeAccount, providers, signTransactions } = useWallet();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { grantApplicationId, applicationAppId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [application, setApplication] = useState<any>();
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [selectedMilestoneId, setSelectedMilestoneId] = useState("");
  const [comment, setComment] = useState("");
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const userslice = useAppSelector((state) => state.userReducer);

  const dispatch = useAppDispatch();

  const fetchMilestoneDetails = async () => {
    try {
      setLoading(true);
      const res = await getApplicationById(grantApplicationId);

      if (res.success) {
        setApplication(res.data.application);
      } else {
        const toastPayload = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toastPayload));
      }
    } finally {
      setLoading(false);
    }
  };

  // const handleApproveMilestoneBlockchain = async (index: number) => {
  //   const myAlgoConnect = new MyAlgoConnect();

  //   if (!walletClient.connected()) {
  //     await walletClient.connect();
  //   }

  //   const approveGrantInfo = {
  //     wallet_address: walletClient.getDefaultAccount(),
  //     user_app_id: parseInt(userslice.userAppId),
  //     application_app_id: applicationAppId ? parseInt(applicationAppId) : "",
  //   };
  //   const unsignedTxn =
  //     index === 0
  //       ? await approveGrantMilestone1Blockchain(approveGrantInfo)
  //       : await approveGrantMilestone2Blockchain(approveGrantInfo);

  //   if (!unsignedTxn.success) {
  //     const toastPaylaod = {
  //       text: t("unableToSendDataToBlockchain"),
  //       success: false,
  //       active: true,
  //     };
  //     dispatch(setToaster(toastPaylaod));
  //     dispatch(setAppLoadingText(""));
  //     dispatch(toggleAppLoading(false));
  //     return {
  //       success: false,
  //     };
  //   }

  //   try {
  //     dispatch(setAppLoadingText(t("signingTransaction")));
  //     const signedTxns: any = await myAlgoConnect.signTransaction(
  //       unsignedTxn.data.map((txn: any) => txn.txn)
  //     );

  //     const signed = [];
  //     signed.push(signedTxns[0].blob);

  //     let tx = await client.sendRawTransaction(signed).do();
  //     dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
  //     let confirmedTxn = await algosdk.waitForConfirmation(client, tx.txId, 4);
  //     dispatch(setAppLoadingText(""));
  //     if (tx?.txId) {
  //       return {
  //         success: true,
  //         transactionId: tx.txId,
  //       };
  //     } else {
  //       return {
  //         success: true,
  //       };
  //     }
  //   } catch (e: any) {
  //     const toastPaylaod = {
  //       text: e.message,
  //       success: false,
  //       active: true,
  //     };
  //     dispatch(setToaster(toastPaylaod));
  //     dispatch(setAppLoadingText(""));
  //     dispatch(toggleAppLoading(false));
  //     return {
  //       success: false,
  //     };
  //   }
  // };

  const handleApproveMilestoneBlockchain = async (index: number) => {
    const approveGrantInfo = {
      wallet_address: activeAccount?.address,
      user_app_id: parseInt(userslice.userAppId),
      application_app_id: applicationAppId ? parseInt(applicationAppId) : "",
    };
    const unsignedTxn =
      index === 0
        ? await approveGrantMilestone1Blockchain(approveGrantInfo)
        : await approveGrantMilestone2Blockchain(approveGrantInfo);

    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: t("unableToSendDataToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      // dispatch(setAppLoadingText(t("signingTransaction")));
      dispatch(toggleAppLoading(false));
      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);
      dispatch(toggleAppLoading(false));

      const signed = [];
      signed.push(signedTxn[0]);

      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();
      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );
      // dispatch(setAppLoadingText(""));
      if (transationRes?.txId) {
        return {
          success: true,
          transactionId: transationRes.txId,
        };
      } else {
        return {
          success: true,
        };
      }
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  // const handleRejectMilestoneBlockchain = async () => {
  //   const myAlgoConnect = new MyAlgoConnect();

  //   if (!walletClient.connected()) {
  //     await walletClient.connect();
  //   }

  //   const rejectGrantInfo = {
  //     wallet_address: walletClient.getDefaultAccount(),
  //     user_app_id: parseInt(userslice.userAppId),
  //     application_app_id: applicationAppId ? parseInt(applicationAppId) : "",
  //   };
  //   const unsignedTxn = await rejectGrantMilestoneBlockchain(rejectGrantInfo);

  //   if (!unsignedTxn.success) {
  //     const toastPaylaod = {
  //       text: t("unableToSendDataToBlockchain"),
  //       success: false,
  //       active: true,
  //     };
  //     dispatch(setToaster(toastPaylaod));
  //     dispatch(setAppLoadingText(""));
  //     dispatch(toggleAppLoading(false));
  //     return {
  //       success: false,
  //     };
  //   }

  //   try {
  //     dispatch(setAppLoadingText(t("signingTransaction")));
  //     const signedTxns: any = await myAlgoConnect.signTransaction(
  //       unsignedTxn.data.map((txn: any) => txn.txn)
  //     );

  //     const signed = [];
  //     signed.push(signedTxns[0].blob);

  //     let tx = await client.sendRawTransaction(signed).do();
  //     dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
  //     let confirmedTxn = await algosdk.waitForConfirmation(client, tx.txId, 4);
  //     dispatch(setAppLoadingText(""));
  //     if (tx?.txId) {
  //       return {
  //         success: true,
  //         transactionId: tx.txId,
  //       };
  //     } else {
  //       return {
  //         success: true,
  //       };
  //     }
  //   } catch (e: any) {
  //     const toastPaylaod = {
  //       text: e.message,
  //       success: false,
  //       active: true,
  //     };
  //     dispatch(setToaster(toastPaylaod));
  //     dispatch(setAppLoadingText(""));
  //     dispatch(toggleAppLoading(false));
  //     return {
  //       success: false,
  //     };
  //   }
  // };
  const handleRejectMilestoneBlockchain = async () => {
    const rejectGrantInfo = {
      wallet_address: activeAccount?.address,
      user_app_id: parseInt(userslice.userAppId),
      application_app_id: applicationAppId ? parseInt(applicationAppId) : "",
    };
    const unsignedTxn = await rejectGrantMilestoneBlockchain(rejectGrantInfo);

    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: t("unableToSendDataToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(setAppLoadingText(t("signingTransaction")));
      dispatch(toggleAppLoading(false));
      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);
      dispatch(toggleAppLoading(true));

      const signed = [];
      signed.push(signedTxn[0]);

      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();
      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );
      // dispatch(setAppLoadingText(""));
      if (transationRes?.txId) {
        return {
          success: true,
          transactionId: transationRes.txId,
        };
      } else {
        return {
          success: true,
        };
      }
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleApproveMilestone = async (id: any, index: number) => {
    dispatch(toggleAppLoading(true));
    const blockchainRes = await handleApproveMilestoneBlockchain(index);
    if (!blockchainRes.success) {
      return;
    }
    const res = await approveGrantMilestone({
      milestoneId: id,
      transactionId: blockchainRes.transactionId,
    });
    if (res.success) {
      const toasterData = {
        active: true,
        text: "Milestone approved",
        success: true,
      };
      await fetchMilestoneDetails();
      dispatch(setToaster(toasterData));
    } else {
      const toasterData = {
        active: true,
        text: res.data.message,
        success: false,
      };
      dispatch(setToaster(toasterData));
    }
    // dispatch(toggleAppLoading(false));
  };
  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };
  const handleRejectMilestone = async () => {
    dispatch(toggleAppLoading(true));
    const blockchainRes = await handleRejectMilestoneBlockchain();
    if (!blockchainRes.success) {
      return;
    }
    const res = await rejectGrantMilestone({
      milestoneId: selectedMilestoneId,
      comment,
    });
    if (res.success) {
      const toasterData = {
        active: true,
        text: "Milestone reject",
        success: true,
      };
      await fetchMilestoneDetails();
      dispatch(setToaster(toasterData));
      setSelectedMilestoneId("");
    } else {
      const toasterData = {
        active: true,
        text: res.data.message,
        success: false,
      };
      dispatch(setToaster(toasterData));
    }
    // dispatch(toggleAppLoading(false));
  };

  useEffect(() => {
    fetchMilestoneDetails();
  }, []);

  useEffect(() => {
    dispatch(toggleAppLoading(loading));
  }, [loading]);

  return (
    <>
      <Container sx={grantApplicationView}>
        <div className="container-fluid">
          <h1>{t("grantMilestoneReportInfo")}</h1>
          <Stack spacing={2}>
            {application?.milestones && application?.milestones?.length > 0 ? (
              application?.milestones.map(
                (item: milestone, index: number) =>
                  item.standardReport && (
                    <Box mb={1} key={index}>
                      <Typography
                        variant="h4"
                        align="left"
                        sx={{
                          marginBottom: "20px",
                        }}
                      >
                        {t("milestone")} {index + 1} {t("report")}
                      </Typography>
                      <Typography
                        variant="h6"
                        mb={1}
                        align="left"
                        fontSize={20}
                      >
                        <b>{t("title")} - </b>
                        {item?.title}
                      </Typography>
                      <Typography
                        variant="h6"
                        mb={1}
                        align="left"
                        fontSize={20}
                      >
                        <b>{t("description")} - </b>
                        {item?.description}
                      </Typography>
                      <Typography
                        variant="h6"
                        mb={1}
                        align="left"
                        fontSize={20}
                        style={{ display: "flex" }}
                      >
                        <b>{t("submissionStatus")} - </b>
                        <Box
                          sx={{
                            paddingLeft: "0.175rem",
                            color:
                              item.status === "pending" ||
                              item.status === "in-progress"
                                ? "#FFA500"
                                : item.status === "completed" ||
                                  item.status === "report-submitted"
                                ? "#00FF00"
                                : item.status === "rejected"
                                ? "#ff0000"
                                : "#000",
                            textTransform: "capitalize",
                          }}
                        >
                          {item.status}
                        </Box>
                      </Typography>
                      <Typography
                        variant="h6"
                        mb={1}
                        align="left"
                        fontSize={20}
                      >
                        <b>{t("submissionDeadline")} - </b>
                        {moment(item?.deadline).format("MMM DD YYYY")}
                      </Typography>
                      <Typography
                        variant="h6"
                        mb={1}
                        align="left"
                        fontSize={20}
                      >
                        <b>{t("objectiveMet")} -</b>
                        {item.standardReport.objectiveMeet ? "Yes" : "No"}
                      </Typography>
                      <Typography
                        variant="h6"
                        mb={1}
                        align="left"
                        fontSize={20}
                      >
                        <b>{t("comments")} - </b>
                        {item.standardReport.comments}
                      </Typography>
                      <Typography
                        variant="h6"
                        mb={1}
                        align="left"
                        fontSize={20}
                      >
                        <b>
                          {item.standardReport.evidenceFiles[0].includes(
                            "mp4"
                          ) ||
                          item.standardReport.evidenceFiles[0].includes("webm")
                            ? "Submitted Video -"
                            : "Submitted Image -"}
                        </b>

                        {item.standardReport.evidenceFiles[0].includes("mp4") ||
                        item.standardReport.evidenceFiles[0].includes(
                          "webm"
                        ) ? (
                          <video
                            style={
                              item.standardReport.evidenceFiles[0]
                                ? { maxWidth: "98%", margin: 20 }
                                : { display: "none" }
                            }
                            src={`${URL}/api/file/${item.standardReport.evidenceFiles[0]}`}
                            controls
                            width={"500px"}
                            height={"auto"}
                            autoPlay={false}
                          />
                        ) : (
                          <Grid container spacing={2}>
                            <Grid item xl={4} lg={6}>
                              <img
                                src={`${URL}/api/file/${item.standardReport.evidenceFiles[0]}`}
                                alt="campaign"
                                width={300}
                                style={{ maxWidth: "100%", margin: 20 }}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Typography>
                      <Typography
                        variant="h6"
                        mb={1}
                        align="left"
                        fontSize={20}
                      >
                        <b>{t("supportingDocuments")} - </b>
                        <a
                          href={`${URL}/api/file/${item.standardReport.supportingDocs[0]}`}
                          style={{ textDecoration: "none" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Typography
                            variant="h6"
                            align="left"
                            fontSize={20}
                            ml={2}
                          >
                            {t("clickToDownload")}
                          </Typography>
                        </a>
                      </Typography>
                      <Button
                        variant="contained"
                        color="error"
                        disabled={item?.status !== "report-submitted"}
                        onClick={() => setSelectedMilestoneId(item?._id)}
                      >
                        {t("rejectMilestone")} {index + 1} {t("report")}
                      </Button>
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ ml: 4 }}
                          disabled={item?.status !== "report-submitted"}
                          onClick={handleShowWalletOptions}
                        >
                          {t("approveMilestone")} {index + 1} {t("report")}
                        </Button>
                        {showWalletButtons && (
                          <Wallets
                            open={showWalletButtons}
                            handleClose={() => setShowWalletButtons(false)}
                            handleSubmit={() =>
                              handleApproveMilestone(item?._id, index)
                            }
                          />
                        )}
                      </>
                    </Box>
                  )
              )
            ) : (
              <></>
            )}
          </Stack>
        </div>
      </Container>
      <Modal
        open={selectedMilestoneId !== ""}
        onClose={() => setSelectedMilestoneId("")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalWrapper}>
          <Box sx={modalContainer}>
            <Box sx={titleStyle}>
              <Typography variant="h4" align="center" mb={2}>
                {t("reasonToRejectMilestoneReport")}
              </Typography>
            </Box>
            <Box sx={ContentStyle}>
              <TextField
                fullWidth
                hiddenLabel
                type={"textarea"}
                multiline
                minRows={4}
                id="filled-hidden-label-small"
                placeholder={t("comment") || ""}
                variant="outlined"
                onChange={(e) => setComment(e.target.value)}
              />
              <Box
                sx={{
                  textAlign: "center",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                mt={3}
                mb={3}
              >
                <Button
                  onClick={() => setSelectedMilestoneId("")}
                  variant="contained"
                  color="error"
                >
                  {t("cancel")}
                </Button>
                <>
                  <Button
                    onClick={() => setShowWalletButtons(true)}
                    variant="contained"
                    color="primary"
                    disabled={comment === ""}
                  >
                    {t("rejectApplication")}
                  </Button>
                  {showWalletButtons && (
                    <Wallets
                      open={showWalletButtons}
                      handleClose={() => setShowWalletButtons(false)}
                      handleSubmit={handleRejectMilestone}
                    />
                  )}
                </>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default GrantMilestoneView;
