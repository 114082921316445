import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  TextareaAutosize,
  Button,
  Menu,
  FormLabel,
  Select,
  Stack,
  SelectChangeEvent,
  IconButton,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import {
  getEditCampaignDetails,
  saveToDraft,
  updateDraft,
  uploadCampaignImage,
} from "../../../requests/authRequest";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate, useParams } from "react-router-dom";
import countryList from "../createCampaign/countrylist";
import { PhotoUpload } from "../createCampaign/creatorCreatedNft/mintNftCreatorStyles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import Wallets from "../../../wallets";

function EditDraftAdditionalInfo({
  handleSteps,
  currentCampaignId,
  handleCampaignCreate,
}: any) {
  const { t } = useTranslation();
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const kyc = useAppSelector((state) => state.userReducer.kyc);
  const [campaignInitialData, setCampaignInitialData] = useState<any>({});

  const [additionalData, setAdditionalData] = React.useState<any>({
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    companyName: "",
    website: "",
    projectPitch: "",
    problems: "",
    previousSuccessfulProject: "",
    Hq: "",
    country: "",
    impact: "",
    partnership: "",
    yearOfEstablishment: "",
    stageDesc: "",
    infoImage: "",
    additionalInfo: "",
  });

  const [infoImageName, setInfoImageName] = React.useState<any>("");

  const [consentStatus, setConsentStatus] = React.useState<
    boolean | undefined | string
  >("");
  const [reachoutStatus, setReachoutStatus] = React.useState<
    boolean | undefined | string
  >("");
  const imageRef = React.useRef<HTMLElement>();
  const [imageValid, setImageValid] = React.useState<boolean>(false);
  /**
   *
   * menu
   */

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   * menu
   */

  /**
   *
   * menu2
   */

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  /**
   *
   * menu
   */

  const handleChange = (newValue: Date | string | null) => {
    if (newValue !== null) {
      setAdditionalData((prev: any) => ({
        ...prev,
        yearOfEstablishment: newValue,
      }));
    }
  };

  const handleUploadChange = (e: any) => {
    const file = e.target.files;
    let fileType = file[0].name.split(".")[file[0].name.split(".").length - 1];
    if (
      fileType.toLowerCase() !== "png" &&
      fileType.toLowerCase() !== "jpeg" &&
      fileType.toLowerCase() !== "jpg"
    ) {
      setImageValid(false);

      if (imageRef && imageRef.current) {
        imageRef.current.style.backgroundImage = "none";
      }
      const toastPaylaod = {
        text: t("pleaseSelectPngJpgOrJpegImageFile"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      e.target.value = "";
      return;
    } else if (file[0].size / 1000000 > 5) {
      setImageValid(false);

      if (imageRef && imageRef.current) {
        imageRef.current.style.backgroundImage = "none";
      }
      const toastPaylaod = {
        text: t("pleaseSelectImageFileSmallerThanFiveMb"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      e.target.value = "";
      return;
    } else if (
      file[0] !== undefined &&
      file[0].name.length > 0 &&
      Math.floor(file[0].size / 1000000) < 5
    ) {
      setImageValid(true);

      setAdditionalData((prev: any) => ({ ...prev, infoImage: file[0] }));
      setInfoImageName(`url(${URL.createObjectURL(e.target.files[0])})`);

      // if (imageRef && imageRef.current) {
      //   // imageRef.current.style.backgroundImage = imageName[imageId];
      //   imageRef.current.style.backgroundImage = `url(${URL.createObjectURL(
      //     e.target.files[0]
      //   )})`;
      // }
    }
  };

  React.useEffect(() => {
    if (
      additionalData.infoImage !== "" ||
      additionalData.infoImage !== undefined ||
      additionalData.infoImage !== null
    ) {
      if (imageRef && imageRef.current) {
        imageRef.current.style.backgroundImage =
          infoImageName?.search("blob:") === -1
            ? `url(${process.env.REACT_APP_URL}/api/file/image/${infoImageName})`
            : infoImageName;
      }
    }
  }, [infoImageName]);

  const handleCountryChange = (e: SelectChangeEvent) => {
    setAdditionalData((prev: any) => ({
      ...prev,
      country: e.target.value,
    }));
  };

  const handleImageUpload = async () => {
    if (additionalData?.infoImage) {
      const res: any = await uploadCampaignImage([additionalData?.infoImage]);
      if (res.success) {
        return res?.data;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  /**
   * handle save to draft function
   */

  const handleSaveToDraft = async (submitStatus: boolean) => {
    if (consentStatus === "" || consentStatus === undefined) {
      const toastPaylaod = {
        text: t("pleaseSelectYourConsent"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return;
    }

    if (consentStatus) {
      if (
        additionalData.contactName === "" ||
        additionalData.contactEmail === "" ||
        additionalData.contactPhone === "" ||
        additionalData.companyName === "" ||
        additionalData.website === "" ||
        additionalData.projectPitch === "" ||
        additionalData.problems === "" ||
        additionalData.impact === "" ||
        additionalData.stageDesc === "" ||
        additionalData.country === ""
      ) {
        const toastPaylaod = {
          text: t("pleaseFillAllTheRequiredFields"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }

      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          additionalData.contactEmail
        )
      ) {
        const toastPaylaod = {
          text: t("pleaseFillValidEmailAddress"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }

      // if (additionalData.contactPhone.length > 10) {
      //   const toastPaylaod = {
      //     text: t("contactNumberShouldNotBeMoreThanTenDigits"),
      //     success: false,
      //     active: true,
      //   };
      //   dispatch(setToaster(toastPaylaod));
      //   dispatch(setAppLoadingText(""));
      //   dispatch(toggleAppLoading(false));
      //   return;
      // }

      if (isNaN(Number(additionalData.contactPhone))) {
        const toastPaylaod = {
          text: t("contactNumberShouldOnlyContainsDigits"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }

      if (reachoutStatus === "" || reachoutStatus === undefined) {
        const toastPaylaod = {
          text: t("pleaseSelectDoYouWantUsToReachoutToYou"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        return;
      }
    }

    dispatch(toggleAppLoading(true));
    dispatch(setAppLoadingText(t("updatingDraft")));

    const image = await handleImageUpload();

    let payload: any = {};

    if (campaignId === "") {
      dispatch(toggleAppLoading(false));
      return;
    } else {
      payload = {
        campaignId: campaignId,
        campaign: {
          title: campaignInitialData?.title,
          description: campaignInitialData?.description,
          category: campaignInitialData?.category?.categoryId,
          fundingCategory:
            campaignInitialData?.fundingCategory?.fundingCategoryId,
          endDate: campaignInitialData?.endDate,
          goalAmount: campaignInitialData?.goalAmount,
          country: campaignInitialData?.country,
          images: campaignInitialData?.images,
          video: campaignInitialData?.video,
          videoUrl: "",
          milestones: [
            {
              milestoneId: campaignInitialData?.milestones[0]?.milestoneId,
              title: campaignInitialData?.milestones[0].title
                ? campaignInitialData?.milestones[0].title
                : "",
              definition: campaignInitialData?.milestones[0].defination
                ? campaignInitialData?.milestones[0].defination
                : "",
              deadline: campaignInitialData?.milestones[0].deadline
                ? campaignInitialData?.milestones[0].deadline
                : "",
              deliverable: "non",
              milestoneAppId:
                campaignInitialData?.milestones[0]?.milestoneAppId,
            },
            {
              milestoneId: campaignInitialData?.milestones[1]?.milestoneId,
              title: campaignInitialData?.milestones[1].title
                ? campaignInitialData?.milestones[1].title
                : "",
              definition: campaignInitialData?.milestones[1].defination
                ? campaignInitialData?.milestones[1].defination
                : "",
              deadline: campaignInitialData?.milestones[1].deadline
                ? campaignInitialData?.milestones[1].deadline
                : "",
              deliverable: "non",
              milestoneAppId:
                campaignInitialData?.milestones[1]?.milestoneAppId,
            },
          ],
          matchFunding: campaignInitialData?.matchFunding,
          goalIds: campaignInitialData?.goalIds?.map((a: any) => a.goalId),
          rewardBased: campaignInitialData?.rewardBased,
          reward: campaignInitialData?.reward,
          rewardType: campaignInitialData?.rewardType,
        },
        isEsgEnabled: campaignInitialData?.isEsgEnabled,
        additionalInfo: campaignInitialData?.additionalInfo,
        additionalInfoData: {
          additionalInfoId:
            campaignInitialData?.additionalInfoId?.additionalInfoId,
          consent: consentStatus,
          contactName: additionalData.contactName,
          contactEmail: additionalData.contactEmail,
          contactPhone: additionalData.contactPhone,
          companyOrProjectName: additionalData.companyName,
          website: additionalData.website,
          projectPitch: additionalData.projectPitch,
          problems: additionalData.problems,
          previousSuccessfulProjects: additionalData.previousSuccessfulProject,
          hq: additionalData.Hq,
          impact: additionalData.impact,
          partnerships: additionalData.partnership,
          yearOfEstablishment: additionalData.yearOfEstablishment,
          image: image ? image[0] : "",
          companyBio: additionalData.stageDesc,
          reachoutRegardingSubmission: reachoutStatus,
          geographies: additionalData.country,
          additionalInformation: additionalData.additionalInfo,
        },
      };
    }

    const res = await updateDraft(payload);

    if (res.success) {
      const toastPaylaod = {
        text: t("updatedDraftSuccessfully"),
        success: true,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setAppLoadingText(""));
      dispatch(setToaster(toastPaylaod));
      if (submitStatus) {
        dispatch(toggleAppLoading(true));
        dispatch(setAppLoadingText(t("creatingCampaign")));
        handleCampaignCreate();
      }
      // cleanFormState();
      // navigationStatus && navigate("/user/my-campaign");
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setAppLoadingText(""));
      dispatch(setToaster(toastPaylaod));
      dispatch(toggleAppLoading(false));
    }
    dispatch(toggleAppLoading(false));
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(false));
    localStorage.removeItem("milestones");
  };

  /**
   * handle save to draft function end
   */

  const fetchInitialValues = async () => {
    dispatch(toggleAppLoading(true));
    const res = await getEditCampaignDetails(campaignId);
    dispatch(toggleAppLoading(false));
    if (res.success) {
      setCampaignInitialData(res.data.campaign);
      setAdditionalData((prev: any) => ({
        ...prev,
        contactName: res.data.campaign?.additionalInfoId?.contactName,
        contactEmail: res.data.campaign?.additionalInfoId?.contactEmail,
        contactPhone: res.data.campaign?.additionalInfoId?.contactPhone,
        companyName: res.data.campaign?.additionalInfoId?.companyOrProjectName,
        website: res.data.campaign?.additionalInfoId?.website,
        projectPitch: res.data.campaign?.additionalInfoId?.projectPitch,
        problems: res.data.campaign?.additionalInfoId?.problems,
        previousSuccessfulProject:
          res.data.campaign?.additionalInfoId?.previousSuccessfulProjects,
        Hq: res.data.campaign?.additionalInfoId?.hq,
        country: res.data.campaign?.additionalInfoId?.geographies,
        impact: res.data.campaign?.additionalInfoId?.impact,
        partnership: res.data.campaign?.additionalInfoId?.partnerships,
        yearOfEstablishment:
          res.data.campaign?.additionalInfoId?.yearOfEstablishment,
        stageDesc: res.data.campaign?.additionalInfoId?.companyBio,
        infoImage: res.data.campaign?.additionalInfoId?.image,
        additionalInfo:
          res.data.campaign?.additionalInfoId?.additionalInformation,
      }));
      setInfoImageName(res.data.campaign?.additionalInfoId?.image);
      setConsentStatus(res.data.campaign?.additionalInfoId?.consent);
      setReachoutStatus(
        res.data.campaign?.additionalInfoId?.reachoutRegardingSubmission
      );
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      navigate(-1);
    }
  };

  const clearImageData = () => {
    setAdditionalData((prev: any) => ({ ...prev, infoImage: "" }));
    setInfoImageName("");
    if (imageRef && imageRef.current) {
      imageRef.current.style.backgroundImage = "none";
    }
  };

  React.useEffect(() => {
    fetchInitialValues();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        padding: "15px",
        marginBottom: "20px",
        borderRadius: "5px",
      }}
    >
      <Typography style={{ textAlign: "left", fontSize: "2rem" }} variant="h3">
        {t("additionalCampaignInformation")}
      </Typography>
      <Box sx={{ bgcolor: "#fff", p: 2, mt: 2 }} style={{ textAlign: "left" }}>
        <Typography
          style={{ textAlign: "left", fontSize: "1rem" }}
          variant="h5"
        >
          {t("privacyAndDataCollection")}
        </Typography>
        <Typography variant="body1" style={{ textAlign: "left" }}>
          {t("additionalInfoTextOne")}
        </Typography>
        <Box sx={{ marginTop: "20px" }}>
          <InputLabel id="demo-simple-select-helper-label">
            {t("pleaseGiveYourConsent")}
          </InputLabel>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ color: "#000", border: "1px solid gray" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {consentStatus === false
              ? t("no")
              : consentStatus === true
              ? t("yes")
              : t("pleaseSelectOption")}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Box sx={{ width: "200px" }}>
              <MenuItem
                onClick={() => {
                  setConsentStatus(true);
                  handleClose();
                }}
              >
                {t("yes")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setConsentStatus(false);
                  handleClose();
                }}
              >
                {t("no")}
              </MenuItem>
            </Box>
          </Menu>
        </Box>
      </Box>
      <Box
        sx={{ bgcolor: "#fff", p: 2, mt: 2, width: "100%" }}
        style={{ textAlign: "left" }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <Box sx={{ mb: 4 }} style={{ textAlign: "left" }}>
              <TextField
                disabled={!consentStatus}
                id="outlined-basic"
                label="Contact name"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={additionalData.contactName}
                onChange={(e) =>
                  setAdditionalData((prev: any) => ({
                    ...prev,
                    contactName: e.target.value,
                  }))
                }
                inputProps={{
                  maxLength: 500,
                }}
              />
              <TextField
                disabled={!consentStatus}
                id="outlined-basic"
                label="Contact email"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={additionalData.contactEmail}
                onChange={(e) =>
                  setAdditionalData((prev: any) => ({
                    ...prev,
                    contactEmail: e.target.value,
                  }))
                }
                inputProps={{
                  maxLength: 500,
                }}
              />
              <TextField
                disabled={!consentStatus}
                id="outlined-basic"
                label="Contact phone"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={additionalData.contactPhone}
                onChange={(e) =>
                  setAdditionalData((prev: any) => ({
                    ...prev,
                    contactPhone: e.target.value,
                  }))
                }
                inputProps={{
                  maxLength: 500,
                }}
              />

              <TextField
                disabled={!consentStatus}
                id="outlined-basic"
                label="Company / Project / Organisation name"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={additionalData.companyName}
                onChange={(e) =>
                  setAdditionalData((prev: any) => ({
                    ...prev,
                    companyName: e.target.value,
                  }))
                }
                inputProps={{
                  maxLength: 500,
                }}
              />
              <TextField
                disabled={!consentStatus}
                id="outlined-basic"
                label="Website "
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={additionalData.website}
                onChange={(e) =>
                  setAdditionalData((prev: any) => ({
                    ...prev,
                    website: e.target.value,
                  }))
                }
                inputProps={{
                  maxLength: 500,
                }}
              />
              <p style={{ marginTop: "10px" }}>{t("projectPitch")}</p>
              <TextareaAutosize
                disabled={!consentStatus}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("enterProjectPitch") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={additionalData.projectPitch}
                onChange={(e) =>
                  setAdditionalData((prev: any) => ({
                    ...prev,
                    projectPitch: e.target.value,
                  }))
                }
              />
              <p style={{ marginTop: "10px" }}>{t("problemsYouAreSolving")}</p>
              <TextareaAutosize
                disabled={!consentStatus}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("problems") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={additionalData.problems}
                onChange={(e) =>
                  setAdditionalData((prev: any) => ({
                    ...prev,
                    problems: e.target.value,
                  }))
                }
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ mb: 4 }} style={{ textAlign: "left" }}>
              <TextField
                disabled={!consentStatus}
                id="outlined-basic"
                label="Previous Successful Projects (Optional)"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={additionalData.previousSuccessfulProject}
                onChange={(e) =>
                  setAdditionalData((prev: any) => ({
                    ...prev,
                    previousSuccessfulProject: e.target.value,
                  }))
                }
                inputProps={{ maxLength: 500 }}
              />
              <TextField
                disabled={!consentStatus}
                id="outlined-basic"
                label="HQ (Optional)"
                variant="outlined"
                style={{ width: "100%" }}
                sx={{ mt: 2, mb: 2 }}
                value={additionalData.Hq}
                onChange={(e) =>
                  setAdditionalData((prev: any) => ({
                    ...prev,
                    Hq: e.target.value,
                  }))
                }
                inputProps={{ maxLength: 500 }}
              />
              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">{t("country")}</FormLabel>
                <Select
                  disabled={!consentStatus}
                  fullWidth
                  value={additionalData.country}
                  onChange={handleCountryChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {countryList.map((country) => {
                    return (
                      <MenuItem
                        value={country.toLocaleLowerCase()}
                        key={country}
                      >
                        {country}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <p style={{ marginTop: "10px" }}>
                {t("whatDoYouHopeToAchieveWithThisProject")}
              </p>
              <TextareaAutosize
                disabled={!consentStatus}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("impact") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={additionalData.impact}
                onChange={(e) =>
                  setAdditionalData((prev: any) => ({
                    ...prev,
                    impact: e.target.value,
                  }))
                }
              />
              <p style={{ marginTop: "10px" }}>
                {t("partnerships")} ({t("optional")})
              </p>
              <TextareaAutosize
                disabled={!consentStatus}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("enterPartnershipsDetails") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={additionalData.partnership}
                onChange={(e) =>
                  setAdditionalData((prev: any) => ({
                    ...prev,
                    partnership: e.target.value,
                  }))
                }
              />
              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">
                  {t("yearOfEstablishment")} ({t("optional")})
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DatePicker
                      disabled={!consentStatus}
                      inputFormat="YYYY-MM-DD"
                      value={additionalData.yearOfEstablishment}
                      onChange={handleChange}
                      renderInput={(params: any) => (
                        <TextField {...params} disabled={!consentStatus} />
                      )}
                      disableFuture
                    />
                  </Stack>
                </LocalizationProvider>
                {/* {invalidEndDate && (
                  <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                    {errorEndDate}
                  </FormHelperText>
                )} */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ mb: 4 }} style={{ textAlign: "left" }}>
              <p style={{ marginTop: "10px" }}>{t("stageDescription")}</p>
              <TextareaAutosize
                disabled={!consentStatus}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("stageDescription") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={additionalData.stageDesc}
                onChange={(e) =>
                  setAdditionalData((prev: any) => ({
                    ...prev,
                    stageDesc: e.target.value,
                  }))
                }
              />
              <FormLabel component="legend">
                {t("imageUpload")} ({t("optional")})
              </FormLabel>
              <Box
                ref={imageRef}
                sx={PhotoUpload}
                style={{
                  position: "relative",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <Box
                  ref={imageRef}
                  style={{ position: "absolute", right: 0, top: 0 }}
                >
                  {additionalData.infoImage && (
                    <IconButton
                      disabled={!consentStatus}
                      onClick={clearImageData}
                    >
                      <CancelIcon />
                    </IconButton>
                  )}
                </Box>
                <Stack spacing={2}>
                  <label htmlFor="contained-button-file">
                    <input
                      className="inputfile"
                      accept=".jpeg, .png"
                      id="contained-button-file"
                      onChange={handleUploadChange}
                      multiple={false}
                      type="file"
                      style={{ display: "none" }}
                    />
                    <Button
                      disabled={!consentStatus}
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        marginBottom: "-28px",
                      }}
                      variant="text"
                      component="span"
                      className="uploadButton"
                      startIcon={<FileUploadIcon />}
                    >
                      {t("imageUpload")} ({t("optional")})
                    </Button>
                  </label>
                </Stack>
              </Box>
              <p style={{ marginTop: "10px" }}>
                {t("additionalInformationOptional")}
              </p>
              <TextareaAutosize
                disabled={!consentStatus}
                aria-label="minimum height"
                minRows={7}
                placeholder={t("additionalInformationOptional") || ""}
                style={{ borderRadius: "5px", width: "100%" }}
                value={additionalData.additionalInfo}
                onChange={(e) =>
                  setAdditionalData((prev: any) => ({
                    ...prev,
                    additionalInfo: e.target.value,
                  }))
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ bgcolor: "#fff", p: 2, mt: 2 }} style={{ textAlign: "left" }}>
        <Box sx={{ marginTop: "20px" }}>
          <InputLabel id="demo-simple-select-helper-label">
            {t("additionalInfoTextTwo")}
          </InputLabel>
          <Button
            disabled={!consentStatus}
            id="basic-button2"
            aria-controls={open2 ? "basic-menu2" : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? "true" : undefined}
            onClick={handleClick2}
            sx={{ color: "#000", border: "1px solid gray" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {reachoutStatus === true
              ? t("yes")
              : reachoutStatus === false
              ? t("no")
              : t("pleaseSelectOption")}
          </Button>
          <Menu
            id="basic-menu2"
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            MenuListProps={{
              "aria-labelledby": "basic-button2",
            }}
          >
            <Box sx={{ width: "200px" }}>
              <MenuItem
                onClick={() => {
                  setReachoutStatus(true);
                  handleClose2();
                }}
              >
                {t("yes")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setReachoutStatus(false);
                  handleClose2();
                }}
              >
                {t("no")}
              </MenuItem>
            </Box>
          </Menu>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "60px",
          marginTop: 3,
        }}
      >
        <Button
          variant="contained"
          color="error"
          className="round-button"
          onClick={() => {
            handleSteps("GOAL");
          }}
        >
          {t("back")}
        </Button>
        <Button
          onClick={() => handleSaveToDraft(false)}
          variant="contained"
          color="primary"
          className="round-button"
        >
          {t("saveToDraft")}
        </Button>

        {kyc === "approved" && (
          <>
            <Button
              onClick={() => setShowWalletButtons(true)}
              variant="contained"
              color="primary"
              className="round-button"
            >
              {t("submit")}
            </Button>
            {showWalletButtons && (
              <Wallets
                open={showWalletButtons}
                handleClose={() => setShowWalletButtons(false)}
                handleSubmit={() => handleSaveToDraft(true)}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
export default EditDraftAdditionalInfo;
