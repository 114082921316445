import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PeraOnramp } from "@perawallet/onramp";
import { donateCampaignDummy } from "../../../requests/authRequest";
import { useAppDispatch } from "../../../store";
import { useState } from "react";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { setAlgoDonationModal } from "../../../store/slices/ModalSlice";
import Wallets from "../../../wallets";
import { useWallet } from "@txnlab/use-wallet";

interface fundNowTab {
  refreshData: () => void;
  campaignInfo: any;
}

function FundNowTab({ refreshData, campaignInfo }: fundNowTab) {
  const peraOnramp = new PeraOnramp();
  const { t } = useTranslation();
  const { activeAccount, providers, signTransactions } = useWallet();
  const { campaignId } = useParams();
  const dispatch = useAppDispatch();
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);

  const accountAddress =
    "JRI4Y3HOUPKADXDELXP7II4TLMNM6JZJHDWCNFPYXX3OWRVFMKCVM5J6QA";
  const handleOpenWyreCheckout = async () => {
    peraOnramp
      .addFunds({
        accountAddress, // A valid Algorand account address
      })
      .then((res: any) => {
        console.log(accountAddress, res, "good");
        // Successfully added funds
      })
      .catch(() => {
        console.log("bad");
        // Failed to add funds
      });
  };

  // const handleOpenWyreCheckout = async () => {
  //   dispatch(toggleAppLoading(true));
  //   const referrerAccountData = await submitWyreAuthToken();
  //   if (referrerAccountData.success) {
  //     let accountId = referrerAccountData.data.authenticatedAs.split(":")[1];
  //     const reservationData = await createReservation(accountId);
  //     if (reservationData.success) {
  //       // this lines opens the session in a new tab uncomment it if you want that functionality
  //       // window.open(reservationData.data.url, "_blank");

  //       if (window && window.Wyre) {
  //         var widget = new window.Wyre({
  //           env: "test",
  //           reservation: reservationData.data.reservation,
  //           /*A reservation id is mandatory. In order for the widget to open properly you must use a new, unexpired reservation id. Reservation ids are only valid for 1 hour. A new reservation must also be made if a transaction fails.*/
  //           operation: {
  //             type: "debitcard-hosted-dialog",
  //           },
  //         });

  //         widget.on("paymentSuccess", async (e: any) => {
  //           dispatch(toggleAppLoading(true));
  //           const res = await fetchOrderData(e.data.data.orderId);
  //           if (res.success) {
  //             await handleAlgoPay();
  //             //handlePay(res.data.purchaseAmount);
  //           }
  //           dispatch(toggleAppLoading(false));
  //           refreshData();
  //         });

  //         widget.open();
  //       }
  //     }
  //   }
  //   dispatch(toggleAppLoading(false));
  // };

  const handlePay = async (amount: number) => {
    const info = {
      campaignId: campaignId,
      amount: amount,
      walletAddress: activeAccount?.address,
    };
    const res = await donateCampaignDummy(info);
    dispatch(toggleAppLoading(false));
    if (res.success) {
      const toastPayload = {
        active: true,
        text: t("donatedSuccessfully"),
        success: true,
      };
      dispatch(setToaster(toastPayload));
    } else {
      const toastPayload = {
        active: true,
        text: res.data.message,
        success: false,
      };
      dispatch(setToaster(toastPayload));
    }
  };

  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };

  const handleAlgoPay = async () => {
    const payload = {
      donationCampaignAppId: campaignInfo.campaignAppId,
      algoDonationModal: true,
    };
    dispatch(setAlgoDonationModal(payload));
  };
  return (
    <>
      <Typography variant="h5" className="semibold" align="left" mt={-2}>
        {t("selectPaymentMethod")}
      </Typography>
      <Box
        mt={3}
        sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <>
          <Button
            onClick={() => setShowWalletButtons(true)}
            variant="contained"
            className="darkButton"
          >
            {t("algorandWallet")}
          </Button>
          {showWalletButtons && (
            <Wallets
              open={showWalletButtons}
              handleClose={() => setShowWalletButtons(false)}
              handleSubmit={handleAlgoPay}
            />
          )}
        </>
        <Button
          onClick={handleOpenWyreCheckout}
          variant="contained"
          className="darkButton"
        >
          {t("debitOrCreditCard")}
        </Button>
      </Box>
    </>
  );
}

export default FundNowTab;
