import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getEditCampaignDetails,
  getInvestedCampaignDetails,
} from "../../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import moment from "moment";
import InvestedInfoTable from "./InvestedInfoTable";
import InvestorComment from "./InvestorComment";
import { checkInvestorNftClaim, investorClaimNft } from "../../../blockchain";
import { useWallet } from "@txnlab/use-wallet";
import { client, peraWallet } from "../../../wallets/algorand";
import algosdk from "algosdk";
import algo from "../../../assets/images/algo.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import Wallets from "../../../wallets";
import YouTube from "react-youtube";

const URL = process.env.REACT_APP_URL;
var getYouTubeID = require("get-youtube-id");
interface transaction {
  amount: number;
  createdAt: string;
  donatedBy: {
    name: string;
    userId: string;
  };
  donationId: string;
}

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function ViewMyInvestedCampaign() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const { t } = useTranslation();
  const [campaignData, setCampaignData] = useState<any>();
  const [transactions, setTransactions] = useState<Array<transaction>>([]);
  const [disableClaimNft, setDisableClaimNft] = useState<boolean>(true);
  const [review, setReview] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { campaignId } = useParams();
  const userAppId = useAppSelector((state) => state.userReducer.userAppId);
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);

  const fetchInitialValues = async () => {
    dispatch(toggleAppLoading(true));
    const res = await getInvestedCampaignDetails(campaignId);
    dispatch(toggleAppLoading(false));
    if (res.success) {
      setCampaignData(res.data.campaign);
      setTransactions(res.data.donations);
      setReview(res.data.review);
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      navigate(-1);
    }
  };

  const handleGoBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleDisableClaimNft = async () => {
    if (campaignData.status === "completed") {
      const info = {
        nftId: campaignData.campaignAppId,
        userAppId: userAppId,
      };
      const res = await checkInvestorNftClaim(info);
      if (res.success) {
        let canClaim = res.data[0].can_claim_NFT;
        let claimed = res.data[1].claimed_nft;
        if (canClaim === "True" && claimed === "False") {
          setDisableClaimNft(false);
        }
      }
    }
  };

  // const handleClaimNft = async () => {
  //   dispatch(toggleAppLoading(true));
  //   const myAlgoConnect = new MyAlgoConnect();

  //   if (!walletClient.connected()) {
  //     await walletClient.connect();
  //   }

  //   const info = {
  //     user_app_id: parseInt(userAppId),
  //     nft_asset_id: parseInt(campaignData.reward),
  //     campaign_app_id: parseInt(campaignData.campaignAppId),
  //   };

  //   const unsignedTxn = await investorClaimNft(info);

  //   if (!unsignedTxn.success) {
  //     const toastPaylaod = {
  //       text: t("unableToSendDataToBlockchain"),
  //       success: false,
  //       active: true,
  //     };
  //     dispatch(setToaster(toastPaylaod));
  //     dispatch(setAppLoadingText(""));
  //     dispatch(toggleAppLoading(false));
  //     return {
  //       success: false,
  //     };
  //   }

  //   try {
  //     const signedTxns: any = await myAlgoConnect.signTransaction(
  //       unsignedTxn.data.map((txn: any) => txn.txn)
  //     );
  //     const signed = [];
  //     signed.push(signedTxns[0].blob);
  //     signed.push(signedTxns[1].blob);
  //     // Submit the transaction

  //     // Wait for confirmation
  //     dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
  //     let tx = await client.sendRawTransaction(signed).do();
  //     dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
  //     let confirmedTxn = await algosdk.waitForConfirmation(client, tx.txId, 4);
  //     const toastPaylaod = {
  //       text: "NFT claim successful",
  //       success: true,
  //       active: true,
  //     };
  //     dispatch(toggleAppLoading(false));
  //     dispatch(setToaster(toastPaylaod));
  //     dispatch(setAppLoadingText(""));
  //     setDisableClaimNft(true);
  //     return;
  //   } catch (e: any) {
  //     const toastPaylaod = {
  //       text: e.message,
  //       success: false,
  //       active: true,
  //     };
  //     dispatch(setToaster(toastPaylaod));
  //     dispatch(setAppLoadingText(""));
  //     dispatch(toggleAppLoading(false));
  //   }
  // };

  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };

  const handleClaimNft = async () => {
    // dispatch(toggleAppLoading(true));

    const info = {
      user_app_id: parseInt(userAppId),
      nft_asset_id: parseInt(campaignData.reward),
      campaign_app_id: parseInt(campaignData.campaignAppId),
    };

    const unsignedTxn = await investorClaimNft(info);

    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: t("unableToSendDataToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(toggleAppLoading(false));

      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });

      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);
      dispatch(toggleAppLoading(true));

      const signed = [];
      signed.push(signedTxn[0]);
      signed.push(signedTxn[1]);
      // Submit the transaction

      // Wait for confirmation
      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();
      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );
      const toastPaylaod = {
        text: "NFT claim successful",
        success: true,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      setDisableClaimNft(true);
      return;
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
    }
  };

  useEffect(() => {
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(false));
    fetchInitialValues();
  }, []);

  useEffect(() => {
    if (campaignData && campaignData.endDate) {
      handleDisableClaimNft();
    }
  }, [campaignData]);

  return (
    <>
      {campaignData ? (
        <Box mb={3}>
          <Typography variant="h4" align="left" mb={4}>
            {t("viewInvestedCampaignInfo")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={4} md={6}>
              <Stack spacing={2}>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("title")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.title}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("description")}
                  </Typography>
                  <div
                    style={{
                      minHeight: "50%",
                      fontSize: "20px",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                  >
                    <Typography variant="h6" align="left">
                      {campaignData.description}
                    </Typography>
                  </div>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("status")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.status}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("endDate")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {moment(campaignData.endDate).format("MMM DD YYYY")}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("country")}
                  </Typography>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{ textTransform: "capitalize" }}
                  >
                    {campaignData.country}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("category")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.category.title}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("goalAmount")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.goalAmount}{" "}
                    <img
                      style={{ width: "16px", marginBottom: "-3px" }}
                      src={algo}
                    ></img>
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("collectedAmount")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.collectedAmount}{" "}
                    <img
                      style={{ width: "16px", marginBottom: "-3px" }}
                      src={algo}
                    ></img>
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("fundingCategory")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.fundingCategory.title}
                  </Typography>
                </Box>

                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("isCampaignaReforestationProject")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.isReforestationProject ? "Yes" : "No"}
                  </Typography>
                </Box>
                {campaignData.isReforestationProject === "Yes" ? (
                  <>
                    (
                    <Box mb={1}>
                      <Typography variant="h5" fontWeight={"600"} align="left">
                        {t("yearlyOutput")}
                      </Typography>
                      <Typography variant="h6" align="left">
                        {campaignData.expectedYearlyoutput}
                      </Typography>
                    </Box>
                    <Box mb={1}>
                      <Typography variant="h5" fontWeight={"600"} align="left">
                        {t("costPerUnit($)")}
                      </Typography>
                      <Typography variant="h6" align="left">
                        {campaignData.expectedCostPerUnit}
                      </Typography>
                    </Box>
                    <Box mb={1}>
                      <Typography variant="h5" fontWeight={"600"} align="left">
                        {t("expectedResults")}
                      </Typography>
                      <div
                        style={{
                          minHeight: "50%",
                          fontSize: "20px",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }}
                      >
                        <Typography variant="h6" align="left">
                          {campaignData.expectedResults}
                        </Typography>
                      </div>
                    </Box>
                    )
                  </>
                ) : (
                  ""
                )}
              </Stack>
              {campaignData.fundingCategory.title === "Reward" && (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "primary",
                      mt: 4,
                      mr: 2,
                      "&:hover": {
                        backgroundColor: "primary",
                      },
                      "&:focus": {
                        backgroundColor: "primary",
                      },
                    }}
                    disabled={disableClaimNft}
                    onClick={() => setShowWalletButtons(true)}
                  >
                    {t("claimNft")}
                  </Button>
                  {showWalletButtons && (
                    <Wallets
                      open={showWalletButtons}
                      handleClose={() => setShowWalletButtons(false)}
                      handleSubmit={() => handleClaimNft()}
                    />
                  )}
                </>
              )}
            </Grid>
            <Grid item lg={8} md={6}>
              <Box
                sx={{
                  border: "1px solid #cecbcb",
                  padding: "20px",
                  width: "100%",
                  borderRadius: "4px",
                  mb: 2,
                }}
              >
                <Typography variant="h5" fontWeight={"600"} align="left" mb={1}>
                  {t("image")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xl={10} lg={10}>
                    <Carousel
                      swipeable={true}
                      draggable={false}
                      showDots={campaignData?.images?.length > 1 ? true : false}
                      responsive={responsive}
                      ssr={false} // means to render carousel on server-side.
                      infinite={false}
                      autoPlay={false}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="carousel-container preview-campaign"
                      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                    >
                      {campaignData?.images?.map((item: any) => (
                        <Box
                          sx={{
                            backgroundImage: `url('${URL}/api/file/image/${item}')`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            width: "500px",
                            height: "500px",
                            cursor: "pointer",
                          }}
                          id={
                            item.length > 0
                              ? `${URL}/api/file/image/${item}`
                              : ""
                          }
                        />
                      ))}
                    </Carousel>
                  </Grid>
                </Grid>
              </Box>
              {campaignData.video ? (
                <Box
                  sx={{
                    border: "1px solid #cecbcb",
                    padding: "20px",
                    width: "100%",
                    borderRadius: "4px",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight={"600"}
                    align="left"
                    mb={1}
                  >
                    {t("video")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    <video
                      style={
                        campaignData.video
                          ? { maxWidth: "98%" }
                          : { display: "none" }
                      }
                      src={`${URL}/api/file/video/${campaignData.video}`}
                      controls
                      width={"500px"}
                      height={"auto"}
                      autoPlay={false}
                    />
                  </Typography>
                </Box>
              ) : (
                " "
              )}
              {campaignData.youTubeLink ? (
                <Box
                  sx={{
                    border: "1px solid #cecbcb",
                    padding: "20px",
                    width: "100%",
                    borderRadius: "4px",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight={"600"}
                    align="left"
                    mb={1}
                  >
                    {t("youtubeoptional")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.youTubeLink &&
                      (() => {
                        const id = getYouTubeID(campaignData.youTubeLink);
                        return (
                          <YouTube
                            videoId={id}
                            opts={{
                              height: "300",
                              width: "75%",
                              playerVars: {
                                autoplay: 0,
                              },
                            }}
                            style={
                              campaignData.youTubeLink
                                ? { maxWidth: "98%", height: "auto" }
                                : { display: "none" }
                            }
                          />
                        );
                      })()}
                  </Typography>
                </Box>
              ) : (
                " "
              )}
            </Grid>
            {campaignData.milestones && campaignData.milestones.length > 0 ? (
              <Grid item xs={12}>
                <Typography variant="h4" align="left" mb={4}>
                  {t("milestoneInfo")}
                </Typography>
                {campaignData.milestones.map((item: any, index: any) => {
                  return (
                    <Stack spacing={2} key={item.milestoneId}>
                      <Box mb={1}>
                        <Typography
                          variant="h5"
                          fontWeight={"600"}
                          align="left"
                          sx={{
                            marginBottom: "20px",
                          }}
                        >
                          {t("milestone")} {index + 1}
                        </Typography>
                        <Typography variant="h6" mb={1} align="left">
                          <b>{t("title")} - </b>
                          {item.title}
                        </Typography>
                        <div
                          style={{
                            minHeight: "50%",
                            fontSize: "20px",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                          }}
                        >
                          <Typography variant="h6" mb={1} align="left">
                            <b>{t("description")} - </b>
                            {item.definition}
                          </Typography>
                        </div>

                        <Typography
                          variant="h6"
                          mb={1}
                          align="left"
                          style={{ display: "flex" }}
                        >
                          <b>{t("submissionStatus")} - </b>
                          <Box
                            sx={{
                              paddingLeft: "0.175rem",
                              color:
                                item.status === "pending" ||
                                item.status === "in-progress"
                                  ? "#FFA500"
                                  : item.status === "completed" ||
                                    item.status === "report-submitted"
                                  ? "#00FF00"
                                  : item.status === "rejected"
                                  ? "#ff0000"
                                  : "#000",
                              textTransform: "capitalize",
                            }}
                          >
                            {item.status}
                          </Box>
                        </Typography>
                        <Typography variant="h6" mb={1} align="left">
                          <b>{t("submissionDeadline")} - </b>
                          {moment(item.deadline).format("MMM DD YYYY")}
                        </Typography>
                        {item.isReforestationProject === true && (
                          <Box>
                            <Typography variant="h6" mb={1} align="left">
                              <b>{t("yearlyOutput")} - </b>
                              {item.expectedYearlyoutput}
                            </Typography>
                            <Typography variant="h6" mb={1} align="left">
                              <b>{t("costPerUnit($)")} - </b>
                              {item.expectedCostPerUnit}
                            </Typography>

                            <div
                              style={{
                                minHeight: "50%",
                                fontSize: "20px",
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                              }}
                            >
                              <Typography variant="h6" mb={1} align="left">
                                <b>{t("expectedResults")} - </b>
                                {item.expectedResults}
                              </Typography>
                            </div>
                          </Box>
                        )}
                      </Box>
                    </Stack>
                  );
                })}
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={12}>
              <Box sx={{ textAlign: "left" }}>
                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" align="left" mb={4}>
                      {t("myDonations")}
                    </Typography>
                    <InvestedInfoTable transactionData={transactions} />
                  </Grid>
                </Grid>
                <InvestorComment review={review} />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.buttongrey",
                    mt: 4,
                    mr: 2,
                    "&:hover": {
                      backgroundColor: "primary.buttongreydark",
                    },
                    "&:focus": {
                      backgroundColor: "primary.buttongreydark",
                    },
                  }}
                  onClick={handleGoBack}
                >
                  {t("back")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

export default ViewMyInvestedCampaign;
