import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { Typography } from "@mui/material";
import algo from "../../../assets/images/algo.png";
import { useTranslation } from "react-i18next";

interface transaction {
  amount: number;
  createdAt: string;
  donatedBy: {
    name: string;
    userId: string;
  };
  donationId: string;
}

interface investedInfoTable {
  transactionData: Array<transaction>;
}

export default function InvestedInfoTable({
  transactionData,
}: investedInfoTable) {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{t("time")}</TableCell>
            <TableCell align="right">{t("amount")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactionData.map((row) => (
            <TableRow
              key={row.donationId}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {moment(row.createdAt).format("MMMM Do YYYY, h:mm a")}
              </TableCell>
              <TableCell align="right">
                {row.amount}{" "}
                <img
                  style={{ width: "17px", marginBottom: "-3px" }}
                  src={algo}
                ></img>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
