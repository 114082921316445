import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GoogleLoginButton } from "../../../components/auth";
import FacebookLoginButton from "../../../components/auth/FacebookLoginButton";
import { handleEmailLogin } from "../../../requests/simpleRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import {
  setLoginModal,
  setReqResetPasswordModal,
  setSelectRoleModal,
} from "../../../store/slices/ModalSlice";
import {
  loggedInUser,
  setUserData,
  setUserToken,
} from "../../../store/slices/UserSlice";
import style from "./style";
import { titleStyle, ContentStyle, SocialLinks, FooterBtn } from "./style";
import CancelIcon from "@mui/icons-material/Cancel";

function Login() {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [invalidPassword, setInvalidPassword] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);

  const open = useAppSelector((state) => state.modalReducer.loginModal);
  const userslice = useAppSelector((state) => state.userReducer);

  const dispatch = useAppDispatch();

  const resetFormState = () => {
    setPassword("");
    setInvalidPassword(false);
    setEmail("");
    setInvalidEmail(false);
    setShowPassword(false);
  };

  const handleClose = (e: any, reason: string) => {
    if (reason && reason === "backdropClick") return;
    resetFormState();
    dispatch(setLoginModal(false));
  };

  const handleOpenSignUp = () => {
    resetFormState();
    dispatch(setSelectRoleModal(true));
    dispatch(setLoginModal(false));
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateInputs = () => {
    let res = true;

    //for email
    if (email.trim().length <= 0) {
      // error = 'Please enter your email address';
      setInvalidEmail(true);
      res = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      // error = 'Invalid email address';
      setInvalidEmail(true);
      res = false;
    } else {
      setInvalidEmail(false);
    }

    // for passwordd
    if (password.trim().length > 0) {
      setInvalidPassword(false);
    } else {
      setInvalidPassword(true);
      res = false;
    }

    return res;
  };

  const handleSubmit = async (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    const everythinValid = validateInputs();
    if (everythinValid) {
      dispatch(toggleAppLoading(true));
      dispatch(setAppLoadingText(""));
      const payload = {
        email: email,
        password: password,
      };
      const res: any = await handleEmailLogin(payload);
      if (res.success) {
        const payload = {
          loggedIn: true,
          userToken: res.data.token,
          loginType: "email",
          registerUserType: userslice.registerUserType,
          userAppId: res.data.userAppId ? res.data.userAppId : "",
          kyc: res.data.kyc,
        };
        dispatch(setUserToken(payload));
        const toastPaylaod = {
          text: res.data.message,
          success: true,
          active: true,
        };
        const userPayload: loggedInUser = {
          status: res.data.status ? res.data.status : "",
          name: res.data.name ? res.data.name : "",
          email: res.data.email ? res.data.email : "",
          userId: res.data.userId ? res.data.userId : "",
          createdAt: res.data.createdAt ? res.data.createdAt : "",
          address: res.data.address ? res.data.address : "",
          phone: res.data.phone ? res.data.phone : "",
          country: res.data.country ? res.data.country : "",
          gender: res.data.gender ? res.data.gender : "",
          role: res.data.role ? res.data.role : "",
          userAppId: res.data.userAppId ? res.data.userAppId : "",
          organisationName: res.data.organisationName
            ? res.data.organisationName
            : "",
          organisationOwner: res.data.organisationOwner
            ? res.data.organisationOwner
            : "",
          orgnisationRole: res.data.orgnisationRole
            ? res.data.orgnisationRole
            : "",
        };
        dispatch(setUserData(userPayload));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        dispatch(setToaster(toastPaylaod));
        // navigate("/user");
      } else {
        const toastPaylaod = {
          text: res.data.message,
          success: false,
          active: true,
        };
        dispatch(toggleAppLoading(false));
        dispatch(setToaster(toastPaylaod));
      }
      dispatch(toggleAppLoading(false));
      resetFormState();
      dispatch(setLoginModal(false));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleForgotPassword = () => {
    dispatch(setReqResetPasswordModal(true));
    dispatch(setLoginModal(false));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          height: "100%",
          outline: " 0px",
          overflow: "hidden auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media(max-height:700px)": {
            alignItems: "baseline",
          },
        }}
        // onClick={handleClose}
      >
        <Box
          sx={style}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Box sx={titleStyle}>
            <CancelIcon
              sx={{ position: "absolute", top: 8, right: 8, cursor: "pointer" }}
              onClick={(e) => handleClose(e, "main")}
            />
            <Typography variant="h4" align="center" mb={2}>
              {t("signIn")}
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="caption">
                {t("dontHaveAnAccount")}&nbsp;
              </Typography>
              <Button variant="text" onClick={handleOpenSignUp}>
                {t("createNewOne")}
              </Button>
            </Box>
          </Box>
          <form autoComplete="off">
            <Box sx={ContentStyle}>
              <TextField
                fullWidth
                label="Email"
                type={"email"}
                id="filled-hidden-label-small"
                placeholder={t("yourEmail") || ""}
                variant="outlined"
                onChange={handleEmailChange}
                error={invalidEmail}
                helperText={invalidEmail && "Please enter a valid Email"}
                onKeyDown={handleKeyDown}
                inputProps={{
                  autocomplete: "new-password",
                  form: {
                    autocomplete: "off",
                  },
                }}
              />
              <TextField
                fullWidth
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                error={invalidPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{
                          color: "#d3d3d3",
                        }}
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder={t("password") || ""}
                onKeyDown={handleKeyDown}
                helperText={invalidPassword && "Please enter a password"}
                inputProps={{
                  autocomplete: "new-password",
                  form: {
                    autocomplete: "off",
                  },
                }}
              />

              {/* <Box sx={{ textAlign: "center" }} mt={3} mb={3}>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "12px", color: "#817f7f" }}
                >
                  {t("orContinueWith")}
                </Typography>
              </Box> */}
            </Box>
          </form>
          {/* <Box sx={SocialLinks}>
            <GoogleLoginButton />
            <FacebookLoginButton />
          </Box> */}
          <Box sx={FooterBtn}>
            <Button
              variant="text"
              sx={{ m: "0 !important", color: "initial" }}
              onClick={handleForgotPassword}
            >
              {t("forgotPassword")}
            </Button>
            <Box sx={{ textAlign: "center" }}>
              <Button variant="contained" onClick={handleSubmit}>
                {t("logIn")}
              </Button>
            </Box>
            <Button
              sx={{ color: "initial" }}
              variant="text"
              onClick={(e) => handleClose(e, "main")}
            >
              {t("doItLater")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default Login;
