import React, { useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Select,
  MenuItem,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import {
  filterTitle,
  selectorStyle,
  mainContainer,
  filterMainTitle,
} from "./style";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import countryList from "../../../user/createCampaign/countrylist";
import moment from "moment";
import { setToaster } from "../../../../store/slices/LoadinAndNotifSlice";
import { useAppDispatch } from "../../../../store";
import algo from "../../../../assets/images/algo.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";

interface category {
  title: string;
  slug: string;
  categoryId: string;
}

function FilterModal({
  filterCategories,
  handleClose,
  handleFilter,
  filterData,
}: any) {
  const { t } = useTranslation();
  const fundingFieldsList = ["Donation", "Reward"];
  const goalFieldsList = ["Environmental", "Social", "Governance"];
  const [categories, setCategories] = React.useState<string[]>(
    filterData.categories
  );
  const [fundings, setFundings] = React.useState<string[]>(filterData.funding);
  const [minGoalAmount, setMinGoalAmount] = React.useState<string>(
    filterData.goalAmountMin
  );
  const [maxGoalAmount, setMaxGoalAmount] = React.useState<string>(
    filterData.goalAmountMax
  );
  const [country, setCountery] = React.useState<string>(filterData.country);
  const [goal, setGoal] = React.useState<string>(filterData.goal);
  const [endDate, setEndDate] = React.useState<Date | string | null>(
    filterData.endDate
  );
  const [noOlderThan, setNoOlderThan] = React.useState<Date | string | null>(
    filterData.campaignNoOlderThan
  );
  const dispatch = useAppDispatch();

  const handleMinGoalAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMinGoalAmount(e.target.value);
  };

  const handleMaxGoalAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMaxGoalAmount(e.target.value);
  };

  const handleCountryChange = (e: SelectChangeEvent) => {
    setCountery(e.target.value);
  };

  const handleGoalChange = (e: SelectChangeEvent) => {
    setGoal(e.target.value);
  };

  const handleEndDateChange = (newValue: Date | string | null) => {
    if (newValue !== null) {
      setEndDate(new Date(newValue));
    }
  };

  const handleNoOlderThanChange = (newValue: Date | string | null) => {
    if (newValue !== null) {
      setNoOlderThan(new Date(newValue));
    }
  };

  const handleClearFilter = () => {
    setCategories([]);
    setFundings([]);
    setMinGoalAmount("");
    setMaxGoalAmount("");
    setCountery("");
    setGoal("");
    setEndDate(null);
    setNoOlderThan(null);
  };

  useEffect(() => {
    document.getElementById("endDate")?.setAttribute("disabled", "disabled");
    document
      .getElementById("campaignNoOlderThan")
      ?.setAttribute("disabled", "disabled");
  }, []);

  const handleApplyFilter = () => {
    if (
      minGoalAmount.trim() &&
      maxGoalAmount.trim() &&
      minGoalAmount.trim() > maxGoalAmount.trim()
    ) {
      const toastPaylaod = {
        text: t("maxGoalAmountShouldBeBiggerOrEqualThanMinGoalAmount"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      return;
    }
    const filtersData = {
      categories: categories,
      funding: fundings,
      goalAmountMin: minGoalAmount.trim(),
      goalAmountMax: maxGoalAmount.trim(),
      country: country,
      endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      campaignNoOlderThan: noOlderThan
        ? moment(noOlderThan).format("YYYY-MM-DD")
        : "",
      goal: goal,
    };
    handleFilter(filtersData);
  };

  return (
    <Box sx={mainContainer}>
      <Typography sx={filterMainTitle}>{t("filters")}</Typography>
      <Box
        sx={{
          display: "flex",
          //   alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography sx={filterTitle}>{t("category")}</Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filterCategories.map((item: any) => (
              <FormControlLabel
                key={item.title}
                control={
                  <Checkbox
                    checked={Boolean(
                      categories.some((el) => el === item.title)
                    )}
                    onChange={() => {
                      categories.some((el) => el === item.title)
                        ? setCategories(
                            categories.filter((el) => el !== item.title)
                          )
                        : setCategories((prev) => [...prev, item.title]);
                    }}
                    name={item.title}
                    color="primary"
                  />
                }
                label={
                  <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
                    {item.title}
                  </Typography>
                }
              />
            ))}
          </Box>
        </Box>
        <Box>
          <Typography sx={filterTitle}>{t("funding")}</Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {fundingFieldsList.map((item: any) => (
              <FormControlLabel
                key={item}
                control={
                  <Checkbox
                    checked={Boolean(fundings.some((el) => el === item))}
                    onChange={() => {
                      fundings.some((el) => el === item)
                        ? setFundings(fundings.filter((el) => el !== item))
                        : setFundings((prev) => [...prev, item]);
                    }}
                    name={item}
                    color="primary"
                  />
                }
                label={
                  <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
                    {item}
                  </Typography>
                }
              />
            ))}
          </Box>
        </Box>
        <Box>
          <Typography sx={filterTitle}>{t("goalAmount")}</Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <TextField
              value={minGoalAmount}
              onChange={handleMinGoalAmountChange}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography sx={{ display: "flex", alignItem: "center" }}>
                      <img
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "5px",
                        }}
                        src={algo}
                      ></img>{" "}
                      {t("min")}
                    </Typography>
                  </InputAdornment>
                ),
                style: {
                  height: "40px",
                  marginTop: 8,
                },
              }}
            />
            <TextField
              value={maxGoalAmount}
              onChange={handleMaxGoalAmountChange}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography sx={{ display: "flex", alignItem: "center" }}>
                      <img
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "5px",
                        }}
                        src={algo}
                      ></img>{" "}
                      {t("max")}
                    </Typography>
                  </InputAdornment>
                ),
                style: {
                  height: "40px",
                  marginTop: 12,
                },
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography sx={filterTitle}>{t("country")}</Typography>
            <Box sx={{ marginTop: "15px" }}>
              <Select
                fullWidth
                value={country}
                onChange={handleCountryChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={""} key={""}>
                  Select
                </MenuItem>
                {countryList.map((country) => {
                  return (
                    <MenuItem value={country.toLocaleLowerCase()} key={country}>
                      {country}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
          <Box sx={{ marginTop: "30px" }}>
            <Typography sx={filterTitle}>{t("esgAndSdgGoals")}</Typography>
            <Box sx={{ marginTop: "15px" }}>
              <Select
                fullWidth
                value={goal}
                onChange={handleGoalChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={""} key={""}>
                  Select
                </MenuItem>
                {goalFieldsList.map((goal) => {
                  return (
                    <MenuItem value={goal} key={goal}>
                      {goal}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography sx={filterTitle}>{t("endDate")}</Typography>
            <Box sx={{ marginTop: "15px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={(params: any) => (
                      <TextField {...params} error={false} id={"endDate"} />
                    )}
                    disablePast
                  />
                </Stack>
              </LocalizationProvider>
            </Box>
          </Box>
          <Box sx={{ marginTop: "30px" }}>
            <Typography sx={filterTitle}>{t("campaignNoOlderThan")}</Typography>
            <Box sx={{ marginTop: "15px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    value={noOlderThan}
                    onChange={handleNoOlderThanChange}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        error={false}
                        id={"campaignNoOlderThan"}
                      />
                    )}
                    disablePast
                  />
                </Stack>
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "60px",
          marginTop: 3,
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={handleClose}
          variant="contained"
          color="error"
          className="round-button"
        >
          {t("close")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className="round-button"
          onClick={handleClearFilter}
        >
          {t("clearFilters")}
        </Button>

        <Button
          variant="contained"
          color="primary"
          className="round-button"
          onClick={handleApplyFilter}
        >
          {t("applyFilters")}
        </Button>
      </Box>
    </Box>
  );
}

export default FilterModal;
