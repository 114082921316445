import { TabContext, TabList } from "@mui/lab";
import {
  Button,
  Grid,
  Modal,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { getAllGrantsForDashboard } from "../../../requests/simpleRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import GrantCard from "./GrantCard";
import GrantFilter from "./FilterModal/GrantFilter";
import { CategoryTab } from "./style";
import { useTranslation } from "react-i18next";
import { HeadingBox } from "../campaigns/style";

export interface goal {
  category: string;
  goalName: string;
  goalId: string;
}

export enum grantType {
  CUSTOM = "custom",
  STANDARD = "standard",
}

export interface grant {
  createdAt: string;
  createdBy: string;
  duration: string;
  endDate: string;
  goals: Array<goal>;
  grantAppId: number;
  grantId: string;
  grantType: string;
  images: Array<any>;
  longDescription: string;
  maximumGrant: number;
  minimumGrant: number;
  preferredLocation: string;
  shortDescription: string;
  status: string;
  target: string;
  title: string;
  totalBudget: number;
  totalGrants: number;
  updatedAt: string;
  video: string;
  videoUrl: string;
  rating: number;
  requestedFund: number;
  totalAcceptedFund: number;
}

const HomeGrantContainer = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>("");
  const [grantList, setGrantList] = useState<Array<grant>>([]);
  const [page, setPage] = useState<number>(1);
  const [loadmore, setLoadmore] = useState<boolean>(true);
  const [filterStatus, setFilterStatus] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const [filtersData, setFiltersData] = useState<any>({
    limit: 8,
    page: 1,
    goal: "",
    country: "",
    endDate: "",
    grantType: "",
    totalBudget: "",
    totalGrants: "",
    minimumGrant: "",
    maximumGrant: "",
    grantNoOlderThan: "",
  });

  const userslice = useAppSelector((state) => state.userReducer);

  let newCategories: { categoryId: number; slug: string; title: string }[] = [
    {
      categoryId: 1,
      slug: "",
      title: t("allGrants"),
    },
    {
      categoryId: 2,
      slug: grantType.STANDARD,
      title: t("esgGrants"),
    },
    {
      categoryId: 3,
      slug: grantType.CUSTOM,
      title: t("nonEsgGrants"),
    },
  ];

  const loadMoreRef = useRef<boolean>();
  const pageRef = useRef<number>(0);
  const grantListRef = useRef<Array<grant>>();

  const dispatch = useAppDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    newValue !== "" ? filterGrants(newValue) : filterGrants();
  };

  const filterGrants = async (grant = "", goal = "") => {
    const data = {
      limit: 8,
      page: pageRef.current,
      grantType: grant,
      goal: goal,
    };
    setPage(pageRef.current);
    const res = await getAllGrantsForDashboard(data);

    setFilterData(res);
  };

  const filter = async (filters: any) => {
    const data = {
      limit: 8,
      page: 1,
      grantType: value,
      goal: filters.goal,
      minimumGrant: filters.minimumGrant,
      maximumGrant: filters.maximumGrant,
      country: filters.country,
      endDate: filters.endDate,
      grantNoOlderThan: filters.grantNoOlderThan,
      totalBudget: filters.totalBudget,
      totalGrants: filters.totalGrants,
    };
    setPage(1);

    setFiltersData(data);
    const res = await getAllGrantsForDashboard(data);
    setFilterData(res);
    setValue("");
    setFilterStatus(false);
  };

  const setFilterData = (res: any) => {
    if (res.success) {
      setGrantList([]);
      if (res.data?.totalPages > pageRef.current) {
        setLoadmore(true);
      } else {
        setLoadmore(false);
      }
      setGrantList([...res.data?.grants]);
    } else if (!res.success && !res.data?.grants) {
      setGrantList([]);
    }
  };

  const handleClearFilter = () => {
    const data = {
      limit: 8,
      page: 1,
      goal: "",
      country: "",
      endDate: "",
      grantType: "",
      minimumGrant: "",
      maximumGrant: "",
      grantNoOlderThan: "",
      totalBudget: "",
      totalGrants: "",
    };
    filter(data);
  };

  const handleScroll = () => {
    let doc = document.documentElement;
    let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top < 470) {
      let scroll = 470 - top;
      window.scrollBy(0, scroll);
    }
  };

  const handleLoadMore = () => {
    if (
      document &&
      document.scrollingElement &&
      Math.floor(window.innerHeight + document.documentElement.scrollTop) >=
        Math.floor(document.scrollingElement.scrollHeight) - 1
    ) {
      // Do load more content here!

      if (
        loadMoreRef.current &&
        grantListRef.current &&
        pageRef.current &&
        grantListRef.current.length > 0
      ) {
        fetchAllGrants(pageRef.current + 1, value);
      }
    }
  };

  useEffect(() => {
    setFiltersData({
      limit: 8,
      page: 1,
      goal: "",
      country: "",
      endDate: "",
      grantType: "",
      minimumGrant: "",
      maximumGrant: "",
      grantNoOlderThan: "",
      totalBudget: "",
      totalGrants: "",
    });

    fetchAllGrants(1);
    setGrantList([]);
    window.addEventListener("scroll", handleLoadMore);
    return () => window.removeEventListener("scroll", handleLoadMore);
  }, [userslice.role]);

  // useEffect(() => {
  // 	if (categorySlug !== undefined) {
  // 		setCampaignList([]);
  // 		fetchCategoryCampaign(1);
  // 	}
  // }, [categorySlug]);

  useEffect(() => {
    loadMoreRef.current = loadmore;
    grantListRef.current = grantList;
    pageRef.current = page;
  }, [loadmore, grantList, page]);

  const fetchAllGrants = async (page: number, grantType = "") => {
    const data = {
      limit: 8,
      page,
      grantType,
    };
    const res = await getAllGrantsForDashboard(data);
    if (res.success) {
      if (res.data.totalPages > page) {
        setLoadmore(true);
      } else {
        setLoadmore(false);
      }
      if (grantListRef.current) {
        setGrantList([...grantListRef.current, ...res.data.grants]);
      } else {
        setGrantList([...res.data.grants]);
      }
    }
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box sx={CategoryTab}>
          <Container fixed>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <Box
                sx={{
                  width: { md: "300px", xs: "120px" },
                  textAlign: "left",
                }}
              >
                <Typography variant="h4">{t("categories")}</Typography>
              </Box> */}
              <Box
                sx={{
                  width: {
                    md: "calc(100% - 300px)",
                    xs: "calc(100% - 120px)",
                  },
                }}
              >
                <TabList onChange={handleChange}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    // classes={{
                    // 	root = {
                    // 		display: "none",
                    // 		// backgroundColor: #ff0000",
                    // 	},
                    // }}
                  >
                    {newCategories &&
                      newCategories.length > 0 &&
                      newCategories?.map((cat) => {
                        return (
                          //   <Link
                          //     to={`/category/${cat.slug}`}
                          //     style={{ textDecoration: "none" }}
                          //   >
                          <Tab
                            key={cat?.categoryId}
                            label={
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  textAlign: "center",
                                }}
                              >
                                {cat?.title}
                              </Typography>
                            }
                            value={cat?.slug}
                            onClick={handleScroll}
                          />
                          //   </Link>
                        );
                      })}
                  </Tabs>
                </TabList>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box>
          <Grid item xs={4}>
            <Box
              sx={{
                marginTop: "20px",
                marginRight: "50px",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                className="round-button"
                onClick={() => setFilterStatus(true)}
              >
                {t("filters")}
              </Button>
              {(filtersData?.goal ||
                filtersData?.maximumGrant ||
                filtersData?.minimumGrant ||
                filtersData?.country ||
                filtersData?.endDate ||
                filtersData?.totalBudget ||
                filtersData?.totalGrants ||
                filtersData?.grantNoOlderThan) && (
                <Tooltip title="Reset filter">
                  <FilterAltOffIcon
                    color="primary"
                    onClick={() => handleClearFilter()}
                  >
                    <FilterAltIcon />
                  </FilterAltOffIcon>
                </Tooltip>
              )}
              {/* </Box> */}
            </Box>
          </Grid>
          <Container fixed>
            <Typography sx={HeadingBox}>All Grants</Typography>
            <Grid mt={3} mb={3} container spacing={4}>
              {grantList &&
                grantList?.length > 0 &&
                grantList?.map((grant) => {
                  return <GrantCard data={grant} />;
                })}
            </Grid>
          </Container>
          {grantList && grantList.length == 0 && (
            <Container fixed>
              <Grid>
                <Typography
                  sx={{
                    fontWeight: 900,
                    fontSize: "23px",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {t("noDataFound")}
                </Typography>
              </Grid>
            </Container>
          )}

          <Typography sx={HeadingBox}>Successfully Completed Grants</Typography>
          <Container fixed>
            <Grid mt={3} mb={3} container spacing={4}>
              {grantList &&
                grantList?.length > 0 &&
                grantList
                  .filter((item) => item.status === "completed")
                  ?.map((camp) => {
                    return <GrantCard data={camp} />;
                  })}
            </Grid>
          </Container>
          {(grantList && grantList.length == 0) ||
            (grantList.filter((item) => item.status === "completed").length ===
              0 && (
              <Container fixed>
                <Grid>
                  <Typography
                    sx={{
                      fontWeight: 900,
                      fontSize: "23px",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {t("noDataFound")}
                  </Typography>
                </Grid>
              </Container>
            ))}

          {/* ------ filter modal ----------- */}

          <Modal
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
            open={filterStatus}
            onClose={() => setFilterStatus(false)}
          >
            <GrantFilter
              filterData={filtersData}
              handleClose={() => setFilterStatus(false)}
              handleFilter={(el: any) => filter(el)}
            />
          </Modal>
        </Box>
      </TabContext>
    </Box>
  );
};

export default HomeGrantContainer;
