const style = {
  maxWidth: "512px",
  width: "98%",
  bgcolor: "#eef0f0",
  borderRadius: "12px",
  margin: "32px",
  position: "relative",
  overflowY: "auto",
  display: "inline-block",
  verticalAlign: "middle",
  textAlign: "left",
  padding: 4,
};

const titleStyle = {
  "& .MuiButton-text": {
    padding: "0px",
    fontSize: "14px",
    color: "green.main",
    fontFamily: "TTNormsRegular",
    height: "auto",
    minWidth: "auto",
  },
};
const ContentStyle = {
  marginTop: { xl: "40px", xs: "20px" },
  "& .MuiTextField-root": {
    marginBottom: 3,
  },
  "& .MuiOutlinedInput-root": {
    // marginBottom: 3,
    backgroundColor: "white.main",
    "& .MuiOutlinedInput-input": {
      fontSize: { xl: "16px", xs: "14px" },
      fontFamily: "TTNormsMedium",
      padding: { xl: "19.5px 20px", xs: "12.5px 15px" },
      borderRadius: "6px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white.main",
    },
  },
};
const SocialLinks = {
  display: "flex",
  justifyContent: "space-between",
  "& .MuiButton-root": {
    backgroundColor: "white.main",
    color: "black.main",
    height: { xl: "62px", xs: "48px" },
    width: "calc(50% - 16px)",
    fontSize: { xl: "16px", xs: "14px" },
    "&:hover": {
      backgroundColor: "white !important",
      color: "black.main",
    },
    "&:focus": {
      backgroundColor: "white !important",
      color: "black.main",
    },
    "& img": {
      marginRight: { xl: "10px", xs: "6px" },
      width: { xl: "32px", xs: "24px" },
      height: "auto",
    },
  },
};
const FooterBtn = {
  margin: { xl: "60px auto 0px", xs: "30px auto 0px" },
  "& .MuiButton-contained": {
    backgroundColor: "green.main",
    borderRadius: "20px",
    fontSize: { xl: "16px", xs: "14px" },
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "green.dark",
      color: "black.main",
    },
  },
  "& .MuiButton-text": {
    padding: "0px",
    fontSize: { xl: "16px", xs: "14px" },
    fontFamily: "TTNormsRegular",
    height: "auto",
    minWidth: "auto",
    display: "block",
    margin: { xl: "25px auto 0px", xs: "15px auto 0px" },
  },
};
export default style;

export { titleStyle, ContentStyle, SocialLinks, FooterBtn };
