import { client } from "../../src/wallets/algorand";
import { t } from "i18next";

var axios = require("axios");

const URL = process.env.REACT_APP_URL;

export const registerIndividualUser = async (regData) => {
  const data = JSON.stringify(regData);
  var config = {
    method: "post",
    url: `${URL}/api/auth/register`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const handleGoogleLogin = async (res) => {
  const data = JSON.stringify(res);
  var config = {
    method: "post",
    url: `${URL}/api/auth/google/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const handleGoogleRegister = async (res) => {
  const data = JSON.stringify(res);
  var config = {
    method: "post",
    url: `${URL}/api/auth/google/register`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const handleFacebookLogin = async (res) => {
  const data = JSON.stringify(res);
  var config = {
    method: "post",
    url: `${URL}/api/auth/facebook/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const handleFacebookRegister = async (res) => {
  const data = JSON.stringify(res);
  var config = {
    method: "post",
    url: `${URL}/api/auth/facebook/register`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const handleEmailLogin = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/auth/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getCampaignCategories = async () => {
  var config = {
    method: "get",
    url: `${URL}/api/campaign/categories`,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getFundingCategories = async () => {
  var config = {
    method: "get",
    url: `${URL}/api/campaign/funding-categories`,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getAllCampaign = async (data) => {
  let param = data.goal ? `&goalCategory=${data.goal.toLowerCase()}` : ``;
  if (data?.categories && data?.categories.length) {
    param = param + `&categories=${JSON.stringify(data.categories)}`;
  }
  if (data?.funding && data?.funding.length) {
    param = param + `&funding=${JSON.stringify(data.funding)}`;
  }
  if (data?.goalAmountMin) {
    param = param + `&goalAmountMin=${data.goalAmountMin}`;
  }
  if (data?.goalAmountMax) {
    param = param + `&goalAmountMax=${data.goalAmountMax}`;
  }
  if (data?.country) {
    param = param + `&country=${data.country}`;
  }
  if (data?.endDate) {
    param = param + `&endDate=${data.endDate}`;
  }
  if (data?.campaignNoOlderThan) {
    param = param + `&campaignNoOlderThan=${data.campaignNoOlderThan}`;
  }
  var config = {
    method: "get",
    url:
      `${URL}/api/campaign/all?page=${data.page}&limit=${data.limit}&campaignsToBeFetched=${data.campaign}` +
      param,
    headers: {},
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
        totalCount: res.data.totalCount,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
        totalCount: 0,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      totalCount: 0,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getCampaignData = async (id) => {
  var config = {
    method: "get",
    url: `${URL}/api/campaign/?campaignId=${id}`,
  };
  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const requestResetPassword = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/auth/forgot-password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const resetPassword = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/auth/reset-password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getCampaignByCategory = async (data) => {
  var config = {
    method: "get",
    url: `${URL}/api/campaign/category?category=${data.category}&page=${data.page}&limit=${data.limit}`,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getCampaignReview = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/api/campaign/reviews?page=${info.page}&limit=${info.limit}&campaignId=${info.campaignId}`,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const checkEmailExistance = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/auth/check-email-exists`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getAllGrantsForDashboard = async (data) => {
  let param = data.goal ? `&goal=${data.goal.toLowerCase()}` : ``;
  if (data?.minimumGrant) {
    param = param + `&minimumGrant=${data.minimumGrant}`;
  }
  if (data?.maximumGrant) {
    param = param + `&maximumGrant=${data.maximumGrant}`;
  }
  if (data?.country) {
    param = param + `&country=${data.country}`;
  }
  if (data?.endDate) {
    param = param + `&endDate=${data.endDate}`;
  }
  if (data?.grantNoOlderThan) {
    param = param + `&grantNoOlderThan=${data.grantNoOlderThan}`;
  }
  if (data?.grantType) {
    param = param + `&grantType=${data.grantType}`;
  }
  if (data?.totalBudget) {
    param = param + `&totalBudget=${data.totalBudget}`;
  }
  if (data?.totalGrants) {
    param = param + `&totalGrants=${data.totalGrants}`;
  }

  var config = {
    method: "get",
    url:
      `${URL}/api/grant/all-for-dashboard?page=${data.page}&limit=${data.limit}` +
      param,
    headers: {},
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
        totalCount: res.data.totalCount,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
        totalCount: 0,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      totalCount: 0,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};

export const getGrantData = async (id) => {
  var config = {
    method: "get",
    url: `${URL}/api/grant/?grantId=${id}`,
  };
  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response ? err.response.data : { message: t("networkError") },
    };
    return returnRes;
  }
};
