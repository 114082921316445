import {
  Box,
  Grid,
  Button,
  TextField,
  FormLabel,
  Typography,
} from "@mui/material";
import { CampaigngForm } from "../createCampaign/styles";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import {
  createGrantManager,
  getGrantManagerById,
  updateGrantManager,
} from "../../../requests/authRequest";
import { useNavigate, useParams } from "react-router-dom";
import { client, peraWallet } from "../../../wallets/algorand";
import { addGrantManagerBlockchain } from "../../../blockchain";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import algosdk from "algosdk";
import { userRole } from "../../../constants/common-enum";
import { useTranslation } from "react-i18next";
import { useWallet } from "@txnlab/use-wallet";
import Wallets from "../../../wallets";

function CreateGrantmanager() {
  const { t } = useTranslation();

  const { activeAccount, providers, signTransactions } = useWallet();
  const getInitialvalues = () => {
    const initialvalues = {
      fullName: "",
      displayName: "",
      website: "",
      description: "",
      email: "",
      phone: "",
      image: "",
      facebookURL: "",
      instagramURL: "",
      twitterURL: "",
      clubhouseURL: "",
      youtubeURL: "",
      grantCreatorId: "",
      role: "",
      userAppId: "",
      createdAt: "",

      status: "",
    };
    return initialvalues;
  };

  const { managerId } = useParams();

  const dispatch: any = useAppDispatch();
  const navigate: any = useNavigate();
  const userslice = useAppSelector((state) => state.userReducer);
  const [manager, setManager] = useState(getInitialvalues());
  const [loading, setloading] = useState<boolean>(false);
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const [values, setValues] = useState<any>({});

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const fetchGrantManagerDetails = async () => {
    try {
      setloading(true);
      const data = { managerId };
      const res = await getGrantManagerById(data);

      if (res.success) {
        setManager(res.data);
        formik.values = res.data;
      } else {
        const toastPlayload = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toastPlayload));
      }
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    setIsEditing(Boolean(managerId));
    isEditing && fetchGrantManagerDetails();
  }, [isEditing]);

  const handleCreateGrantManagerBlockchain = async () => {
    const grantInfo = {
      wallet_address: activeAccount?.address,
      user_type: "grant-manager",
      user_app_id: parseInt(userslice.userAppId),
    };

    const unsignedTxn = await addGrantManagerBlockchain(grantInfo);
    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: t("unableToSendDataToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(setAppLoadingText(t("signingTransaction")));
      dispatch(toggleAppLoading(false));
      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);

      dispatch(toggleAppLoading(true));

      // const signed = [];
      // signed.push(signedTxn[0]);
      // signed.push(signedTxn[1]);
      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => {
            return Buffer.from(sign, "base64");
          })
        )
        .do();
      console.log("222", transationRes);
      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );
      console.log("333", confirmedTxn);
      //2nd time
      let grantDataBlockchain = await algosdk.waitForConfirmation(
        client,
        transactionArr[1].txn.txID(),
        4
      );
      dispatch(setAppLoadingText(""));

      const grantAppId =
        grantDataBlockchain["inner-txns"][0]["application-index"];
      if (grantAppId) {
        return {
          success: true,
          grantAppId,
        };
      } else {
        return {
          success: false,
        };
      }
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleSubmit = async (values: any) => {
    setValues(values);
    setShowWalletButtons(true);
  };

  const handleSubmitOption = async () => {
    //state true
    try {
      // setloading(true);
      if (!isEditing) {
        const blockchainRes = await handleCreateGrantManagerBlockchain();
        if (!blockchainRes.success) {
          return;
        }
        values.grantCreatorId = userslice.userId;
        values.userAppId = blockchainRes.grantAppId;
        values.role = "grant-manager";
      }
      const res: any = isEditing
        ? await updateGrantManager({ ...values, grantManagerId: managerId })
        : await createGrantManager(values);
      if (res.success) {
        const toastPlayload = {
          active: true,
          text:
            isEditing && userslice.role === userRole.GRANT_CREATOR
              ? t("grantManagerUpdatedSuccessfully")
              : isEditing && userslice.role === userRole.GRANT_MANAGER
              ? t("profileUpdatedSuccessfully")
              : t("grantManagerCreatedSuccessfully"),
          success: true,
        };
        dispatch(setToaster(toastPlayload));

        navigate(
          userslice.role === userRole.GRANT_CREATOR
            ? `/user/grant-managers`
            : `/user/grant-list`
        );
      } else {
        const toastPlayload = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toastPlayload));
        dispatch(toggleAppLoading(false));
      }
    } finally {
      setloading(false);
    }
  };

  const validationSchema = () => {
    return Yup.object().shape({
      fullName: Yup.string()
        .trim()
        .max(100, "* Maximum 100 characters are allowed.")
        .required("* Please enter your full name."),
      description: Yup.string()
        .trim()
        .max(30000, "* Maximum 30000 characters are allowed.")
        .required("* Please enter description."),
      email: Yup.string()
        .trim()
        .email("* Please enter a valid email.")
        .required("* Please enter your email address."),
      facebookURL: Yup.string()
        .trim()
        .max(200, "* Maximum 200 characters are allowed.")
        .matches(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "* Please Enter a valid URL"
        ),
      displayName: Yup.string()
        .trim()
        .max(200, "* Maximum 200 characters are allowed.")
        .required("* Please enter your display name."),
      phone: Yup.string()
        .trim()
        .min(10, "* Minimum 10 digits are allowed.")
        // .max(10, "* Maximum 10 digits are allowed.")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Please enter a valid phone number"
        ),
      instagramURL: Yup.string()
        .trim()
        .max(200, "* Maximum 200 characters are allowed.")
        .matches(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Please Enter a valid URL"
        ),
      website: Yup.string()
        .trim()
        .max(200, "* Maximum 200 characters are allowed.")
        .matches(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "* Please Enter a valid URL"
        )
        .required("* Please enter your website."),
      twitterURL: Yup.string()
        .trim()
        .max(200, "* Maximum 200 characters are allowed.")
        .matches(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "* Please Enter a valid URL"
        ),
      clubhouseURL: Yup.string()
        .trim()
        .max(200, "* Maximum 200 characters are allowed.")
        .matches(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "* Please Enter a valid URL"
        ),
      youtubeURL: Yup.string()
        .trim()
        .max(200, "* Maximum 200 characters are allowed.")
        .matches(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "* Please Enter a valid URL"
        ),
    });
  };

  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);

  const formik = useFormik({
    initialValues: manager,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  const goBack = () => {
    navigate(
      userslice.role === userRole.GRANT_CREATOR
        ? `/user/grant-managers`
        : `/user/grant-list`
    );
  };
  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };
  return (
    <div>
      <Box sx={CampaigngForm}>
        <Typography variant="h4" mt={3} align="center" mb={3}>
          {t("createGrantManager")}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3} sx={{ textAlign: "left" }}>
            <Grid item lg={4} md={6}>
              <FormLabel component="legend">{t("fullName")}</FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                type={"text"}
                placeholder={t("enterFullName") || ""}
                variant="outlined"
                name="fullName"
                value={formik.values.fullName}
                onChange={formik.handleChange("fullName")}
                helperText={
                  formik.touched.fullName && formik.errors.fullName
                    ? formik.errors.fullName
                    : ""
                }
                error={
                  formik.touched.fullName && formik.errors.fullName
                    ? true
                    : false
                }
              />
              <FormLabel component="legend">{t("displayName")}</FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                type={"text"}
                placeholder={t("enterDisplayName") || ""}
                variant="outlined"
                name="displayName"
                value={formik.values.displayName}
                onChange={formik.handleChange("displayName")}
                helperText={
                  formik.touched.displayName && formik.errors.displayName
                    ? formik.errors.displayName
                    : ""
                }
                error={
                  formik.errors.displayName && formik.touched.displayName
                    ? true
                    : false
                }
              />
              <FormLabel component="legend">{t("description")}</FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                multiline
                minRows={4}
                type={"text"}
                placeholder={t("enterDescription") || ""}
                variant="outlined"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange("description")}
                helperText={
                  formik.errors.description && formik.touched.description
                    ? formik.errors.description
                    : ""
                }
                error={
                  formik.errors.description && formik.touched.description
                    ? true
                    : false
                }
              />
            </Grid>

            <Grid item lg={4} md={6}>
              <FormLabel component="legend">{t("contactEmail")}</FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                type={"text"}
                placeholder={t("enterEmailAddress") || ""}
                variant="outlined"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange("email")}
                helperText={
                  formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : ""
                }
                disabled={isEditing}
                error={
                  formik.errors.email && formik.touched.email ? true : false
                }
              />
              <FormLabel component="legend">
                {t("contactPhone")} ({t("Optional")})
              </FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                type={"text"}
                placeholder={t("enterPhoneNo") || ""}
                variant="outlined"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange("phone")}
                helperText={
                  formik.errors.phone && formik.touched.phone
                    ? formik.errors.phone
                    : ""
                }
                error={
                  formik.errors.phone && formik.touched.phone ? true : false
                }
              />
              <FormLabel component="legend">{t("website")}</FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                multiline
                type={"text"}
                placeholder={t("enterWebsite") || ""}
                variant="outlined"
                name="website"
                value={formik.values.website}
                onChange={formik.handleChange("website")}
                helperText={
                  formik.errors.website && formik.touched.website
                    ? formik.errors.website
                    : ""
                }
                error={
                  formik.errors.website && formik.touched.website ? true : false
                }
              />
              {/* <FormLabel component="legend">Video (Optional)</FormLabel>
            <UploadVideo
              setVideo={(vl: any | undefined) => setVideo(vl)}
              video={video}
              setVideoName={(vl: any | undefined) => setVideoName(vl)}
              setVideoSelected={(vl: any | undefined) => setVideoSelected(vl)}
              videoName={videoName}
              videoSelected={videoSelected}
              videoRef={videoRef}
              inputRef={inputRef}
            /> */}
              <FormLabel component="legend">
                {t("facebookUrl")} ({t("optional")})
              </FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                type={"text"}
                placeholder={t("enterFacebookProfileLink") || ""}
                variant="outlined"
                name="facebookURL"
                value={formik.values.facebookURL}
                onChange={formik.handleChange("facebookURL")}
                helperText={
                  formik.errors.facebookURL && formik.touched.facebookURL
                    ? formik.errors.facebookURL
                    : ""
                }
                error={
                  formik.errors.facebookURL && formik.touched.facebookURL
                    ? true
                    : false
                }
              />
            </Grid>
            <Grid item lg={4} md={6}>
              {/* <FormLabel component="legend">Video</FormLabel>*/}

              <FormLabel component="legend">
                {t("instagramUrl")} ({t("optional")})
              </FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                type={"text"}
                placeholder={t("enterInstagramProfileLink") || ""}
                variant="outlined"
                name="instagramURL"
                value={formik.values.instagramURL}
                onChange={formik.handleChange("instagramURL")}
                helperText={
                  formik.errors.instagramURL && formik.touched.instagramURL
                    ? formik.errors.instagramURL
                    : ""
                }
                error={
                  formik.errors.instagramURL && formik.touched.instagramURL
                    ? true
                    : false
                }
              />
              <FormLabel component="legend">
                {t("twitterUrl")} ({t("optional")})
              </FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                type={"text"}
                placeholder={t("enterTwitterProfileLink") || ""}
                variant="outlined"
                name="twitterURL"
                value={formik.values.twitterURL}
                onChange={formik.handleChange("twitterURL")}
                helperText={
                  formik.errors.twitterURL && formik.touched.twitterURL
                    ? formik.errors.twitterURL
                    : ""
                }
                error={
                  formik.errors.twitterURL && formik.touched.twitterURL
                    ? true
                    : false
                }
              />
              <FormLabel component="legend">
                {t("clubhouseUrl")} ({t("optional")})
              </FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                type={"text"}
                placeholder={t("enterClubhouseLink") || ""}
                variant="outlined"
                name="clubhouseURL"
                value={formik.values.clubhouseURL}
                onChange={formik.handleChange("clubhouseURL")}
                helperText={
                  formik.errors.clubhouseURL && formik.touched.clubhouseURL
                    ? formik.errors.clubhouseURL
                    : ""
                }
                error={
                  formik.errors.clubhouseURL && formik.touched.clubhouseURL
                    ? true
                    : false
                }
              />
              <FormLabel component="legend">
                {t("youtubeUrl")} ({t("optional")})
              </FormLabel>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                hiddenLabel
                type={"text"}
                placeholder={t("enterYoutubeChannelLink") || ""}
                variant="outlined"
                name="youtubeURL"
                value={formik.values.youtubeURL}
                onChange={formik.handleChange("youtubeURL")}
                helperText={
                  formik.errors.youtubeURL && formik.touched.youtubeURL
                    ? formik.errors.youtubeURL
                    : ""
                }
                error={
                  formik.errors.youtubeURL && formik.touched.youtubeURL
                    ? true
                    : false
                }
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: "50px",
              marginBottom: "60px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <Button
                color="error"
                variant="contained"
                className="round-button"
                onClick={goBack}
              >
                {t("back")}
              </Button>
              <>
                {isEditing ? (
                  <Button
                    style={{
                      marginLeft: "20px",
                    }}
                    color="primary"
                    variant="contained"
                    className="round-button"
                    type="submit"
                  >
                    {t("update")}
                  </Button>
                ) : (
                  <Button
                    style={{
                      marginLeft: "20px",
                    }}
                    color="primary"
                    variant="contained"
                    className="round-button"
                    type="submit"
                  >
                    {t("createAndInvite")}
                  </Button>
                )}
                {showWalletButtons && (
                  <Wallets
                    open={showWalletButtons}
                    handleClose={() => setShowWalletButtons(false)}
                    handleSubmit={handleSubmitOption}
                  />
                )}
              </>
            </Box>
          </Box>
        </form>
      </Box>
    </div>
  );
}
export default CreateGrantmanager;
