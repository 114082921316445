import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InputFiles } from "typescript";

export interface campaignState {
  title: string;
  YearlyOutput: number;
  CostPerUnit: number;
  description: string;
  Results: string;
  location: any;
  lat: any;
  lng: any;
  campaignCategory: string;
  endDate: string | Date;
  fundingCategory: string;
  goalAmount: string;
  country: string;
  socialVideoUrl: string | undefined;
  videoUrl: string;
  youTubeLink: string;
  imageUrl: any | undefined;
  previewEnabled: boolean;
  video: any | undefined;
  image: any | undefined;
  imageNames: any | undefined;
  videoSelected: boolean;
  imageSelected: boolean;
  goalIds: string[];
  isEsgEnabled: boolean | undefined;
  isReforestationProject: boolean | undefined;
}

interface rewardType {
  rewardType?: string;
  rewardNftId?: string;
}

const initialState: campaignState & rewardType = {
  title: "",
  YearlyOutput: 0,
  CostPerUnit: 0,
  description: "",
  Results: "",
  location: "",
  lat: "",
  lng: "",
  campaignCategory: "",
  endDate: new Date(),
  fundingCategory: "",
  goalAmount: "",
  country: "",
  socialVideoUrl: "",
  videoUrl: "",
  youTubeLink: "",
  imageUrl: [],
  previewEnabled: false,
  video: undefined,
  image: [],
  imageNames: [],
  videoSelected: false,
  imageSelected: false,
  rewardType: "",
  rewardNftId: undefined,
  goalIds: [],
  isEsgEnabled: undefined,
  isReforestationProject: undefined,
};

export const campaignPreviewSlice = createSlice({
  name: "CampaignPreviewSlice",
  initialState,
  reducers: {
    setCampaignPreview: (state, action: PayloadAction<campaignState>) => {
      console.log("30", action, state);
      state.title = action.payload.title;
      state.YearlyOutput = action.payload.YearlyOutput;
      state.CostPerUnit = action.payload.CostPerUnit;
      state.description = action.payload.description;
      state.Results = action.payload.Results;
      state.location = action.payload.location;
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
      state.campaignCategory = action.payload.campaignCategory;
      state.endDate = action.payload.endDate;
      state.fundingCategory = action.payload.fundingCategory;
      state.goalAmount = action.payload.goalAmount;
      state.country = action.payload.country;
      state.socialVideoUrl = action.payload.socialVideoUrl;
      state.youTubeLink = action.payload.youTubeLink;
      state.videoUrl = action.payload.videoUrl;
      state.imageUrl = action.payload.imageUrl;
      state.previewEnabled = action.payload.previewEnabled;
      state.video = action.payload.video;
      state.videoSelected = action.payload.videoSelected;
      state.image = action.payload.image;
      state.imageSelected = action.payload.imageSelected;
      state.goalIds = action.payload.goalIds;
      state.isEsgEnabled = action.payload.isEsgEnabled;
      state.isReforestationProject = action.payload.isReforestationProject;
    },
    setPreview: (state, action: PayloadAction<boolean>) => {
      console.log("31", state, action);
      state.previewEnabled = action.payload;
    },
    setRewardType: (state, action: PayloadAction<rewardType>) => {
      console.log("32", state, action);
      if (action.payload.rewardType !== undefined) {
        console.log("33", state, action);
        state.rewardType = action.payload.rewardType;
      }
      if (action.payload.rewardNftId !== undefined) {
        console.log("34", state, action);
        state.rewardNftId = action.payload.rewardNftId;
      }
    },
  },
});

export const { setCampaignPreview, setPreview, setRewardType } =
  campaignPreviewSlice.actions;

export default campaignPreviewSlice.reducer;
