import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  TextField,
  Select,
  MenuItem,
  FormLabel,
  SelectChangeEvent,
  FormHelperText,
  Typography,
  IconButton,
  Menu,
  Modal,
} from "@mui/material";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Wallets from "../../../wallets";
import { CampaigngForm } from "./styles";
import UploadImage from "./UploadImage";
import moment from "moment";
import countryList from "./countrylist";
import UploadVideo from "./UploadVideo";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  campaignState,
  setCampaignPreview,
  setPreview,
  setRewardType,
} from "../../../store/slices/CreateCampaignSlice";
import { InputFiles } from "typescript";
import {
  createCampaign,
  saveToDraft,
  uploadCampaignImage,
  uploadCampaignVideo,
} from "../../../requests/authRequest";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import {
  getCampaignCategories,
  getFundingCategories,
} from "../../../requests/simpleRequest";
import { useNavigate } from "react-router-dom";
import { setSelectRewardTypeModal } from "../../../store/slices/ModalSlice";
import {
  STAGING_FUNDING_CATEGORY,
  TEST_FUNDING_CATEGORY,
  PRODUCTION_FUNDING_CATEGORY,
} from "./constant";
import MilestoneSet from "../../home/campaigns/MilestoneSet";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NotificationToast from "../../../loaders/NotificationToast";
import Preview from "../preview/preview";
import algo from "../../../assets/images/algo.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

interface milestone {
  title: string;
  defination: string;
  deadline: Date | string;
  delivereble: any;
}

function CreateCampaign({
  handleSteps,
  currentCampaignId,
  setCurrentCampaignId,
  title,
  handleTitle,
  YearlyOutput,
  handleYearlyOutput,
  CostPerUnit,
  handleCostPerUnit,
  Results,
  handleResults,
  description,
  handleDescription,
  category,
  handleCategory,
  fundingCategory,
  handleFundingCategory,
  endDate,
  handleEndDate,
  location,
  handleLocation,
  country,
  youTubeLink,
  handleYoutubelink,
  handleCountry,
  goalAmount,
  handleGoalAmount,
  image,
  handleImage,
  imageName,
  handleImageName,
  video,
  handleVideo,
  videoName,
  handleVideoName,
  videoSelected,
  handleVideoSelected,
  milestoneOne,
  handleMilestoneOne,
  milestoneTwo,
  handleMilestoneTwo,
  ESG_SDG_status,
  handleESG_SDG_status,
  monitoringStatus,
  handleMonitoringStatus,
  handleCampaignCreate,
  coordinates,
  setCoordinates,
}: any) {
  const { t } = useTranslation();
  document
    .getElementById("campaignEndingDate")
    ?.setAttribute("disabled", "disabled");
  const campaignState = useAppSelector((state) => state.campaignPreviewReducer);

  const kyc = useAppSelector((state) => state.userReducer.kyc);

  const [imageSelected, setImageSelected] = useState<boolean>(
    campaignState.imageSelected
  );
  const imageRef = useRef<HTMLElement>();
  const [selectedImageId, setSelectedImageId] = useState<number>(0);

  const videoRef = useRef<HTMLVideoElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  //this is for categories
  const [campCategoryList, setCampCategoryList] = useState([]);
  const [fundCategoryList, setFundCategoryList] = useState([]);

  // this is for rewards

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const preview = useAppSelector(
    (state) => state.campaignPreviewReducer.previewEnabled
  );

  // this is for nft as reward
  const selectedNft = useAppSelector(
    (state) => state.campaignPreviewReducer.rewardNftId
  );
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const rewardType = useAppSelector(
    (state) => state.campaignPreviewReducer.rewardType
  );

  const handleCategoryChange = (e: SelectChangeEvent) => {
    handleCategory(e.target.value);
  };

  const handleFundingCategoryChange = (e: SelectChangeEvent) => {
    handleFundingCategory(e.target.value);
  };

  const handleCountryChange = (e: SelectChangeEvent) => {
    handleCountry(e.target.value);
  };

  const handleChange = (newValue: any) => {
    if (newValue !== null) {
      handleEndDate(new Date(newValue));
    }
  };

  const [locOption, setLocOption] = React.useState("location");
  const [invalidlatitude, setInvalidlatitude] = useState<boolean>(false);

  const [invalidlongitude, setInvalidlongitude] = useState<boolean>(false);

  // these are for form validations
  const [invalidTitle, setInvalidTitle] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>("");
  const [invalidYearlyOutput, setinvalidYearlyOutput] =
    useState<boolean>(false);
  const [errorYearlyOutput, setErrorYearlyOutput] = useState<string>("");
  const [invalidCostPerUnit, setinvalidCostPerUnit] = useState<boolean>(false);
  const [errorCostPerUnit, setErrorCostPerUnit] = useState<string>("");
  const [invalidResults, setinvalidResults] = useState<boolean>(false);
  const [errorResults, setErrorResults] = useState<string>("");
  const [invalidLocation, setInvalidLocation] = useState<boolean>(false);
  const [errorLocation, setErrorLocation] = useState<string>("");

  // these are for form validations
  const [invalidEsgOption, setInvalidEsgOption] = useState<boolean>(false);
  const [errorInvalidEsgTitle, setErrorInvalidEsgTitle] = useState<string>("");
  const [invalidMonitoringOption, setInvalidMonitoringOption] =
    useState<boolean>(false);
  const [errorInvalidMonitoringTitle, setErrorInvalidMonitoringTitle] =
    useState<string>("");

  const [invalidDescription, setInvalidDescription] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<string>("");

  const [invalidEndDate, setInvalidEndDate] = useState<boolean>(false);
  const [errorEndDate, setErrorEndDate] = useState<string>("");

  const [invalidGoalAmount, setInvalidGoalAmount] = useState<boolean>(false);
  const [errorGoalAmount, setErrorGoalAmount] = useState<string>("");

  const [invalidCategory, setInvalidCategory] = useState<boolean>(false);
  const [invalidFundingCategory, setInvalidFundingCategory] =
    useState<boolean>(false);
  const [invalidCountry, setInvalidCountry] = useState<boolean>(false);

  const [invalidNft, setInvalidNft] = useState<boolean>(false);

  const [imageErrorMsg, setImageErrorMsg] = useState<string>("");

  let blockchainCampaignAppId = "";
  let milestoneOneAppId = "";
  let milestoneTwoAppId = "";
  // let milestoneThreeAppId = "";

  const [invalidDateOne, setInvalidDateOne] = useState<boolean>(false);
  const [invalidDateTwo, setInvalidDateTwo] = useState<boolean>(false);
  const [invalidTitleOne, setInvalidTitleOne] = useState<boolean>(false);
  const [invalidTitleTwo, setInvalidTitleTwo] = useState<boolean>(false);
  const [invalidDefinitionOne, setInvalidDefinitionOne] =
    useState<boolean>(false);
  const [invalidDefinitionTwo, setInvalidDefinitionTwo] =
    useState<boolean>(false);
  // const [currentCampaignId, setCurrentCampaignId] = useState<string>("");

  //location
  // const [coordinates, setCoordinates] = useState<{
  //   lat: 0 | number;
  //   lng: 0 | number;
  // }>({
  //   lat: 0,
  //   lng: 0,
  // });
  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    handleLocation(value);
    setCoordinates(ll);
  };
  /**
   * create campaign refs
   */

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const resultsRef = useRef(null);
  const YearlyOutputRef = useRef(null);
  const CostPerUnitRef = useRef(null);
  const locationRef = useRef(null);
  const endDateRef = useRef(null);
  const goalAmountRef = useRef(null);
  const categoryRef = useRef(null);
  const countryRef = useRef(null);
  const fundingCategoryRef = useRef(null);
  const coordinatesRef = useRef(null);

  /**
   * create campaign refs end
   *
   */

  const previewCampaign = useAppSelector(
    (state) => state.campaignPreviewReducer.previewEnabled
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  /**
   *
   * menu
   */

  const validateMilestoneData = () => {
    let validity = true;

    // for milestone one
    if (!moment(milestoneOne.deadline).isAfter(endDate, "day")) {
      validity = false;
      setInvalidDateOne(true);
    } else {
      setInvalidDateOne(false);
    }

    if (milestoneOne.title.trim().length <= 0) {
      validity = false;
      setInvalidTitleOne(true);
    } else if (milestoneOne.title.length > 100) {
      validity = false;
      setInvalidTitleOne(true);
    } else {
      setInvalidTitleOne(false);
    }

    if (milestoneOne.defination.trim().length <= 0) {
      validity = false;
      setInvalidDefinitionOne(true);
    } else if (milestoneOne.defination.trim().length > 3000) {
      validity = false;
      setInvalidDefinitionOne(true);
    } else {
      setInvalidDefinitionOne(false);
    }

    // for milestone Two
    if (!moment(milestoneTwo.deadline).isAfter(milestoneOne.deadline, "day")) {
      validity = false;
      setInvalidDateTwo(true);
    } else {
      setInvalidDateTwo(false);
    }

    if (milestoneTwo.title.trim().length <= 0) {
      validity = false;
      setInvalidTitleTwo(true);
    } else if (milestoneTwo.title.length > 100) {
      validity = false;
      setInvalidTitleTwo(true);
    } else {
      setInvalidTitleTwo(false);
    }

    if (milestoneTwo.defination.trim().length <= 0) {
      validity = false;
      setInvalidDefinitionTwo(true);
    } else if (milestoneTwo.defination.trim().length > 3000) {
      validity = false;
      setInvalidDefinitionTwo(true);
    } else {
      setInvalidDefinitionTwo(false);
    }

    // // for milestone three
    // if (
    // 	!moment(milestoneThree.deadline).isAfter(milestoneTwo.deadline, "day")
    // ) {
    // 	validity = false;
    // 	setInvalidDateThree(true);
    // } else {
    // 	setInvalidDateThree(false);
    // }

    // if (milestoneThree.title.trim().length <= 0) {
    // 	validity = false;
    // } else if (milestoneThree.title.length > 100) {
    // 	validity = false;
    // }

    // if (milestoneThree.defination.trim().length <= 0) {
    // 	validity = false;
    // } else if (milestoneThree.defination.trim().length > 3000) {
    // 	validity = false;
    // }

    return validity;
  };
  const handleSubmit = () => {
    const valid = validateForm();
    const validMilestone = validateMilestoneData();

    if (!valid || !validMilestone) {
      return;
    } else {
      setErrorInvalidEsgTitle("");
      handleSaveToDraft(false, true);
    }
  };
  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();
    const valid = validateForm();
    const validMilestone = validateMilestoneData();

    if (valid && validMilestone) {
      setShowWalletButtons(true);
    }
  };

  const validateForm = () => {
    let validity = true;
    // for title
    if (title.trim().length <= 0) {
      validity = false;
      setInvalidTitle(true);
      setErrorTitle(t("pleaseEnterTheTitle") || "");
    } else if (title.length > 100) {
      validity = false;
      setInvalidTitle(true);
      setErrorTitle(t("titleMustBeHundredCharOrSmaller") || "");
    } else {
      setInvalidTitle(false);
      setErrorTitle("");
    }

    // for description
    // const MAX_WORD_LIMIT = 500;
    // const wordCount = description.trim().split(/\s+/).length;
    if (description.trim().length <= 0) {
      validity = false;
      setInvalidDescription(true);
      setErrorDescription(t("pleaseEnterTheDescription") || "");
    } else if (description.trim().length > 30000) {
      validity = false;
      setInvalidDescription(true);
      setErrorDescription("Length Exceeds Limit" || "");
    } else {
      setInvalidDescription(false);
      setErrorDescription("");
    }
    //for results
    if (monitoringStatus.title === "Yes") {
      validity = true;
      setInvalidMonitoringOption(false);
      setErrorInvalidMonitoringTitle("");

      if (!Results?.trim().length) {
        validity = false;
        setinvalidResults(true);
        setErrorResults(t("pleaseEnterTheResults") || "");
      } else {
        setinvalidResults(false);
        setErrorResults("");
      }
      //yearly output
      if (isNaN(parseInt(YearlyOutput)) || parseInt(YearlyOutput) <= 0) {
        validity = false;
        setinvalidYearlyOutput(true);
        setErrorYearlyOutput(t("pleaseEnterYearlyOutput") || "");
      } else {
        setinvalidYearlyOutput(false);
        setErrorYearlyOutput("");
      }
      //CostPerUnit
      if (isNaN(parseInt(CostPerUnit)) || parseInt(CostPerUnit) <= 0) {
        validity = false;
        setinvalidCostPerUnit(true);
        setErrorCostPerUnit(t("pleaseEnterCostPerUnit") || "");
      } else {
        setinvalidCostPerUnit(false);
        setErrorCostPerUnit("");
      }

      // location

      if (locOption === "location" && !location?.trim().length) {
        validity = false;
        setInvalidLocation(true);
        setErrorLocation(t("pleaseEnterLocation") || "");
      } else {
        setInvalidLocation(false);
        setErrorLocation("");
      }

      if (locOption === "coordinates" && !coordinates.lat) {
        validity = false;
        setInvalidlatitude(true);
      } else {
        setInvalidlatitude(false);
      }

      if (locOption === "coordinates" && !coordinates.lng) {
        validity = false;
        setInvalidlongitude(true);
      } else {
        setInvalidlongitude(false);
      }
    } else {
      setInvalidMonitoringOption(true);
      setErrorInvalidMonitoringTitle("Please Select any Option");
    }

    // for end Date
    if (
      moment(new Date(endDate)).startOf("day").unix() <=
      moment().startOf("day").unix()
    ) {
      validity = false;
      setInvalidEndDate(true);
      setErrorEndDate(t("pleaseSelectValidFutureDate") || "");
    } else {
      setInvalidEndDate(false);
      setErrorEndDate("");
    }

    // for goal amount
    if (
      isNaN(parseInt(goalAmount)) ||
      parseInt(goalAmount) < 5 ||
      parseInt(goalAmount) > 1000000
    ) {
      validity = false;
      setInvalidGoalAmount(true);
      setErrorGoalAmount(t("pleaseEnterAmountBetweenFiveAndTenLakh") || "");
    } else {
      setInvalidGoalAmount(false);
      setErrorGoalAmount("");
    }

    // for dropdowns
    if (category.length === 0) {
      validity = false;
      setInvalidCategory(true);
    } else {
      setInvalidCategory(false);
    }

    if (country.length === 0) {
      validity = false;
      setInvalidCountry(true);
    } else {
      setInvalidCountry(false);
    }

    if (fundingCategory.length === 0) {
      validity = false;
      setInvalidFundingCategory(true);
    } else {
      setInvalidFundingCategory(false);
    }
    setImageErrorMsg("");
    if (!imageName?.length) {
      validity = false;
      setImageErrorMsg(t("pleaseSelectImageToUpload") || "");
    }
    // this is for nft as a reward
    if (
      fundingCategory === TEST_FUNDING_CATEGORY ||
      fundingCategory === STAGING_FUNDING_CATEGORY ||
      fundingCategory === PRODUCTION_FUNDING_CATEGORY
    ) {
      if (!selectedNft || selectedNft?.length <= 0) {
        setInvalidNft(true);
        validity = false;
      } else {
        setInvalidNft(false);
      }
    }

    return validity;
  };

  const handlePreview = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const valid = validateForm();
    const validMilestone = validateMilestoneData();

    if (!valid || !validMilestone || imageName.length === 0) {
      const toastPaylaod = {
        text: t("pleaseFillAllFields"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      return;
    }

    const payload: campaignState = {
      title: title,
      description: description,
      YearlyOutput: YearlyOutput,
      CostPerUnit: CostPerUnit,
      youTubeLink: youTubeLink,
      Results: Results,
      location: location,
      lat: coordinates.lat,
      lng: coordinates.lng,
      campaignCategory: category,
      fundingCategory: fundingCategory,
      endDate: endDate,
      country: country,
      goalAmount: goalAmount,
      socialVideoUrl: "",
      videoUrl: videoName,
      imageUrl: imageName,
      previewEnabled: preview,
      video: video,
      image: image,
      imageNames: imageName,
      imageSelected: imageSelected,
      videoSelected: videoSelected,
      goalIds: [],
      isEsgEnabled: ESG_SDG_status.status,
      isReforestationProject: monitoringStatus.status,
    };
    dispatch(setCampaignPreview(payload));
    dispatch(setPreview(true));
    const milestoneInfo = {
      one: {
        title: milestoneOne?.title,
        definition: milestoneOne?.defination,
        deadline: milestoneOne?.deadline,
        deliverable: "non",
        milestoneAppId: milestoneOneAppId,
      },
      two: {
        title: milestoneTwo?.title,
        definition: milestoneTwo?.defination,
        deadline: milestoneTwo?.deadline,
        deliverable: "non",
        milestoneAppId: milestoneTwoAppId,
      },
    };
    localStorage.setItem("milestones", JSON.stringify(milestoneInfo));
  };

  const handleImageUpload = async () => {
    if (image?.length > 0) {
      const res: any = await uploadCampaignImage(image);
      if (res.success) {
        return res?.data;
      } else {
        return false;
      }
    }
  };

  const handleVideoUpload = async () => {
    if (videoSelected) {
      const res = await uploadCampaignVideo(video);
      if (res.success) {
        return res.data.fileId;
      } else {
        return false;
      }
    }
  };

  /**
   * handle save to draft function
   */

  const handleSaveToDraft = async (
    nextStatus: boolean,
    submitStatus: boolean
  ) => {
    const valid = validateForm();
    // const validMilestone = validateMilestoneData();

    if (!valid) {
      return;
    }
    dispatch(toggleAppLoading(true));
    dispatch(setAppLoadingText(t("savingToDraft")));

    // dispatch(setAppLoadingText("Uploading Image"));
    const images = await handleImageUpload();
    // dispatch(setAppLoadingText("Uploading Video"));
    const video = await handleVideoUpload();
    let payload: any = {};
    if (
      fundingCategory === TEST_FUNDING_CATEGORY ||
      fundingCategory === STAGING_FUNDING_CATEGORY ||
      fundingCategory === PRODUCTION_FUNDING_CATEGORY
    ) {
      if (currentCampaignId === "") {
        payload = {
          title: title,
          description: description,
          expectedResults: Results,
          expectedYearlyoutput: YearlyOutput,
          expectedCostPerUnit: CostPerUnit,
          location: location,
          lat: coordinates.lat,
          lng: coordinates.lng,
          category: category,
          fundingCategory: fundingCategory,
          endDate: moment(endDate).format("YYYY-MM-DD"),
          goalAmount: goalAmount,
          country: country,
          images: !images ? [] : images,
          video: !video ? "" : video,
          videoUrl: "https://youtu.be/ouncVBiye_M",
          youTubeLink: !youTubeLink ? "" : youTubeLink,
          rewardBased: true,
          rewardType: rewardType,
          reward: selectedNft,
          campaignAppId: blockchainCampaignAppId,
          milestones: [
            {
              title: milestoneOne.title ? milestoneOne.title : "",
              definition: milestoneOne.defination
                ? milestoneOne.defination
                : "",
              deadline: milestoneOne.deadline ? milestoneOne.deadline : "",
              deliverable: "non",
              milestoneAppId: milestoneOneAppId,
            },
            {
              title: milestoneTwo.title ? milestoneTwo.title : "",
              definition: milestoneTwo.defination
                ? milestoneTwo.defination
                : "",
              deadline: milestoneTwo.deadline ? milestoneTwo.deadline : "",
              deliverable: "non",
              milestoneAppId: milestoneTwoAppId,
            },
          ],
          isEsgEnabled: ESG_SDG_status.status,
          isReforestationProject: monitoringStatus.status,
        };
      } else {
        payload = {
          campaignId: currentCampaignId,
          title: title,
          description: description,
          expectedResults: Results,
          expectedYearlyoutput: YearlyOutput,
          location: location,
          lat: coordinates.lat,
          lng: coordinates.lng,
          expectedCostPerUnit: CostPerUnit,
          category: category,
          fundingCategory: fundingCategory,
          endDate: moment(endDate).format("YYYY-MM-DD"),
          goalAmount: goalAmount,
          country: country,
          images: !images ? [] : images,
          video: !video ? "" : video,
          videoUrl: "https://youtu.be/ouncVBiye_M",
          youTubeLink: !youTubeLink ? "" : youTubeLink,
          rewardBased: true,
          rewardType: rewardType,
          reward: selectedNft,
          campaignAppId: blockchainCampaignAppId,
          milestones: [
            {
              title: milestoneOne.title ? milestoneOne.title : "",
              definition: milestoneOne.defination
                ? milestoneOne.defination
                : "",
              deadline: milestoneOne.deadline ? milestoneOne.deadline : "",
              deliverable: "non",
              milestoneAppId: milestoneOneAppId,
            },
            {
              title: milestoneTwo.title ? milestoneTwo.title : "",
              definition: milestoneTwo.defination
                ? milestoneTwo.defination
                : "",
              deadline: milestoneTwo.deadline ? milestoneTwo.deadline : "",
              deliverable: "non",
              milestoneAppId: milestoneTwoAppId,
            },
          ],
          isEsgEnabled: ESG_SDG_status.status,
          isReforestationProject: monitoringStatus.status,
        };
      }
    } else {
      if (currentCampaignId === "") {
        payload = {
          title: title,
          description: description,
          expectedResults: Results,
          expectedYearlyoutput: YearlyOutput,
          location: location,
          lat: coordinates.lat,
          lng: coordinates.lng,
          expectedCostPerUnit: CostPerUnit,
          category: category,
          fundingCategory: fundingCategory,
          endDate: moment(endDate).format("YYYY-MM-DD"),
          goalAmount: goalAmount,
          country: country,
          images: !images ? [] : images,
          video: !video ? "" : video,
          videoUrl: "https://youtu.be/ouncVBiye_M",
          youTubeLink: !youTubeLink ? "" : youTubeLink,
          campaignAppId: blockchainCampaignAppId,
          milestones: [
            {
              title: milestoneOne.title ? milestoneOne.title : "",
              definition: milestoneOne.defination
                ? milestoneOne.defination
                : "",
              deadline: milestoneOne.deadline ? milestoneOne.deadline : "",
              deliverable: "anything",
              milestoneAppId: milestoneOneAppId,
            },
            {
              title: milestoneTwo.title ? milestoneTwo.title : "",
              definition: milestoneTwo.defination
                ? milestoneTwo.defination
                : "",
              deadline: milestoneTwo.deadline ? milestoneTwo.deadline : "",
              deliverable: "anything",
              milestoneAppId: milestoneTwoAppId,
            },
          ],
          isEsgEnabled: ESG_SDG_status.status,
          isReforestationProject: monitoringStatus.status,
        };
      } else {
        payload = {
          campaignId: currentCampaignId,
          title: title,
          description: description,
          expectedResults: Results,
          location: location,
          lat: coordinates.lat,
          lng: coordinates.lng,
          expectedYearlyoutput: YearlyOutput,
          expectedCostPerUnit: CostPerUnit,
          category: category,
          fundingCategory: fundingCategory,
          endDate: moment(endDate).format("YYYY-MM-DD"),
          goalAmount: goalAmount,
          country: country,
          images: !images ? [] : images,
          video: !video ? "" : video,
          videoUrl: "https://youtu.be/ouncVBiye_M",
          youTubeLink: !youTubeLink ? "" : youTubeLink,
          campaignAppId: blockchainCampaignAppId,
          milestones: [
            {
              title: milestoneOne.title ? milestoneOne.title : "",
              definition: milestoneOne.defination
                ? milestoneOne.defination
                : "",
              deadline: milestoneOne.deadline ? milestoneOne.deadline : "",
              deliverable: "anything",
              milestoneAppId: milestoneOneAppId,
            },
            {
              title: milestoneTwo.title ? milestoneTwo.title : "",
              definition: milestoneTwo.defination
                ? milestoneTwo.defination
                : "",
              deadline: milestoneTwo.deadline ? milestoneTwo.deadline : "",
              deliverable: "anything",
              milestoneAppId: milestoneTwoAppId,
            },
          ],
          isEsgEnabled: ESG_SDG_status.status,
          isReforestationProject: monitoringStatus.status,
        };
      }
    }

    const res = await saveToDraft(payload);
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(false));

    if (res.success) {
      setCurrentCampaignId(res.data?.campaign?._id);
      const toastPaylaod = {
        text: t("savedToDraftSuccessfully"),
        success: true,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      nextStatus && handleSteps();
      if (submitStatus) {
        dispatch(toggleAppLoading(true));
        dispatch(setAppLoadingText(t("creatingCampaign")));
        handleCampaignCreate(
          res.data?.campaign?.milestones[0]?.milestoneId,
          res.data?.campaign?.milestones[1]?.milestoneId,
          res.data?.campaign?._id
        );
      }
      // cleanFormState();
      // navigationStatus && navigate("/user/my-campaign");
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(toggleAppLoading(false));
    }

    dispatch(toggleAppLoading(false));
    localStorage.removeItem("milestones");
  };

  /**
   * handle save to draft function end
   */

  const fetchCategories = async () => {
    const res1 = await getFundingCategories();
    if (res1.success) {
      setFundCategoryList(res1.data);
    } else {
      const toastPaylaod = {
        text: res1.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
    const res2 = await getCampaignCategories();
    if (res2.success) {
      setCampCategoryList(res2.data);
    } else {
      const toastPaylaod = {
        text: res2.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
  };

  const cleanFormState = () => {
    const payload: campaignState = {
      title: "",
      YearlyOutput: 0,
      CostPerUnit: 0,
      description: "",
      Results: "",
      lat: coordinates.lat,
      lng: coordinates.lng,
      campaignCategory: "",
      fundingCategory: "",
      location: "",
      endDate: "",
      country: "",
      goalAmount: "",
      socialVideoUrl: "",
      videoUrl: "",
      youTubeLink: "",
      imageUrl: "",
      previewEnabled: false,
      video: undefined,
      image: undefined,
      imageNames: undefined,
      videoSelected: false,
      imageSelected: false,
      goalIds: [],
      isEsgEnabled: undefined,
      isReforestationProject: undefined,
    };
    localStorage.removeItem("milestones");
    handleTitle("");
    handleDescription("");
    handleResults("");
    handleYearlyOutput("");
    handleCostPerUnit("");
    handleLocation("");
    handleCategory("");
    handleFundingCategory("");
    handleEndDate("");
    handleCountry("");
    handleGoalAmount("");
    clearvideoData();
    clearImageData();
    const nftData = {
      rewardNftId: "",
      rewardType: "",
    };
    dispatch(setRewardType(nftData));

    dispatch(setCampaignPreview(payload));
  };

  const clearvideoData = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    handleVideo(undefined);
    handleVideoName("");
    handleVideoSelected(false);
    if (videoRef && videoRef.current) {
      videoRef.current.src = "none";
    }
  };

  const clearImageData = () => {
    handleImage([]);
    handleImageName([]);
    setImageSelected(false);
    if (imageRef && imageRef.current) {
      imageRef.current.style.backgroundImage = "none";
    }
  };

  const handleRewardModal = () => {
    dispatch(setSelectRewardTypeModal(true));
  };

  useEffect(() => {
    fetchCategories();
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(false));
  }, []);

  /**
   * auto save to draft
   */

  useEffect(() => {
    let activeElements =
      titleRef.current !== document.activeElement ||
      descriptionRef.current !== document.activeElement ||
      // resultsRef.current !== document.activeElement ||
      // YearlyOutputRef.current !== document.activeElement ||
      // CostPerUnitRef.current !== document.activeElement ||
      // locationRef.current !== document.activeElement ||
      categoryRef.current !== document.activeElement ||
      fundingCategoryRef.current !== document.activeElement ||
      goalAmountRef.current !== document.activeElement ||
      // countryRef.current !== document.activeElement ||
      endDateRef.current !== document.activeElement;

    let FeildsValidation =
      title !== "" &&
      description !== "" &&
      // Results !== "" &&
      // YearlyOutput !== "" &&
      // CostPerUnit !== "" &&
      // location !== "" &&
      category.length !== 0 &&
      fundingCategory.length !== 0 &&
      goalAmount !== "" &&
      country !== "" &&
      endDate !== "";

    if (FeildsValidation && activeElements) {
      setImageErrorMsg("");
      if (!imageName?.length) {
        setImageErrorMsg(t("pleaseSelectImageToUpload") || "");
        return;
      }
      handleSaveToDraft(false, false);
    }
  }, [
    title,
    description,
    // Results,
    // YearlyOutput,
    // location,
    // CostPerUnit,
    goalAmount,
    category,
    fundingCategory,
    country,
    endDate,
    imageName,
  ]);

  /**
   * auto save to draft end
   */

  return (
    <>
      <Box sx={CampaigngForm}>
        <Typography variant="h4" mt={3} align="center" mb={3}>
          {t("createCampaign")}
        </Typography>
        <Grid container spacing={2}>
          <Grid
            item
            lg={4}
            md={6}
            sx={{ width: { md: "auto", sm: "50%", xs: "100%" } }}
          >
            <FormLabel component="legend">Title</FormLabel>
            <TextField
              ref={titleRef}
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              type={"text"}
              placeholder={t("title") || ""}
              variant="outlined"
              onChange={(e) => {
                handleTitle(e.target.value);
              }}
              value={title}
              error={invalidTitle}
              helperText={invalidTitle && errorTitle}
            />
            <FormLabel component="legend">{t("description")}</FormLabel>

            <TextField
              ref={descriptionRef}
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              multiline
              minRows={4}
              type={"text"}
              placeholder={t("description") || ""}
              variant="outlined"
              onChange={(e) => {
                handleDescription(e.target.value);
              }}
              value={description}
              error={invalidDescription}
              helperText={invalidDescription && errorDescription}
            />
            <FormLabel component="legend">{t("image")}</FormLabel>
            <UploadImage
              image={image}
              setImage={handleImage}
              imageName={imageName}
              imageSelected={imageSelected}
              setImageName={handleImageName}
              setImageSelected={setImageSelected}
              imageRef={imageRef}
              imageId={selectedImageId}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "300px",
                width: "100%",
              }}
            >
              {imageName?.length > 1 &&
                imageName?.map((item: any, index: any) => (
                  <IconButton
                    key={index}
                    onClick={() => setSelectedImageId(index)}
                  >
                    <Box
                      sx={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "5px",
                        backgroundColor:
                          index === selectedImageId ? "#000" : "gray",
                        marginX: "2.5px",
                      }}
                    />
                  </IconButton>
                ))}
            </Box>
            {image.length === 0 && (
              <FormHelperText
                sx={{ marginBottom: 3 }}
                className="css-l8pll-MuiFormHelperText-root Mui-error"
              >
                {imageName?.length >= 5
                  ? "You can not upload more than five images"
                  : imageErrorMsg}
              </FormHelperText>
            )}
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            sx={{ width: { md: "auto", sm: "50%", xs: "100%" } }}
          >
            <Box sx={{ marginBottom: 3 }}>
              <FormLabel component="legend">{t("campaignCategory")}</FormLabel>
              <Select
                ref={categoryRef}
                fullWidth
                value={category}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                onChange={handleCategoryChange}
              >
                {campCategoryList.length > 0 &&
                  campCategoryList.map((catg: any) => {
                    return (
                      <MenuItem value={catg.categoryId}>{catg.title}</MenuItem>
                    );
                  })}
              </Select>
              {invalidCategory && (
                <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                  {t("pleaseSelectCategory")}
                </FormHelperText>
              )}
            </Box>
            <Box sx={{ marginBottom: 3 }}>
              <FormLabel component="legend">
                {t("campaignEndingDate")}
              </FormLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DatePicker
                    ref={endDateRef}
                    inputFormat="YYYY-MM-DD"
                    value={endDate}
                    onChange={handleChange}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        error={false}
                        id={"campaignEndingDate"}
                      />
                    )}
                    disablePast
                  />
                </Stack>
              </LocalizationProvider>
              {invalidEndDate && (
                <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                  {errorEndDate}
                </FormHelperText>
              )}
            </Box>
            <Box sx={{ marginBottom: 3 }}>
              <FormLabel component="legend">{t("fundingCategory")}</FormLabel>
              <Select
                ref={fundingCategoryRef}
                fullWidth
                value={fundingCategory}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                onChange={handleFundingCategoryChange}
              >
                {fundCategoryList.length > 0 &&
                  fundCategoryList.map((catg: any) => {
                    return (
                      <MenuItem value={catg.fundingCategoryId}>
                        {catg.title}
                      </MenuItem>
                    );
                  })}
              </Select>
              {invalidFundingCategory && (
                <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                  {t("pleaseSelectFundingCategory")}
                </FormHelperText>
              )}
            </Box>

            <Box sx={{ marginBottom: 3 }}>
              <FormLabel component="legend">
                Goal(
                <img
                  style={{ width: "16px", marginBottom: "-3px" }}
                  src={algo}
                ></img>
                )* between 5 and 1000000
              </FormLabel>
              <TextField
                ref={goalAmountRef}
                fullWidth
                hiddenLabel
                // type={"number"}
                placeholder={t("enterCampaignGoal") || ""}
                variant="outlined"
                onChange={(e) => {
                  if (parseInt(e.target.value) > 1000000) {
                    handleGoalAmount("1000000");
                  } else {
                    handleGoalAmount(e.target.value);
                  }
                }}
                value={goalAmount}
                error={invalidGoalAmount}
                helperText={invalidGoalAmount && errorGoalAmount}
              />
            </Box>
            <Box sx={{ marginBottom: 3 }}>
              <FormLabel component="legend">{t("country")}</FormLabel>
              <Select
                ref={countryRef}
                fullWidth
                value={country}
                onChange={handleCountryChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {countryList.map((country) => {
                  return (
                    <MenuItem value={country.toLocaleLowerCase()} key={country}>
                      {country}
                    </MenuItem>
                  );
                })}
              </Select>
              {invalidCountry && (
                <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                  {t("pleaseSelectCountry")}
                </FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            sx={{
              width: { md: "auto", sm: "50%", xs: "100%" },
            }}
          >
            <FormLabel component="legend">Video (Optional)</FormLabel>
            <UploadVideo
              setVideo={handleVideo}
              video={video}
              setVideoName={handleVideoName}
              setVideoSelected={handleVideoSelected}
              videoName={videoName}
              videoSelected={videoSelected}
              videoRef={videoRef}
              inputRef={inputRef}
            />
            <Typography
              style={{
                fontFamily: "bold",
                fontSize: "15px",
                marginBottom: "3px",
              }}
            >
              OR{" "}
            </Typography>
            <FormLabel component="legend">
              {t("youtubelink")} ({t("optional")})
            </FormLabel>
            <TextField
              sx={{ marginBottom: 3 }}
              fullWidth
              hiddenLabel
              type={"text"}
              placeholder={t("youtubelink") || ""}
              variant="outlined"
              name="youTubeLink"
              value={youTubeLink}
              onChange={(e) => {
                handleYoutubelink(e.target.value);
              }}
            />
            {(fundingCategory === TEST_FUNDING_CATEGORY ||
              fundingCategory === STAGING_FUNDING_CATEGORY ||
              fundingCategory === PRODUCTION_FUNDING_CATEGORY) && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className="round-button"
                  sx={{
                    marginRight: "auto",
                    marginBottom: "10px",
                    backgroundColor: "#000000de",
                    color: "#61f359",
                  }}
                  onClick={handleRewardModal}
                >
                  {t("selectReward")}
                </Button>
                {rewardType === "NFTs" && (
                  <FormLabel component="legend">
                    {t("selectedNft")} - {selectedNft}
                  </FormLabel>
                )}
                {invalidNft && !selectedNft && (
                  <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                    {t("pleaseSelectReward")}
                  </FormHelperText>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
        <MilestoneSet
          milestoneOne={milestoneOne}
          milestoneTwo={milestoneTwo}
          invalidDateOne={invalidDateOne}
          invalidDateTwo={invalidDateTwo}
          setMilestoneOne={handleMilestoneOne}
          setMilestoneTwo={handleMilestoneTwo}
          invalidDefinitionOne={invalidDefinitionOne}
          invalidDefinitionTwo={invalidDefinitionTwo}
          invalidTitleOne={invalidTitleOne}
          invalidTitleTwo={invalidTitleTwo}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: "50px",
            marginBottom: "60px",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
            {t("isCampaignaReforestationProject")}
          </Typography>
          <Box sx={{ marginTop: "20px" }}>
            <Button
              id="basic-button2"
              aria-controls={open2 ? "basic-menu2" : undefined}
              aria-haspopup="true"
              aria-expanded={open2 ? "true" : undefined}
              onClick={handleClick2}
              sx={{ color: "#000", border: "1px solid gray" }}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {monitoringStatus.title !== ""
                ? monitoringStatus.title
                : t("pleaseSelectOption")}
            </Button>
            <Menu
              id="basic-menu2"
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleClose2}
              MenuListProps={{
                "aria-labelledby": "basic-button2",
              }}
            >
              <Box sx={{ width: "200px" }}>
                <MenuItem
                  onClick={() => {
                    handleMonitoringStatus({ title: "Yes", status: true });
                    handleClose2();
                  }}
                >
                  {t("yes")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMonitoringStatus({ title: "No", status: false });
                    handleClose2();
                  }}
                >
                  {t("no")}
                </MenuItem>
              </Box>
            </Menu>
          </Box>
          {invalidMonitoringOption && (
            <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
              {errorInvalidMonitoringTitle}
            </FormHelperText>
          )}
        </Box>
        {monitoringStatus.title === "Yes" && (
          <Box sx={CampaigngForm}>
            <Typography variant="h4" mt={3} align="center" mb={3}>
              {t("monitoringDetails")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormLabel component="legend">Project Output</FormLabel>
                <TextField
                  value={YearlyOutput}
                  ref={YearlyOutputRef}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  sx={{ marginBottom: 3 }}
                  hiddenLabel
                  type="number"
                  placeholder={t("yearlyOutput") || ""}
                  onChange={(e) => {
                    handleYearlyOutput(e.target.value);
                  }}
                  error={invalidYearlyOutput}
                  helperText={invalidYearlyOutput && errorYearlyOutput}
                />
                {/* <Box sx={{ marginBottom: 3 }}>
                  <FormLabel component="legend">Location</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={locOption}
                    onChange={(e) => setLocOption(e.target.value)}

                  >
                    <FormControlLabel
                      value="location"
                      control={<Radio />}
                      label={t("searchLocation")}
                    />
                    <FormControlLabel
                      value="coordinates"
                      control={<Radio />}
                      label={t("enterLocation")}
                    />
                    {locOption === "location" ?
                      (<PlacesAutocomplete
                        value={location}
                        onChange={handleLocation}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            {/* <p>lat : {coordinates.lat}</p>
                        <p>lat : {coordinates.lng}</p> */}

                {/* <TextField
                              {...getInputProps({
                                placeholder: "Search Places ...",
                                className: "location-search-input",
                              })}
                              error={invalidLocation}
                              helperText={invalidLocation && errorLocation}
                              ref={locationRef}
                              sx={{ marginTop: 2 }}
                              required
                              fullWidth
                              hiddenLabel
                              type={"text"}
                              variant="outlined"
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                  : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      )
                      : (
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <TextField
                              value={coordinates.lat}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              sx={{ marginBottom: 1 }}
                              hiddenLabel
                              type="number"
                              ref={coordinatesRef}
                              placeholder={"Latitude" || ""}
                              onChange={(e: any) => {
                                setCoordinates({
                                  ...coordinates,
                                  lat: e.target.value,
                                });
                              }}
                              error={invalidlatitude}
                              helperText={invalidlatitude && t("pleaseEnterLatitude")}
                            />
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <TextField
                              value={coordinates.lng}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              ref={coordinatesRef}
                              sx={{ marginBottom: 3 }}
                              hiddenLabel
                              type="number"
                              placeholder={"Longitude" || ""}
                              onChange={(e: any) => {
                                setCoordinates({
                                  ...coordinates,
                                  lng: e.target.value,
                                });
                              }}
                              error={invalidlongitude}
                              helperText={
                                invalidlongitude && t("pleaseEnterLongitude")
                              }
                            />
                          </Grid>
                        </Grid>
                      )}
                  </RadioGroup>
                </Box> */}

                <Box sx={{ marginBottom: 3 }}>
                  <FormLabel component="legend">Location</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={locOption}
                    onChange={(e) => setLocOption(e.target.value)}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        value="location"
                        control={<Radio />}
                        label={t("searchLocation")}
                        style={{ marginRight: "2rem" }} // Adjust the spacing between text and radio button
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        value="coordinates"
                        control={<Radio />}
                        label={t("enterLocation")}
                        style={{ marginRight: "2rem" }} // Adjust the spacing between text and radio button
                      />
                    </div>
                  </RadioGroup>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {locOption === "location" ? (
                      <PlacesAutocomplete
                        value={location}
                        onChange={handleLocation}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div style={{ width: "100%" }}>
                            {/* <p>lat : {coordinates.lat}</p>
      <p>lat : {coordinates.lng}</p>  */}

                            <TextField
                              {...getInputProps({
                                placeholder: "Search Places ...",
                                className: "location-search-input",
                              })}
                              error={invalidLocation}
                              helperText={invalidLocation && errorLocation}
                              ref={locationRef}
                              sx={{ marginTop: 2 }}
                              required
                              fullWidth
                              hiddenLabel
                              type={"text"}
                              variant="outlined"
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    ) : locOption === "coordinates" ? (
                      <Grid container spacing={2}>
                        {/* ... (rest of your code for the coordinates input fields) */}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <TextField
                            // value={coordinates.lat}
                            value={coordinates.lat}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            sx={{ marginBottom: 1 }}
                            hiddenLabel
                            type="number"
                            ref={coordinatesRef}
                            placeholder={"Latitude" || ""}
                            onChange={(e: any) => {
                              setCoordinates({
                                ...coordinates,
                                lat: e.target.value,
                              });
                            }}
                            error={invalidlatitude}
                            helperText={
                              invalidlatitude && t("pleaseEnterLatitude")
                            }
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <TextField
                            // value={coordinates.lng}
                            value={coordinates.lng}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            ref={coordinatesRef}
                            sx={{ marginBottom: 3 }}
                            hiddenLabel
                            type="number"
                            placeholder={"Longitude" || ""}
                            onChange={(e: any) => {
                              setCoordinates({
                                ...coordinates,
                                lng: e.target.value,
                              });
                            }}
                            error={invalidlongitude}
                            helperText={
                              invalidlongitude && t("pleaseEnterLongitude")
                            }
                          />
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                </Box>

                {/* <FormLabel component="legend">Cost Per Unit</FormLabel>
                <TextField
                  value={CostPerUnit}
                  ref={CostPerUnitRef}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  sx={{ marginBottom: 3 }}
                  hiddenLabel
                  type="number"
                  placeholder={t("costPerUnit($)") || ""}
                  onChange={(e) => {
                    handleCostPerUnit(e.target.value);
                  }}
                  error={invalidCostPerUnit}
                  helperText={invalidCostPerUnit && errorCostPerUnit}
                /> */}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormLabel component="legend">Cost Per Unit</FormLabel>
                <TextField
                  value={CostPerUnit}
                  ref={CostPerUnitRef}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  sx={{ marginBottom: 3 }}
                  hiddenLabel
                  type="number"
                  placeholder={t("costPerUnit($)") || ""}
                  onChange={(e) => {
                    handleCostPerUnit(e.target.value);
                  }}
                  error={invalidCostPerUnit}
                  helperText={invalidCostPerUnit && errorCostPerUnit}
                />
                {/* <Box sx={{ marginBottom: 3 }}>
                  <FormLabel component="legend">Expected Results</FormLabel>
                  <TextField
                    ref={resultsRef}
                    value={Results}
                    multiline
                    type={"text"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    sx={{ marginBottom: 3 }}
                    hiddenLabel
                    placeholder={t("expectedResults") || ""}
                    onChange={(e) => {
                      handleResults(e.target.value);
                    }}
                    error={invalidResults}
                    helperText={invalidResults && errorResults}
                  /> */}
                {/* <Box sx={{ marginBottom: 3 }}>
                  <FormLabel component="legend">Location</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={locOption}
                    onChange={(e) => setLocOption(e.target.value)}
                  >
                    <FormControlLabel
                      value="location"
                      control={<Radio />}
                      label={t("searchLocation")}
                    />
                    <FormControlLabel
                      value="coordinates"
                      control={<Radio />}
                      label={t("enterLocation")}
                    />
                    {locOption === "location" ?
                      (<PlacesAutocomplete
                        value={location}
                        onChange={handleLocation}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            {/* <p>lat : {coordinates.lat}</p>
                        <p>lat : {coordinates.lng}</p> */}

                {/* <TextField
                              {...getInputProps({
                                placeholder: "Search Places ...",
                                className: "location-search-input",
                              })}
                              error={invalidLocation}
                              helperText={invalidLocation && errorLocation}
                              ref={locationRef}
                              sx={{ marginTop: 2 }}
                              required
                              fullWidth
                              hiddenLabel
                              type={"text"}
                              variant="outlined"
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                  : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      )
                      : (
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <TextField
                              value={coordinates.lat}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              sx={{ marginBottom: 1 }}
                              hiddenLabel
                              type="number"
                              ref={coordinatesRef}
                              placeholder={"Latitude" || ""}
                              onChange={(e: any) => {
                                setCoordinates({
                                  ...coordinates,
                                  lat: e.target.value,
                                });
                              }}
                              error={invalidlatitude}
                              helperText={invalidlatitude && t("pleaseEnterLatitude")}
                            />
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <TextField
                              value={coordinates.lng}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              ref={coordinatesRef}
                              sx={{ marginBottom: 3 }}
                              hiddenLabel
                              type="number"
                              placeholder={"Longitude" || ""}
                              onChange={(e: any) => {
                                setCoordinates({
                                  ...coordinates,
                                  lng: e.target.value,
                                });
                              }}
                              error={invalidlongitude}
                              helperText={
                                invalidlongitude && t("pleaseEnterLongitude")
                              }
                            />
                          </Grid>
                        </Grid>
                      )}
                  </RadioGroup>
                </Box>
 */}

                <Box sx={{ marginBottom: 3 }}>
                  <FormLabel component="legend">Expected Results</FormLabel>
                  <TextField
                    ref={resultsRef}
                    value={Results}
                    minRows={3}
                    multiline
                    type={"text"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    sx={{ marginBottom: 3 }}
                    hiddenLabel
                    placeholder={t("expectedResults") || ""}
                    onChange={(e) => {
                      handleResults(e.target.value);
                    }}
                    error={invalidResults}
                    helperText={invalidResults && errorResults}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: "50px",
            marginBottom: "60px",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
            {t("isYourCampaignAlignedWithAnyGoal")}
          </Typography>
          <Box sx={{ marginTop: "20px" }}>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ color: "#000", border: "1px solid gray" }}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {ESG_SDG_status.title !== ""
                ? ESG_SDG_status.title
                : t("pleaseSelectOption")}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Box sx={{ width: "200px" }}>
                <MenuItem
                  onClick={() => {
                    handleESG_SDG_status({ title: "Yes", status: true });
                    handleClose();
                  }}
                >
                  {t("yes")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleESG_SDG_status({ title: "No", status: false });
                    handleClose();
                  }}
                >
                  {t("no")}
                </MenuItem>
              </Box>
            </Menu>
          </Box>
          {invalidEsgOption && (
            <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
              {errorInvalidEsgTitle}
            </FormHelperText>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "60px",
            marginTop: 3,
          }}
        >
          <Button
            variant="contained"
            color="error"
            className="round-button"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t("back")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="round-button"
            onClick={handlePreview}
          >
            {t("preview")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            className="round-button"
            onClick={() => {
              const valid = validateForm();
              const validMilestone = validateMilestoneData();
              if (!valid || !validMilestone) {
                return;
              } else if (ESG_SDG_status.title === "") {
                setInvalidEsgOption(true);
                setErrorInvalidEsgTitle("Please select an Option");
              } else {
                setErrorInvalidEsgTitle("");
                handleSaveToDraft(false, false);
              }
            }}
          >
            {t("saveDraft")}
          </Button>
          {kyc === "approved" && ESG_SDG_status.title === "No" && (
            <>
              <Button
                variant="contained"
                color="primary"
                className="round-button"
                onClick={() => setShowWalletButtons(true)}
              >
                {t("submit")}
              </Button>

              {showWalletButtons && (
                <Wallets
                  open={showWalletButtons}
                  handleClose={() => setShowWalletButtons(false)}
                  handleSubmit={handleSubmit}
                />
              )}
            </>
          )}
          {ESG_SDG_status.title === "Yes" && (
            <Button
              variant="contained"
              color="primary"
              className="round-button"
              onClick={() => {
                const valid = validateForm();
                const validMilestone = validateMilestoneData();

                if (!valid || !validMilestone) {
                  return;
                } else if (ESG_SDG_status.title === "") {
                  setInvalidEsgOption(true);
                  setErrorInvalidEsgTitle("Please select an Option");
                } else {
                  setErrorInvalidEsgTitle("");
                  handleSaveToDraft(true, false);
                }
              }}
            >
              {t("next")}
            </Button>
          )}
        </Box>
        <NotificationToast />
        <Modal open={previewCampaign}>
          <Box
            sx={{
              backgroundColor: "#fff",
              width: "80%",
              height: "98vh",
              marginX: "auto",
            }}
          >
            <Preview />
          </Box>
        </Modal>
      </Box>
    </>
  );
}

export default CreateCampaign;
