import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  TextField,
  Select,
  MenuItem,
  FormLabel,
  SelectChangeEvent,
  FormHelperText,
  Typography,
  IconButton,
  Menu,
} from "@mui/material";
import { CampaigngForm } from "../createCampaign/styles";
import UploadImage from "../createCampaign/UploadImage";
import moment from "moment";
import countryList from "../createCampaign/countrylist";
import UploadVideo from "../createCampaign/UploadVideo";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  campaignState,
  setCampaignPreview,
  setPreview,
  setRewardType,
} from "../../../store/slices/CreateCampaignSlice";
import { InputFiles } from "typescript";
import {
  createCampaign,
  deleteCampaign,
  getEditCampaignDetails,
  saveToDraft,
  updateDraft,
  uploadCampaignImage,
  uploadCampaignVideo,
} from "../../../requests/authRequest";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import {
  getCampaignCategories,
  getFundingCategories,
} from "../../../requests/simpleRequest";
import { useNavigate, useParams } from "react-router-dom";
import {
  setDeleteCampaignModal,
  setSelectRewardTypeModal,
} from "../../../store/slices/ModalSlice";
import {
  STAGING_FUNDING_CATEGORY,
  TEST_FUNDING_CATEGORY,
  PRODUCTION_FUNDING_CATEGORY,
} from "../createCampaign/constant";
import MyAlgoConnect from "@randlabs/myalgo-connect";
// import { client, walletClient } from "../../../wallets/algorand";
import algosdk from "algosdk";
import MilestoneSet from "../../home/campaigns/MilestoneSet";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import algo from "../../../assets/images/algo.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Wallets from "../../../wallets";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

interface milestone {
  title: string;
  defination: string;
  deadline: Date | string;
  delivereble: any;
}

function EditDraftCampaign({
  handleSteps,
  currentCampaignId,
  handleCurrentCampaignId,
  title,
  handleTitle,
  description,
  handleDescription,
  category,
  handleCategory,
  fundingCategory,
  handleFundingCategory,
  endDate,
  handleEndDate,
  country,
  handleCountry,
  goalAmount,
  handleGoalAmount,
  youTubeLink,
  handleYoutubelink,
  image,
  handleImage,
  video,
  handleVideo,
  videoSelected,
  handleVideoSelected,
  milestoneOne,
  handleMilestoneOne,
  milestoneTwo,
  handleMilestoneTwo,
  ESG_SDG_status,
  handleESG_SDG_status,
  handleCampaignCreate,
  monitoringStatus,
  handleMonitoringStatus,
  YearlyOutput,
  handleYearlyOutput,
  CostPerUnit,
  handleCostPerUnit,
  Results,
  handleResults,
}: // location,
// handleLocation,
// coordinates,
// setCoordinates,
any) {
  const { t } = useTranslation();
  const { campaignId } = useParams();
  document
    .getElementById("campaignEndingDate")
    ?.setAttribute("disabled", "disabled");
  const campaignState = useAppSelector((state) => state.campaignPreviewReducer);
  const kyc = useAppSelector((state) => state.userReducer.kyc);
  const [campaignInitialData, setCampaignInitialData] = useState<any>({});

  const [invalidEsgOption, setInvalidEsgOption] = useState<boolean>(false);
  const [errorInvalidEsgTitle, setErrorInvalidEsgTitle] = useState<string>("");
  const [imageName, setImageName] = useState<any | undefined>(
    campaignState.imageNames
  );

  const [imageSelected, setImageSelected] = useState<boolean>(
    campaignState.imageSelected
  );
  const [invalidMonitoringOption, setInvalidMonitoringOption] =
    useState<boolean>(false);
  const [errorInvalidMonitoringTitle, setErrorInvalidMonitoringTitle] =
    useState<string>("");
  const [invalidYearlyOutput, setinvalidYearlyOutput] =
    useState<boolean>(false);
  const [errorYearlyOutput, setErrorYearlyOutput] = useState<string>("");
  const [invalidCostPerUnit, setinvalidCostPerUnit] = useState<boolean>(false);
  const [errorCostPerUnit, setErrorCostPerUnit] = useState<string>("");
  const [invalidResults, setinvalidResults] = useState<boolean>(false);
  const [errorResults, setErrorResults] = useState<string>("");
  const [invalidLocation, setInvalidLocation] = useState<boolean>(false);
  const [errorLocation, setErrorLocation] = useState<string>("");
  const imageRef = useRef<HTMLElement>();
  const [selectedImageId, setSelectedImageId] = useState<number>(0);
  // const [locOption, setLocOption] = React.useState("location");
  const [locOption, setLocOption] = React.useState(
    campaignInitialData.location === null ? "coordinates" : "location"
  );
  const [videoName, setVideoName] = useState<string>(campaignState.videoUrl);
  const videoRef = useRef<HTMLVideoElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  //this is for categories
  const [campCategoryList, setCampCategoryList] = useState([]);
  const [fundCategoryList, setFundCategoryList] = useState([]);

  // this is for rewards

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const preview = useAppSelector(
    (state) => state.campaignPreviewReducer.previewEnabled
  );

  // this is for nft as reward
  const selectedNft = useAppSelector(
    (state) => state.campaignPreviewReducer.rewardNftId
  );

  const rewardType = useAppSelector(
    (state) => state.campaignPreviewReducer.rewardType
  );

  const handleCategoryChange = (e: SelectChangeEvent) => {
    handleCategory(e.target.value);
  };

  const handleFundingCategoryChange = (e: SelectChangeEvent) => {
    handleFundingCategory(e.target.value);
  };

  const handleCountryChange = (e: SelectChangeEvent) => {
    handleCountry(e.target.value);
  };

  const handleChange = (newValue: Date | string | null) => {
    if (newValue !== null) {
      handleEndDate(new Date(newValue));
    }
  };
  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: campaignState.lat,
    lng: campaignState.lng,
  });
  // these are for form validations
  const [invalidTitle, setInvalidTitle] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>("");

  const [invalidDescription, setInvalidDescription] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<string>("");
  const [invalidlatitude, setInvalidlatitude] = useState<boolean>(false);

  const [invalidlongitude, setInvalidlongitude] = useState<boolean>(false);
  const [invalidEndDate, setInvalidEndDate] = useState<boolean>(false);
  const [errorEndDate, setErrorEndDate] = useState<string>("");

  const [invalidGoalAmount, setInvalidGoalAmount] = useState<boolean>(false);
  const [errorGoalAmount, setErrorGoalAmount] = useState<string>("");

  const [invalidCategory, setInvalidCategory] = useState<boolean>(false);
  const [invalidFundingCategory, setInvalidFundingCategory] =
    useState<boolean>(false);
  const [invalidCountry, setInvalidCountry] = useState<boolean>(false);

  const [invalidNft, setInvalidNft] = useState<boolean>(false);
  const [imageErrorMsg, setImageErrorMsg] = useState<string>("");

  let blockchainCampaignAppId = "";
  let milestoneOneAppId = "";
  let milestoneTwoAppId = "";
  // let milestoneThreeAppId = "";

  // *******************************
  // here i have the logic for the milestone start
  // *******************************

  const [localMilestoneData, setLocalMilestoneData] = useState(
    localStorage.getItem("milestones")
  );
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const [invalidDateOne, setInvalidDateOne] = useState<boolean>(false);
  const [invalidDateTwo, setInvalidDateTwo] = useState<boolean>(false);
  const [invalidTitleOne, setInvalidTitleOne] = useState<boolean>(false);
  const [invalidTitleTwo, setInvalidTitleTwo] = useState<boolean>(false);
  const [invalidDefinitionOne, setInvalidDefinitionOne] =
    useState<boolean>(false);
  const [invalidDefinitionTwo, setInvalidDefinitionTwo] =
    useState<boolean>(false);
  const [location, setLocation] = useState<any>(campaignState.location);
  // const [coordinates, setCoordinates] = useState<{
  //   lat: null | number;
  //   lng: null | number;
  // }>({
  //   lat: null,
  //   lng: null,
  // });
  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    console.log(ll);

    if (campaignInitialData.location === null) {
      setCoordinates({ lat: 0, lng: 0 });
    } else {
      setLocation(value);
      setCoordinates(ll);
    }
    console.log(coordinates.lat);
    console.log(coordinates.lng);
  };

  /**
   * create campaign refs
   */

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const endDateRef = useRef(null);
  const goalAmountRef = useRef(null);
  const categoryRef = useRef(null);
  const countryRef = useRef(null);
  const resultsRef = useRef(null);
  const YearlyOutputRef = useRef(null);
  const CostPerUnitRef = useRef(null);
  const locationRef = useRef(null);
  const fundingCategoryRef = useRef(null);
  const coordinatesRef = useRef(null);
  /**
   * create campaign refs end
   */

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();
    const valid = validateForm();
    const validMilestone = validateMilestoneData();

    if (valid && validMilestone) {
      setShowWalletButtons(true);
    }
  };

  const validateMilestoneData = () => {
    let validity = true;

    // for milestone one
    if (!moment(milestoneOne.deadline).isAfter(endDate, "day")) {
      validity = false;
      setInvalidDateOne(true);
    } else {
      setInvalidDateOne(false);
    }

    if (milestoneOne.title.trim().length <= 0) {
      validity = false;
      setInvalidTitleOne(true);
    } else if (milestoneOne.title.length > 100) {
      validity = false;
      setInvalidTitleOne(true);
    } else {
      setInvalidTitleOne(false);
    }

    if (milestoneOne.defination.trim().length <= 0) {
      validity = false;
      setInvalidDefinitionOne(true);
    } else if (milestoneOne.defination.trim().length > 3000) {
      validity = false;
      setInvalidDefinitionOne(true);
    } else {
      setInvalidDefinitionOne(false);
    }

    // for milestone Two
    if (!moment(milestoneTwo.deadline).isAfter(milestoneOne.deadline, "day")) {
      validity = false;
      setInvalidDateTwo(true);
    } else {
      setInvalidDateTwo(false);
    }

    if (milestoneTwo.title.trim().length <= 0) {
      validity = false;
      setInvalidTitleTwo(true);
    } else if (milestoneTwo.title.length > 100) {
      validity = false;
      setInvalidTitleTwo(true);
    } else {
      setInvalidTitleTwo(false);
    }

    if (milestoneTwo.defination.trim().length <= 0) {
      validity = false;
      setInvalidDefinitionTwo(true);
    } else if (milestoneTwo.defination.trim().length > 3000) {
      validity = false;
      setInvalidDefinitionTwo(true);
    } else {
      setInvalidDefinitionTwo(false);
    }

    // // for milestone three
    // if (
    // 	!moment(milestoneThree.deadline).isAfter(milestoneTwo.deadline, "day")
    // ) {
    // 	validity = false;
    // 	setInvalidDateThree(true);
    // } else {
    // 	setInvalidDateThree(false);
    // }

    // if (milestoneThree.title.trim().length <= 0) {
    // 	validity = false;
    // } else if (milestoneThree.title.length > 100) {
    // 	validity = false;
    // }

    // if (milestoneThree.defination.trim().length <= 0) {
    // 	validity = false;
    // } else if (milestoneThree.defination.trim().length > 3000) {
    // 	validity = false;
    // }

    return validity;
  };

  // *******************************
  // here i have the logic for the milestone end
  // *******************************

  const validateForm = () => {
    let validity = true;
    // for title
    if (title.trim().length <= 0) {
      validity = false;
      setInvalidTitle(true);
      setErrorTitle(t("pleaseEnterTheTitle") || "");
    } else if (title.length > 100) {
      validity = false;
      setInvalidTitle(true);
      setErrorTitle(t("titleMustBeHundredCharOrSmaller") || "");
    } else {
      setInvalidTitle(false);
      setErrorTitle("");
    }
    // if (monitoringStatus.title === "Yes") {
    //   if (!Results?.trim().length) {
    //     validity = false;
    //     setinvalidResults(true);
    //     setErrorResults(t("pleaseEnterTheResults") || "");
    //   } else {
    //     setinvalidResults(false);
    //     setErrorResults("");
    //   }
    //   //yearly output
    //   if (YearlyOutput <= 0) {
    //     validity = false;
    //     setinvalidYearlyOutput(true);
    //     setErrorYearlyOutput(t("pleaseEnterYearlyOutput") || "");
    //   } else {
    //     setinvalidYearlyOutput(false);
    //     setErrorYearlyOutput("");
    //   }
    //   //CostPerUnit
    //   if (CostPerUnit <= 0) {
    //     validity = false;
    //     setinvalidCostPerUnit(true);
    //     setErrorCostPerUnit(t("pleaseEnterCostPerUnit") || "");
    //   } else {
    //     setinvalidCostPerUnit(false);
    //     setErrorCostPerUnit("");
    //   }

    //   // location

    //   if (!location?.trim().length) {
    //     validity = false;
    //     setInvalidLocation(true);
    //     setErrorLocation(t("pleaseEnterLocation") || "");
    //   } else {
    //     setInvalidLocation(false);
    //     setErrorLocation("");
    //   }
    // }

    if (monitoringStatus.title === "Yes") {
      validity = true;
      setInvalidMonitoringOption(false);
      setErrorInvalidMonitoringTitle("");

      if (!Results?.trim().length) {
        validity = false;
        setinvalidResults(true);
        setErrorResults(t("pleaseEnterTheResults") || "");
      } else {
        setinvalidResults(false);
        setErrorResults("");
      }
      //yearly output
      if (isNaN(parseInt(YearlyOutput)) || parseInt(YearlyOutput) <= 0) {
        validity = false;
        setinvalidYearlyOutput(true);
        setErrorYearlyOutput(t("pleaseEnterYearlyOutput") || "");
      } else {
        setinvalidYearlyOutput(false);
        setErrorYearlyOutput("");
      }
      //CostPerUnit
      if (isNaN(parseInt(CostPerUnit)) || parseInt(CostPerUnit) <= 0) {
        validity = false;
        setinvalidCostPerUnit(true);
        setErrorCostPerUnit(t("pleaseEnterCostPerUnit") || "");
      } else {
        setinvalidCostPerUnit(false);
        setErrorCostPerUnit("");
      }

      // location

      if (locOption === "location" && !location?.trim().length) {
        validity = false;
        setInvalidLocation(true);
        setErrorLocation(t("pleaseEnterLocation") || "");
      } else {
        setInvalidLocation(false);
        setErrorLocation("");
      }

      if (locOption === "coordinates" && !coordinates.lat) {
        validity = false;
        setInvalidlatitude(true);
      } else {
        setInvalidlatitude(false);
      }

      if (locOption === "coordinates" && !coordinates.lng) {
        validity = false;
        setInvalidlongitude(true);
      } else {
        setInvalidlongitude(false);
      }
    } else {
      setInvalidMonitoringOption(true);
      setErrorInvalidMonitoringTitle("Please Select any Option");
    }

    // for description
    // const MAX_WORD_LIMIT = 500;
    // const wordCount = description.trim().split(/\s+/).length;
    if (description.trim().length <= 0) {
      validity = false;
      setInvalidDescription(true);
      setErrorDescription(t("pleaseEnterTheDescription") || "");
    } else if (description.trim().length > 30000) {
      validity = false;
      setInvalidDescription(true);
      setErrorDescription("Length Exceeds Limit" || "");
    } else {
      setInvalidDescription(false);
      setErrorDescription("");
    }

    // for end Date
    if (
      moment(new Date(endDate)).startOf("day").unix() <=
      moment().startOf("day").unix()
    ) {
      validity = false;
      setInvalidEndDate(true);
      setErrorEndDate(t("pleaseSelectValidFutureDate") || "");
    } else {
      setInvalidEndDate(false);
      setErrorEndDate("");
    }

    // for goal amount
    if (
      isNaN(parseInt(goalAmount)) ||
      parseInt(goalAmount) < 5 ||
      parseInt(goalAmount) > 1000000
    ) {
      validity = false;
      setInvalidGoalAmount(true);
      setErrorGoalAmount(t("pleaseEnterAmountBetweenFiveAndTenLakh") || "");
    } else {
      setInvalidGoalAmount(false);
      setErrorGoalAmount("");
    }

    // for dropdowns
    if (category.length === 0) {
      validity = false;
      setInvalidCategory(true);
    } else {
      setInvalidCategory(false);
    }

    if (country.length === 0) {
      validity = false;
      setInvalidCountry(true);
    } else {
      setInvalidCountry(false);
    }

    if (fundingCategory.length === 0) {
      validity = false;
      setInvalidFundingCategory(true);
    } else {
      setInvalidFundingCategory(false);
    }
    setImageErrorMsg("");
    if (!imageName?.length) {
      validity = false;
      setImageErrorMsg(t("pleaseSelectImageToUpload") || "");
    }

    // this is for nft as a reward
    if (
      fundingCategory === TEST_FUNDING_CATEGORY ||
      fundingCategory === STAGING_FUNDING_CATEGORY ||
      fundingCategory === PRODUCTION_FUNDING_CATEGORY
    ) {
      if (!selectedNft || selectedNft?.length <= 0) {
        setInvalidNft(true);
        validity = false;
      } else {
        setInvalidNft(false);
      }
    }

    return validity;
  };

  const handleSubmit = () => {
    const valid = validateForm();
    const validMilestone = validateMilestoneData();

    if (!valid || !validMilestone) {
      return;
    } else {
      setErrorInvalidEsgTitle("");
      handleSaveToDraft(false, true);
    }
  };

  // const handlePreview = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault();

  //   const payload: campaignState = {
  //     title: title,
  //     description: description,
  //     campaignCategory: category,
  //     fundingCategory: fundingCategory,
  //     endDate: endDate,
  //     country: country,
  //     goalAmount: goalAmount,
  //     socialVideoUrl: "",
  //     videoUrl: videoName,
  //     imageUrl: imageName,
  //     previewEnabled: preview,
  //     video: video,
  //     image: image,
  //     imageNames: imageName,
  //     imageSelected: imageSelected,
  //     videoSelected: videoSelected,
  //   };
  //   dispatch(setCampaignPreview(payload));
  //   dispatch(setPreview(true));
  //   const milestoneInfo = {
  //     one: {
  //       title: milestoneOne.title,
  //       definition: milestoneOne.defination,
  //       deadline: milestoneOne.deadline,
  //       deliverable: "non",
  //       milestoneAppId: milestoneOneAppId,
  //     },
  //     two: {
  //       title: milestoneTwo.title,
  //       definition: milestoneTwo.defination,
  //       deadline: milestoneTwo.deadline,
  //       deliverable: "non",
  //       milestoneAppId: milestoneTwoAppId,
  //     },
  //   };
  //   localStorage.setItem("milestones", JSON.stringify(milestoneInfo));
  // };

  const handleImageUpload = async () => {
    if (imageSelected) {
      const res: any = await uploadCampaignImage(image);
      if (res.success) {
        return res?.data;
      } else {
        return false;
      }
    } else {
      return image;
    }
  };

  const handleVideoUpload = async () => {
    if (videoSelected && typeof video === "object") {
      const res = await uploadCampaignVideo(video);
      if (res.success) {
        return res.data.fileId;
      } else {
        return false;
      }
    }
  };

  const fetchCategories = async () => {
    const res1 = await getFundingCategories();
    if (res1.success) {
      setFundCategoryList(res1.data);
    } else {
      const toastPaylaod = {
        text: res1.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
    const res2 = await getCampaignCategories();
    if (res2.success) {
      setCampCategoryList(res2.data);
    } else {
      const toastPaylaod = {
        text: res2.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
  };

  const cleanFormState = () => {
    // const payload: campaignState = {
    //   title: "",
    //   description: "",
    //   campaignCategory: "",
    //   fundingCategory: "",
    //   endDate: "",
    //   country: "",
    //   goalAmount: "",
    //   socialVideoUrl: "",
    //   videoUrl: "",
    //   imageUrl: "",
    //   previewEnabled: false,
    //   video: undefined,
    //   image: undefined,
    //   imageNames: undefined,
    //   videoSelected: false,
    //   imageSelected: false,
    // };
    localStorage.removeItem("milestones");
    handleTitle("");
    handleDescription("");
    handleCategory("");
    handleFundingCategory("");
    handleEndDate("");
    handleCountry("");
    handleGoalAmount("");
    clearvideoData();
    handleResults("");
    handleYearlyOutput("");
    handleCostPerUnit("");
    setLocation("");
    clearImageData();
    const nftData = {
      rewardNftId: "",
      rewardType: "",
    };
    dispatch(setRewardType(nftData));

    // dispatch(setCampaignPreview(payload));
  };

  const clearvideoData = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    handleVideo(undefined);
    setVideoName("");
    handleVideoSelected(false);
    if (videoRef && videoRef.current) {
      videoRef.current.src = "none";
    }
  };

  const clearImageData = () => {
    handleImage(undefined);
    setImageName("");
    setImageSelected(false);
    if (imageRef && imageRef.current) {
      imageRef.current.style.backgroundImage = "none";
    }
  };

  const handleRewardModal = () => {
    dispatch(setSelectRewardTypeModal(true));
  };

  const fetchInitialValues = async () => {
    dispatch(toggleAppLoading(true));
    const res = await getEditCampaignDetails(campaignId);
    dispatch(toggleAppLoading(false));
    if (res.success) {
      setCampaignInitialData(res.data.campaign);
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      navigate(-1);
    }
  };

  const handleDelete = async () => {
    dispatch(toggleAppLoading(true));

    if (campaignId) {
      const data = {
        campaignId: campaignId,
      };
      const res = await deleteCampaign(data);
      if (res.success) {
        const toastPayload = {
          active: true,
          text: t("campaignDeletedSuccessfully"),
          success: true,
        };

        dispatch(setToaster(toastPayload));
      } else {
        const toastPayload = {
          active: true,
          text: res.data.message,
          success: false,
        };

        dispatch(setToaster(toastPayload));
      }
    } else {
      const toastPayload = {
        active: true,
        text: t("selectedCampaignDoNotxist"),
        success: false,
      };

      dispatch(setToaster(toastPayload));
    }
    dispatch(toggleAppLoading(false));
    navigate("/user/draft-campaign");
    // handleClose();
  };

  /**
   * handle save to draft function
   */

  const handleSaveToDraft = async (
    nextStatus: boolean,
    submitStatus: boolean
  ) => {
    const valid = validateForm();
    const validMilestone = validateMilestoneData();

    if (!valid || !validMilestone) {
      return;
    }

    dispatch(toggleAppLoading(true));
    dispatch(setAppLoadingText(t("savingToDraft")));

    // dispatch(setAppLoadingText("Uploading Image"));
    const images = await handleImageUpload();

    // dispatch(setAppLoadingText("Uploading Video"));
    const video = await handleVideoUpload();

    let payload: any = {};

    if (
      fundingCategory === TEST_FUNDING_CATEGORY ||
      fundingCategory === STAGING_FUNDING_CATEGORY ||
      fundingCategory === PRODUCTION_FUNDING_CATEGORY
    ) {
      payload = {
        campaignId: campaignId,
        campaign: {
          title: title,
          description: description,
          expectedResults: Results,
          expectedYearlyoutput: YearlyOutput,
          expectedCostPerUnit: CostPerUnit,
          isReforestationProject: monitoringStatus.status,
          location: location,
          lat: coordinates.lat,
          lng: coordinates.lng,
          youTubeLink: youTubeLink,
          category: category,
          fundingCategory: fundingCategory,
          endDate: moment(endDate).format("YYYY-MM-DD"),
          goalAmount: goalAmount,
          country: country,
          images: images,
          video: !video ? "" : video,
          videoUrl: "",
          milestones: [
            {
              milestoneId: campaignInitialData?.milestones[0]?.milestoneId,
              title: milestoneOne.title ? milestoneOne.title : "",
              definition: milestoneOne.defination
                ? milestoneOne.defination
                : "",
              deadline: milestoneOne.deadline ? milestoneOne.deadline : "",
              deliverable: "non",
              milestoneAppId:
                campaignInitialData?.milestones[0]?.milestoneAppId,
            },
            {
              milestoneId: campaignInitialData?.milestones[1]?.milestoneId,
              title: milestoneTwo.title ? milestoneTwo.title : "",
              definition: milestoneTwo.defination
                ? milestoneTwo.defination
                : "",
              deadline: milestoneTwo.deadline ? milestoneTwo.deadline : "",
              deliverable: "non",
              milestoneAppId:
                campaignInitialData?.milestones[1]?.milestoneAppId,
            },
          ],
          matchFunding: campaignInitialData?.matchFunding,
          goalIds: campaignInitialData?.goalIds?.map((a: any) => a.goalId),
          rewardBased: true,
          reward: selectedNft,
          rewardType: rewardType,
        },
        isEsgEnabled: ESG_SDG_status,
        // isReforestationProject:
        //   campaignInitialData.isReforestationProject !== undefined
        //     ? {
        //         title: campaignInitialData.isReforestationProject
        //           ? "Yes"
        //           : "No",
        //         status: campaignInitialData.is,
        //       }
        //     : { title: "", status: false },

        additionalInfo: campaignInitialData?.additionalInfo,
        additionalInfoData: {
          additionalInfoId:
            campaignInitialData?.additionalInfoId?.additionalInfoId,
          consent: campaignInitialData?.additionalInfoId?.consent,
          contactName: campaignInitialData?.additionalInfoId?.contactName,
          contactEmail: campaignInitialData?.additionalInfoId?.contactEmail,
          contactPhone: campaignInitialData?.additionalInfoId?.contactPhone,
          companyOrProjectName:
            campaignInitialData?.additionalInfoId?.companyOrProjectName,
          website: campaignInitialData?.additionalInfoId?.website,
          projectPitch: campaignInitialData?.additionalInfoId?.projectPitch,
          problems: campaignInitialData?.additionalInfoId?.problems,
          previousSuccessfulProjects:
            campaignInitialData?.additionalInfoId?.previousSuccessfulProjects,
          hq: campaignInitialData?.additionalInfoId?.hq,
          impact: campaignInitialData?.additionalInfoId?.impact,
          partnerships: campaignInitialData?.additionalInfoId?.partnerships,
          yearOfEstablishment:
            campaignInitialData?.additionalInfoId?.yearOfEstablishment,
          image: campaignInitialData?.additionalInfoId?.image,
          companyBio: campaignInitialData?.additionalInfoId?.companyBio,
          reachoutRegardingSubmission:
            campaignInitialData?.additionalInfoId?.reachoutRegardingSubmission,
          geographies: campaignInitialData?.additionalInfoId?.geographies,
          additionalInformation:
            campaignInitialData?.additionalInfoId?.additionalInformation,
        },
      };
    } else {
      payload = {
        campaignId: campaignId,
        campaign: {
          title: title,
          description: description,
          category: category,
          expectedResults: Results,
          expectedYearlyoutput: YearlyOutput,
          location: location,
          lat: coordinates.lat,
          lng: coordinates.lng,
          youTubeLink: youTubeLink,
          expectedCostPerUnit: CostPerUnit,
          isReforestationProject: monitoringStatus.status,
          fundingCategory: fundingCategory,
          endDate: moment(endDate).format("YYYY-MM-DD"),
          goalAmount: goalAmount,
          country: country,
          images: images,
          video: !video ? "" : video,
          videoUrl: "",
          milestones: [
            {
              milestoneId: campaignInitialData?.milestones[0]?.milestoneId,
              title: milestoneOne.title ? milestoneOne.title : "",
              definition: milestoneOne.defination
                ? milestoneOne.defination
                : "",
              deadline: milestoneOne.deadline ? milestoneOne.deadline : "",
              deliverable: "non",
              milestoneAppId:
                campaignInitialData?.milestones[0]?.milestoneAppId,
            },
            {
              milestoneId: campaignInitialData?.milestones[1]?.milestoneId,
              title: milestoneTwo.title ? milestoneTwo.title : "",
              definition: milestoneTwo.defination
                ? milestoneTwo.defination
                : "",
              deadline: milestoneTwo.deadline ? milestoneTwo.deadline : "",
              deliverable: "non",
              milestoneAppId:
                campaignInitialData?.milestones[1]?.milestoneAppId,
            },
          ],
          matchFunding: campaignInitialData?.matchFunding,
          goalIds: campaignInitialData?.goalIds?.map((a: any) => a.goalId),
        },
        isEsgEnabled: ESG_SDG_status,

        additionalInfo: campaignInitialData?.additionalInfo,
        additionalInfoData: {
          additionalInfoId:
            campaignInitialData?.additionalInfoId?.additionalInfoId,
          consent: campaignInitialData?.additionalInfoId?.consent,
          contactName: campaignInitialData?.additionalInfoId?.contactName,
          contactEmail: campaignInitialData?.additionalInfoId?.contactEmail,
          contactPhone: campaignInitialData?.additionalInfoId?.contactPhone,
          companyOrProjectName:
            campaignInitialData?.additionalInfoId?.companyOrProjectName,
          website: campaignInitialData?.additionalInfoId?.website,
          projectPitch: campaignInitialData?.additionalInfoId?.projectPitch,
          problems: campaignInitialData?.additionalInfoId?.problems,
          previousSuccessfulProjects:
            campaignInitialData?.additionalInfoId?.previousSuccessfulProjects,
          hq: campaignInitialData?.additionalInfoId?.hq,
          impact: campaignInitialData?.additionalInfoId?.impact,
          partnerships: campaignInitialData?.additionalInfoId?.partnerships,
          yearOfEstablishment:
            campaignInitialData?.additionalInfoId?.yearOfEstablishment,
          image: campaignInitialData?.additionalInfoId?.image,
          companyBio: campaignInitialData?.additionalInfoId?.companyBio,
          reachoutRegardingSubmission:
            campaignInitialData?.additionalInfoId?.reachoutRegardingSubmission,
          geographies: campaignInitialData?.additionalInfoId?.geographies,
          additionalInformation:
            campaignInitialData?.additionalInfoId?.additionalInformation,
        },
      };
    }

    const res = await updateDraft(payload);
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(false));
    if (res.success) {
      const toastPaylaod = {
        text: t("savedToDraftSuccessfully"),
        success: true,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      nextStatus && handleSteps();
      if (submitStatus) {
        dispatch(toggleAppLoading(true));
        dispatch(setAppLoadingText(t("creatingCampaign")));
        handleCampaignCreate();
      }
      // cleanFormState();
      // navigationStatus && navigate("/user/my-campaign");
    } else {
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(toggleAppLoading(false));
    }
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(false));
    dispatch(toggleAppLoading(false));
    localStorage.removeItem("milestones");
  };

  /**
   * handle save to draft function end
   */

  /**
   * auto save to draft
   */

  // useEffect(() => {
  //   let FeildsValidation =
  //     title !== "" &&
  //     description !== "" &&
  //     category.length !== 0 &&
  //     fundingCategory.length !== 0 &&
  //     goalAmount !== "" &&
  //     country !== "" &&
  //     endDate !== "";

  //   let prevData =
  //     campaignInitialData?.title !== title ||
  //     campaignInitialData?.description !== description ||
  //     campaignInitialData?.category?.categoryId !== category ||
  //     campaignInitialData?.endDate !== endDate ||
  //     campaignInitialData?.fundingCategory?.fundingCategoryId !==
  //       fundingCategory ||
  //     campaignInitialData?.goalAmount !== goalAmount ||
  //     campaignInitialData?.country?.toLocaleLowerCase() !==
  //       country.toLocaleLowerCase();

  //   if (FeildsValidation && prevData) {
  //     handleSaveToDraft(false, false);
  //   }
  // }, [
  //   title,
  //   description,
  //   goalAmount,
  //   category,
  //   fundingCategory,
  //   country,
  //   endDate,
  // ]);

  /**
   * auto save to draft end
   */

  useEffect(() => {
    if (campaignInitialData.title) {
      handleTitle(campaignInitialData.title);
      handleDescription(campaignInitialData.description);
      handleCategory(campaignInitialData.category.categoryId);
      handleEndDate(campaignInitialData.endDate);
      handleYearlyOutput(campaignInitialData.expectedYearlyoutput);
      handleCostPerUnit(campaignInitialData.expectedCostPerUnit);
      handleResults(campaignInitialData.expectedResults);
      setLocation(campaignInitialData.location);
      setCoordinates(campaignInitialData.lat);
      setCoordinates(campaignInitialData.lng);
      handleYoutubelink(campaignInitialData.youTubeLink);
      handleFundingCategory(
        campaignInitialData.fundingCategory.fundingCategoryId
      );
      handleGoalAmount(campaignInitialData.goalAmount);
      handleCountry(campaignInitialData.country.toLocaleLowerCase());
      handleImage(campaignInitialData?.images);
      setImageName(campaignInitialData?.images);
      handleVideo(campaignInitialData?.video);
      setVideoName(campaignInitialData?.video);
      const nftData = {
        rewardNftId: campaignInitialData?.reward,
        rewardType: campaignInitialData?.rewardType,
      };
      dispatch(setRewardType(nftData));
      handleESG_SDG_status(
        campaignInitialData.isEsgEnabled !== undefined
          ? campaignInitialData.isEsgEnabled
          : ""
      );

      handleMonitoringStatus(
        campaignInitialData.isReforestationProject !== undefined
          ? {
              title: campaignInitialData.isReforestationProject ? "Yes" : "No",
              status: campaignInitialData.isReforestationProject,
            }
          : { title: "", status: false }
      );
    }
    if (
      campaignInitialData.milestones &&
      campaignInitialData.milestones.length > 0
    ) {
      let tempMilestoneOne = {
        title: campaignInitialData.milestones[0].title,
        defination: campaignInitialData.milestones[0].definition,
        deadline: campaignInitialData.milestones[0].deadline,
        milestoneId: campaignInitialData.milestones[0].milestoneId,
        delivereble: campaignInitialData.milestones[0].deliverable,
      };
      handleMilestoneOne(tempMilestoneOne);
      let tempMilestoneTwo = {
        title: campaignInitialData.milestones[1].title,
        defination: campaignInitialData.milestones[1].definition,
        deadline: campaignInitialData.milestones[1].deadline,
        milestoneId: campaignInitialData.milestones[1].milestoneId,
        delivereble: campaignInitialData.milestones[1].deliverable,
      };
      handleMilestoneTwo(tempMilestoneTwo);
    }
  }, [campaignInitialData]);

  useEffect(() => {
    fetchCategories();
    fetchInitialValues();
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(false));
  }, []);

  return (
    <Box sx={CampaigngForm}>
      <Typography variant="h4" mt={3} align="center" mb={3}>
        {t("editDraftCampaign")}
      </Typography>
      <Grid container spacing={2}>
        <Grid
          item
          lg={4}
          md={6}
          sx={{ width: { md: "auto", sm: "50%", xs: "100%" } }}
        >
          <FormLabel component="legend">{t("title")}</FormLabel>
          <TextField
            ref={titleRef}
            sx={{ marginBottom: 3 }}
            fullWidth
            hiddenLabel
            type={"text"}
            placeholder={t("title") || ""}
            variant="outlined"
            onChange={(e) => {
              handleTitle(e.target.value);
            }}
            value={title}
            error={invalidTitle}
            helperText={invalidTitle && errorTitle}
          />
          <FormLabel component="legend">{t("description")}</FormLabel>

          <TextField
            ref={descriptionRef}
            sx={{ marginBottom: 3 }}
            fullWidth
            hiddenLabel
            multiline
            minRows={4}
            type={"text"}
            placeholder={t("description") || ""}
            variant="outlined"
            onChange={(e) => {
              handleDescription(e.target.value);
            }}
            value={description}
            error={invalidDescription}
            helperText={invalidDescription && errorDescription}
          />
          <FormLabel component="legend">{t("image")}</FormLabel>
          <UploadImage
            image={image}
            setImage={handleImage}
            imageName={imageName}
            imageSelected={imageSelected}
            setImageName={setImageName}
            setImageSelected={setImageSelected}
            imageRef={imageRef}
            imageId={selectedImageId}
          />
          {imageName && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "300px",
                width: "100%",
              }}
            >
              {imageName?.length > 1 &&
                imageName?.map((item: any, index: any) => (
                  <IconButton
                    key={index}
                    onClick={() => setSelectedImageId(index)}
                  >
                    <Box
                      sx={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "5px",
                        backgroundColor:
                          index === selectedImageId ? "#000" : "gray",
                        marginX: "2.5px",
                      }}
                    />
                  </IconButton>
                ))}
            </Box>
          )}
          {image?.length === 0 && (
            <FormHelperText
              sx={{ marginBottom: 3 }}
              className="css-l8pll-MuiFormHelperText-root Mui-error"
            >
              {imageName?.length > 5
                ? "You can not upload more than five images"
                : imageErrorMsg}
            </FormHelperText>
          )}
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          sx={{ width: { md: "auto", sm: "50%", xs: "100%" } }}
        >
          <Box sx={{ marginBottom: 3 }}>
            <FormLabel component="legend">{t("campaignCategory")}</FormLabel>
            <Select
              ref={categoryRef}
              fullWidth
              value={category}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleCategoryChange}
            >
              {campCategoryList.length > 0 &&
                campCategoryList.map((catg: any) => {
                  return (
                    <MenuItem value={catg.categoryId}>{catg.title}</MenuItem>
                  );
                })}
            </Select>
            {invalidCategory && (
              <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                {t("pleaseSelectCategory")}
              </FormHelperText>
            )}
          </Box>
          <Box sx={{ marginBottom: 3 }}>
            <FormLabel component="legend">{t("campaignEndingDate")}</FormLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DatePicker
                  ref={endDateRef}
                  inputFormat="YYYY-MM-DD"
                  value={endDate}
                  onChange={handleChange}
                  renderInput={(params: any) => (
                    <TextField {...params} id={"campaignEndingDate"} />
                  )}
                  disablePast
                />
              </Stack>
            </LocalizationProvider>
            {invalidEndDate && (
              <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                {errorEndDate}
              </FormHelperText>
            )}
          </Box>
          <Box sx={{ marginBottom: 3 }}>
            <FormLabel component="legend">{t("fundingCategory")}</FormLabel>
            <Select
              ref={fundingCategoryRef}
              fullWidth
              value={fundingCategory}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleFundingCategoryChange}
            >
              {fundCategoryList.length > 0 &&
                fundCategoryList.map((catg: any) => {
                  return (
                    <MenuItem value={catg.fundingCategoryId}>
                      {catg.title}
                    </MenuItem>
                  );
                })}
            </Select>
            {invalidFundingCategory && (
              <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                {t("pleaseSelectFundingCategory")}
              </FormHelperText>
            )}
          </Box>

          <Box sx={{ marginBottom: 3 }}>
            <FormLabel component="legend">
              Goal(
              <img
                style={{ width: "16px", marginBottom: "-3px" }}
                src={algo}
              ></img>
              )* between 5 and 1000000
            </FormLabel>
            <TextField
              ref={goalAmountRef}
              fullWidth
              hiddenLabel
              // type={"number"}
              placeholder={t("enterCampaignGoal") || ""}
              variant="outlined"
              onChange={(e) => {
                if (parseInt(e.target.value) > 1000000) {
                  handleGoalAmount("1000000");
                } else {
                  handleGoalAmount(e.target.value);
                }
              }}
              value={goalAmount}
              error={invalidGoalAmount}
              helperText={invalidGoalAmount && errorGoalAmount}
            />
          </Box>
          <Box sx={{ marginBottom: 3 }}>
            <FormLabel component="legend">{t("country")}</FormLabel>
            <Select
              ref={countryRef}
              fullWidth
              value={country}
              onChange={handleCountryChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {countryList.map((country) => {
                return (
                  <MenuItem value={country.toLocaleLowerCase()} key={country}>
                    {country}
                  </MenuItem>
                );
              })}
            </Select>
            {invalidCountry && (
              <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                {t("pleaseSelectCountry")}
              </FormHelperText>
            )}
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          sx={{
            width: { md: "auto", sm: "50%", xs: "100%" },
          }}
        >
          <FormLabel component="legend">
            {t("video")} ({t("optional")})
          </FormLabel>
          <UploadVideo
            setVideo={handleVideo}
            video={video}
            setVideoName={setVideoName}
            setVideoSelected={handleVideoSelected}
            videoName={videoName}
            videoSelected={videoSelected}
            videoRef={videoRef}
            inputRef={inputRef}
          />
          <Typography
            style={{
              fontFamily: "bold",
              fontSize: "15px",
              marginBottom: "3px",
            }}
          >
            OR{" "}
          </Typography>
          <FormLabel component="legend">
            {t("youtubelink")} ({t("optional")})
          </FormLabel>
          <TextField
            sx={{ marginBottom: 3 }}
            fullWidth
            hiddenLabel
            type={"text"}
            placeholder={t("youtubelink") || ""}
            variant="outlined"
            name="youTubeLink"
            value={youTubeLink}
            onChange={(e) => {
              handleYoutubelink(e.target.value);
            }}
          />

          {
            // this is the id of type reward
            (fundingCategory === TEST_FUNDING_CATEGORY ||
              fundingCategory === STAGING_FUNDING_CATEGORY ||
              fundingCategory === PRODUCTION_FUNDING_CATEGORY) && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {rewardType === "NFTs" && (
                  <FormLabel component="legend">
                    {t("selectedNft")} - {selectedNft}
                  </FormLabel>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className="round-button"
                  sx={{
                    marginRight: "auto",
                    backgroundColor: "#000000de",
                    color: "#61f359",
                  }}
                  onClick={handleRewardModal}
                >
                  {t("selectReward")}
                </Button>
                {invalidNft && (
                  <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                    {t("pleaseSelectReward")}
                  </FormHelperText>
                )}
              </Box>
            )
          }
        </Grid>
      </Grid>
      <MilestoneSet
        milestoneOne={milestoneOne}
        milestoneTwo={milestoneTwo}
        invalidDateOne={invalidDateOne}
        invalidDateTwo={invalidDateTwo}
        setMilestoneOne={handleMilestoneOne}
        setMilestoneTwo={handleMilestoneTwo}
        invalidDefinitionOne={invalidDefinitionOne}
        invalidDefinitionTwo={invalidDefinitionTwo}
        invalidTitleOne={invalidTitleOne}
        invalidTitleTwo={invalidTitleTwo}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginTop: "50px",
          marginBottom: "60px",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
          {t("isCampaignaReforestationProject")}
        </Typography>
        <Box sx={{ marginTop: "20px" }}>
          <Button
            id="basic-button2"
            aria-controls={open2 ? "basic-menu2" : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? "true" : undefined}
            onClick={handleClick2}
            sx={{ color: "#000", border: "1px solid gray" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {monitoringStatus.title !== ""
              ? monitoringStatus.title
              : t("pleaseSelectOption")}
          </Button>
          <Menu
            id="basic-menu2"
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            MenuListProps={{
              "aria-labelledby": "basic-button2",
            }}
          >
            <Box sx={{ width: "200px" }}>
              <MenuItem
                onClick={() => {
                  handleMonitoringStatus({ title: "Yes", status: true });
                  handleClose2();
                }}
              >
                {t("yes")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleMonitoringStatus({ title: "No", status: false });
                  handleClose2();
                }}
              >
                {t("no")}
              </MenuItem>
            </Box>
          </Menu>
        </Box>
        {invalidMonitoringOption && (
          <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
            {errorInvalidMonitoringTitle}
          </FormHelperText>
        )}
      </Box>
      {monitoringStatus.title === "Yes" && (
        <Box sx={CampaigngForm}>
          <Typography variant="h4" mt={3} align="center" mb={3}>
            {t("monitoringDetails")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormLabel component="legend">Project Output</FormLabel>
              <TextField
                value={YearlyOutput}
                ref={YearlyOutputRef}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                sx={{ marginBottom: 3 }}
                hiddenLabel
                type="number"
                placeholder={t("yearlyOutput") || ""}
                onChange={(e) => {
                  handleYearlyOutput(e.target.value);
                }}
                error={invalidYearlyOutput}
                helperText={invalidYearlyOutput && errorYearlyOutput}
              />
              {/* <FormLabel component="legend">CostPerUnit</FormLabel>
              <TextField
                ref={CostPerUnitRef}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                sx={{ marginBottom: 3 }}
                hiddenLabel
                type="number"
                placeholder={t("costPerUnit($)") || ""}
                onChange={(e) => {
                  handleCostPerUnit(e.target.value);
                }}
                value={CostPerUnit}
                error={invalidCostPerUnit}
                helperText={invalidCostPerUnit && errorCostPerUnit}
              /> */}
              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">Location</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={locOption}
                  onChange={(e) => setLocOption(e.target.value)}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {campaignInitialData.location && (
                      <FormControlLabel
                        value="location"
                        control={<Radio />}
                        label={t("searchLocation")}
                        style={{ marginRight: "2rem" }} // Adjust the spacing between text and radio button
                      />
                    )}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {campaignInitialData.location === null && (
                      <FormControlLabel
                        value="coordinates"
                        control={<Radio />}
                        label={t("enterLocation")}
                        style={{ marginRight: "2rem" }} // Adjust the spacing between text and radio button
                      />
                    )}
                  </div>
                </RadioGroup>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {locOption === "location" && campaignInitialData.location ? (
                    <PlacesAutocomplete
                      value={location}
                      // onChange={handleLocation}
                      onChange={(newValue) => {
                        setLocation(newValue);
                      }}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div style={{ width: "100%" }}>
                          {/* <p>lat : {coordinates.lat}</p>
      <p>lat : {coordinates.lng}</p>  */}

                          <TextField
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "location-search-input",
                            })}
                            error={invalidLocation}
                            helperText={invalidLocation && errorLocation}
                            ref={locationRef}
                            sx={{ marginTop: 2 }}
                            required
                            fullWidth
                            hiddenLabel
                            type={"text"}
                            variant="outlined"
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  ) : locOption === "coordinates" &&
                    campaignInitialData.location === null ? (
                    <Grid container spacing={2}>
                      {/* ... (rest of your code for the coordinates input fields) */}
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField
                          value={coordinates.lat || campaignInitialData.lat}
                          // value={coordinates && coordinates.lat ? coordinates.lat : campaignInitialData.lat}
                          // value={locOption === "coordinates" ? campaignInitialData.lat : coordinates.lat}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          required
                          sx={{ marginBottom: 1 }}
                          hiddenLabel
                          type="number"
                          ref={coordinatesRef}
                          placeholder={"Latitude" || ""}
                          onChange={(e: any) => {
                            if (locOption === "coordinates") {
                              setCoordinates({
                                ...coordinates,
                                lat: e.target.value,
                              });
                            }
                          }}
                          // onChange={(e: any) => {
                          //   setCoordinates({
                          //     ...coordinates,
                          //     lat: e.target.value,
                          //   });
                          // }}
                          error={invalidlatitude}
                          helperText={
                            invalidlatitude && t("pleaseEnterLatitude")
                          }
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField
                          value={coordinates.lng || campaignInitialData.lng}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          required
                          ref={coordinatesRef}
                          sx={{ marginBottom: 3 }}
                          hiddenLabel
                          type="number"
                          placeholder={"Longitude" || ""}
                          onChange={(e: any) => {
                            if (locOption === "coordinates") {
                              setCoordinates({
                                ...coordinates,
                                lng: e.target.value,
                              });
                            }
                          }}
                          // onChange={(e: any) => {
                          //   setCoordinates({
                          //     ...coordinates,
                          //     lng: e.target.value,
                          //   });
                          // }}
                          error={invalidlongitude}
                          helperText={
                            invalidlongitude && t("pleaseEnterLongitude")
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </div>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormLabel component="legend">CostPerUnit</FormLabel>
              <TextField
                ref={CostPerUnitRef}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                sx={{ marginBottom: 3 }}
                hiddenLabel
                type="number"
                placeholder={t("costPerUnit($)") || ""}
                onChange={(e) => {
                  handleCostPerUnit(e.target.value);
                }}
                value={CostPerUnit}
                error={invalidCostPerUnit}
                helperText={invalidCostPerUnit && errorCostPerUnit}
              />

              <Box sx={{ marginBottom: 3 }}>
                <FormLabel component="legend">Expected Results</FormLabel>
                <TextField
                  ref={resultsRef}
                  multiline
                  type={"text"}
                  variant="outlined"
                  minRows={3}
                  margin="normal"
                  fullWidth
                  required
                  sx={{ marginBottom: 3 }}
                  hiddenLabel
                  placeholder={t("expectedResults") || ""}
                  onChange={(e) => {
                    handleResults(e.target.value);
                  }}
                  value={Results}
                  error={invalidResults}
                  helperText={invalidResults && errorResults}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginTop: "50px",
          marginBottom: "60px",
        }}
      >
        <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
          {t("isYourCampaignAlignedWithAnyGoal")}
        </Typography>
        <Box sx={{ marginTop: "20px" }}>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ color: "#000", border: "1px solid gray" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {ESG_SDG_status === ""
              ? t("pleaseSelectOption")
              : ESG_SDG_status
              ? t("yes")
              : t("no")}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Box sx={{ width: "200px" }}>
              <MenuItem
                onClick={() => {
                  handleESG_SDG_status(true);
                  handleClose();
                }}
              >
                {t("yes")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleESG_SDG_status(false);
                  handleClose();
                }}
              >
                {t("no")}
              </MenuItem>
            </Box>
          </Menu>
        </Box>
        {invalidEsgOption && (
          <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
            {errorInvalidEsgTitle}
          </FormHelperText>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "60px",
          marginTop: 3,
        }}
      >
        <Button
          variant="contained"
          color="inherit"
          className="round-button"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t("back")}
        </Button>
        <Button
          variant="contained"
          color="error"
          className="round-button"
          onClick={() => handleDelete()}
        >
          {t("deleteDraft")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="round-button"
          onClick={() => {
            if (ESG_SDG_status === "") {
              setInvalidEsgOption(true);
              setErrorInvalidEsgTitle("Please select an Option");
            } else {
              handleSaveToDraft(false, false);
            }
          }}
        >
          {t("saveDraft")}
        </Button>
        {kyc === "approved" && ESG_SDG_status === false && (
          <>
            <Button
              variant="contained"
              color="primary"
              className="round-button"
              onClick={() => setShowWalletButtons(true)}
            >
              {t("submit")}
            </Button>

            {showWalletButtons && (
              <Wallets
                open={showWalletButtons}
                handleClose={() => setShowWalletButtons(false)}
                handleSubmit={handleSubmit}
              />
            )}
          </>
        )}

        {ESG_SDG_status === true && (
          <Button
            variant="contained"
            color="primary"
            className="round-button"
            onClick={() => {
              if (ESG_SDG_status.title === "") {
                setInvalidEsgOption(true);
                setErrorInvalidEsgTitle("Please select an Option");
              } else {
                handleSaveToDraft(true, false);
              }
            }}
          >
            {t("next")}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default EditDraftCampaign;
