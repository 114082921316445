import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Icon } from "@iconify/react";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Carousel from "react-multi-carousel";
import Stack from "@mui/material/Stack";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect, useState } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import {
  PreviewWrap,
  UploadBox,
  Range,
  DescriptionTabs,
  SocialIcon,
  thumbnailImg,
} from "./style";
import { useAppDispatch, useAppSelector } from "../../../store";
import moment from "moment";
import {
  previewImage,
  setPreviewImageModal,
  setSelectRoleModal,
} from "../../../store/slices/ModalSlice";
import { useNavigate, useParams } from "react-router-dom";
import { getCampaignData } from "../../../requests/simpleRequest";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import FundNowTab from "../../../components/home/campaigns/FundNowTab";
import ReviewTab from "../../../components/home/campaigns/ReviewTab";
import { initiateChat } from "../../../requests/authRequest";
import { setCurrentActiveChat } from "../../../store/slices/ChatSlice";
import RewardNftCard from "../../../components/home/campaigns/RewardNftCard";
import { getBlockchainNftById } from "../../../blockchain";
import { useTranslation } from "react-i18next";
import { Google } from "@mui/icons-material";
import algo from "../../assets/images/algo.png";
import YouTube from "react-youtube";

const URL = process.env.REACT_APP_URL;
var getYouTubeID = require("get-youtube-id");
function Campaign() {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("1");
  const dispatch = useAppDispatch();
  const { campaignId } = useParams();
  const [previewState, setPreviewState] = useState<any>({});
  const campaignState = useAppSelector((state) => state.campaignPreviewReducer);

  const pageUrl = window.location.href;

  const [loading, setLoading] = useState(true);
  const [daysRemaining, setDaysRemainig] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(0);
  const navigate = useNavigate();
  const loggedIn = useAppSelector((state) => state.userReducer.loggedIn);
  const isDonor = useAppSelector(
    (state) =>
      state.userReducer.role === "investor" ||
      state.userReducer.role === "institutional-donor"
  );
  const userId = useAppSelector((state) => state.userReducer.userId);
  const loggedInUserRole = useAppSelector((state) => state.userReducer.role);

  const [hadDonated, setHadDonated] = useState<boolean>(false);
  const [hadReviewd, setHadReviewed] = useState<boolean>(true);

  const [rewardInfo, setRewardInfo] = useState<any>();

  const responsive = {
    desktop: {
      breakpoint: { max: 5000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleThumbnailPreview = (e: React.MouseEvent<HTMLElement>) => {
    const payload: previewImage = {
      imageUrl: e.currentTarget.id,
      previewImage: true,
    };
    dispatch(setPreviewImageModal(payload));
  };

  const fetchCampaignData = async () => {
    setLoading(true);
    const res = await getCampaignData(campaignId);
    if (res.success) {
      setPreviewState(res.data.campaign);

      const today = moment();
      const endDate = moment(res.data.campaign.endDate);
      setDaysRemainig(endDate.diff(today, "days"));
    } else {
      const toastPaylaod = {
        text: t("campaignDoesNotExist"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      navigate("/");
    }
    setLoading(false);
  };

  const fetchRewardNFT = async () => {
    if (previewState.rewardNftId) {
      const info = {
        nftId: previewState.reward,
      };
      // const res = await getNftById(previewState.rewardNftId);
      const res = await getBlockchainNftById(info);
      if (res.success) {
        setRewardInfo(res.data);
      } else {
        setRewardInfo({});
      }
    }
  };

  const handleRegisterModal = () => {
    dispatch(setSelectRoleModal(true));
  };

  const handleFundNowButton = () => {
    if (!loggedIn) {
      handleRegisterModal();
      const toastPayload = {
        active: true,
        text: t("pleaseRegisterOrSigninAsDonorToDonate"),
        success: false,
      };
      dispatch(setToaster(toastPayload));
      return;
    }
    // if (hadDonated) {
    // 	const toastPayload = {
    // 		active: true,
    // 		text: "You have already donated to the campaign",
    // 		success: false,
    // 	};
    // 	dispatch(setToaster(toastPayload));
    // 	return;
    // }
    if (isDonor && value !== "4") {
      setValue("4");
    } else if (isDonor && value === "4") {
      setValue("0");
    } else {
      const toastPayload = {
        active: true,
        text: t("onlyRegisteredDonorsCanDonate"),
        success: false,
      };
      dispatch(setToaster(toastPayload));
    }
  };

  useEffect(() => {
    if (campaignId) {
      fetchCampaignData();
    }
  }, [campaignId]);

  useEffect(() => {
    if (previewState.reward) {
      fetchRewardNFT();
    }
  }, [previewState]);

  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);

  useEffect(() => {
    if (previewState.donors && previewState.donors.length > 0) {
      if (previewState.donors.indexOf(userId) !== -1) {
        setHadDonated(true);
      } else {
        setHadDonated(false);
      }
    } else {
      setHadDonated(false);
    }

    if (previewState.reviewers && previewState.reviewers.length > 0) {
      if (previewState.reviewers.indexOf(userId) !== -1) {
        setHadReviewed(true);
      } else {
        setHadReviewed(false);
      }
    } else {
      setHadReviewed(false);
    }

    // logic to set percentage donated
    const collected = Number(previewState.collectedAmount);
    const totalGoal = Number(previewState.goalAmount);
    const per = ((collected / totalGoal) * 100).toPrecision(2);
    setPercentage(parseFloat(per));
  }, [previewState, loggedIn]);

  const handleInitiateChat = async () => {
    if (!loggedIn) {
      const toastPayload = {
        active: true,
        text: t("pleaseLoginToChat"),
        success: false,
      };
      dispatch(setToaster(toastPayload));
      return;
    }
    if (previewState.createdBy.userId === userId) {
      const toastPayload = {
        active: true,
        text: t("youAreTheCreatorOfThisCampaign"),
        success: false,
      };
      dispatch(setToaster(toastPayload));
    } else {
      dispatch(toggleAppLoading(true));
      const info = {
        participant: previewState.createdBy.userId,
        limit: 10,
      };
      const res = await initiateChat(info);
      dispatch(setCurrentActiveChat(res.data.chatChannel));
      navigate("/user/chat");
      dispatch(toggleAppLoading(false));
    }
  };

  const shareLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`,
      "_blank"
    );
  };

  return (
    <>
      {!loading && (
        <Box sx={PreviewWrap}>
          <Container fixed>
            <Grid
              container
              columnSpacing={{ xs: 2, md: 6, xl: 9 }}
              sx={{ marginTop: "40px" }}
            >
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h3"
                  className="semibold"
                  align="left"
                  mb={3}
                >
                  {previewState.title}
                </Typography>
                <Box
                  sx={{
                    height: "534px",
                    width: "100%",
                  }}
                >
                  <Carousel
                    swipeable={
                      previewState.video || previewState.youTubeLink
                        ? true
                        : false
                    }
                    draggable={false}
                    showDots={
                      previewState.video || previewState.youTubeLink
                        ? true
                        : false
                    }
                    responsive={responsive}
                    ssr={false} // means to render carousel on server-side.
                    infinite={false}
                    autoPlay={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container view-campaign"
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {previewState.video && (
                      <video
                        src={`${URL}/api/file/video/${previewState.video}`}
                        controls
                        width={"100%"}
                        height={"100%"}
                        autoPlay={false}
                      />
                    )}
                    {previewState.youTubeLink && (
                      <YouTube
                        videoId={getYouTubeID(previewState.youTubeLink)}
                        opts={{
                          height: "400px",
                          width: "100%",
                          playerVars: {
                            autoplay: 0,
                          },
                        }}
                      />
                    )}

                    <Carousel
                      swipeable={true}
                      draggable={false}
                      showDots={previewState?.images?.length > 1 ? true : false}
                      responsive={responsive}
                      ssr={false} // means to render carousel on server-side.
                      infinite={false}
                      autoPlay={false}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="carousel-container preview-campaign"
                      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                    >
                      {previewState?.images?.map((item: any) => (
                        <Box
                          sx={{
                            backgroundImage: `url('${URL}/api/file/image/${item}')`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            width: "90%",
                            height: "300px",
                            cursor: "pointer",
                          }}
                          id={
                            item.length > 0
                              ? `${URL}/api/file/image/${item}`
                              : ""
                          }
                          onClick={handleThumbnailPreview}
                        />
                      ))}
                    </Carousel>
                  </Carousel>
                </Box>
                <Box sx={Range}>
                  <Box className="RangeBox">
                    <div
                      className="rangecolor"
                      style={{
                        width: `${
                          isNaN(percentage)
                            ? 0
                            : percentage > 100
                            ? 100
                            : percentage
                        }%`,
                      }}
                    ></div>
                  </Box>
                  <Box className="valuebox">
                    <Typography variant="body2" className="percentage semibold">
                      {isNaN(percentage)
                        ? 0
                        : percentage > 100
                        ? 100
                        : percentage}
                      %{" "}
                      {`(${previewState.collectedAmount || 0} / ${
                        previewState.goalAmount
                      })`}
                    </Typography>
                    <Typography variant="body2" className="days semibold">
                      {daysRemaining} days
                    </Typography>
                  </Box>
                </Box>
                {previewState?.isReforestationProject && (
                  <Box
                    sx={{ mb: 3 }}
                    style={{ textAlign: "left", marginTop: "60px" }}
                  >
                    <Typography
                      variant="h4"
                      align="left"
                      mb={3}
                      fontWeight={600}
                      style={{
                        backgroundColor: "#dfe1df",
                        borderRadius: "7px",
                        marginTop: "20px",
                        height: "50px",
                        padding: "10px",
                      }}
                    >
                      {t("isCampaignaReforestationProject")}
                      {""}
                    </Typography>

                    <div
                      className="label"
                      style={{
                        backgroundColor: "#dfe1df",
                        borderRadius: "7px",
                        marginTop: "20px",
                        // height: "50px",
                        padding: "10px",
                        fontSize: "20px",
                      }}
                    >
                      {" "}
                      {t("costPerUnit($)")} :{" "}
                      {previewState.expectedCostPerUnit || ""}
                    </div>
                    <div
                      className="label"
                      aria-multiline
                      style={{
                        backgroundColor: "#dfe1df",
                        borderRadius: "7px",
                        marginTop: "20px",
                        // minHeight: "50%",
                        padding: "10px",
                        fontSize: "20px",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {t("expectedResults")} :{" "}
                      {previewState.expectedResults || ""}
                    </div>

                    <div
                      className="label"
                      style={{
                        backgroundColor: "#dfe1df",
                        borderRadius: "7px",
                        marginTop: "20px",
                        // height: "50px",
                        padding: "10px",
                        fontSize: "20px",
                      }}
                    >
                      {t("yearlyOutput")} :{" "}
                      {previewState.expectedYearlyoutput || 0}
                    </div>
                    <div
                      className="label"
                      style={{
                        position: "relative",
                        backgroundColor: "#dfe1df",
                        borderRadius: "7px",
                        marginTop: "20px",
                        // height: "50px",
                        padding: "10px",
                        fontSize: "20px",
                      }}
                    >
                      {previewState.location === null ? (
                        <>
                          {" "}
                          <p>
                            {" "}
                            {t("latitude")} :{previewState.lat}
                          </p>{" "}
                          <p>
                            {" "}
                            {t("longitude")}: {previewState.lng}
                          </p>{" "}
                        </>
                      ) : (
                        <p>
                          {" "}
                          {t("location")} :{previewState.location}
                        </p>
                      )}
                      {/* {t("location")} : {previewState.location || <> <p> {t("latitude")} :{previewState.lat}</p> <p> {t("longitude")}: {previewState.lng}</p> </>}{" "} */}
                    </div>
                    <Box
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "300px",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <Map
                        google={google}
                        initialCenter={{
                          lat: previewState.lat,
                          lng: previewState.lng,
                        }}
                        zoom={14}
                      >
                        <Marker
                          position={{
                            lat: previewState.lat,
                            lng: previewState.lng,
                          }}
                        />
                      </Map>
                    </Box>
                  </Box>
                )}
                {previewState?.isEsgEnabled && (
                  <Box
                    sx={{ mb: 3 }}
                    style={{ textAlign: "left", marginTop: "60px" }}
                  >
                    <Typography
                      variant="h4"
                      align="left"
                      mb={3}
                      fontWeight={600}
                      style={{
                        backgroundColor: "#dfe1df",
                        borderRadius: "7px",
                        marginTop: "20px",
                        height: "50px",
                        padding: "10px",
                      }}
                    >
                      {t("goals")}
                    </Typography>
                    {previewState.goalIds?.filter(
                      (el: any) => el?.category === "environmental"
                    ).length > 0 && (
                      <Typography variant="h5" align="left" mt={3} mb={3}>
                        {t("environmentalGoal")}
                      </Typography>
                    )}
                    <Grid
                      container
                      spacing={2}
                      style={{
                        textAlign: "left",
                        paddingLeft: "10px",
                        fontSize: "20px",
                      }}
                    >
                      {previewState.goalIds
                        ?.filter((el: any) => el?.category === "environmental")
                        ?.map((item: any) => (
                          <Grid
                            xs={12}
                            style={{ textAlign: "left", paddingLeft: "20px" }}
                          >
                            <Box>
                              <p>{item.goalName}</p>
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                    {previewState.goalIds?.filter(
                      (el: any) => el?.category === "social"
                    ).length > 0 && (
                      <Typography variant="h5" align="left" mt={3} mb={3}>
                        {t("socialGoal")}
                      </Typography>
                    )}
                    <Grid
                      container
                      spacing={2}
                      style={{
                        textAlign: "left",
                        paddingLeft: "10px",
                        fontSize: "20px",
                      }}
                    >
                      {previewState.goalIds
                        ?.filter((el: any) => el?.category === "social")
                        ?.map((item: any) => (
                          <Grid
                            xs={12}
                            style={{ textAlign: "left", paddingLeft: "20px" }}
                          >
                            <Box>
                              <Box>
                                <p>{item.goalName}</p>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                    {previewState.goalIds?.filter(
                      (el: any) => el?.category === "governance"
                    ).length > 0 && (
                      <Typography variant="h5" align="left" mt={3} mb={3}>
                        {t("governanceGoal")}
                      </Typography>
                    )}
                    <Grid
                      container
                      spacing={2}
                      style={{
                        textAlign: "left",
                        paddingLeft: "10px",
                        fontSize: "20px",
                      }}
                    >
                      {previewState.goalIds
                        ?.filter((el: any) => el?.category === "governance")
                        ?.map((item: any) => (
                          <Grid
                            xs={12}
                            style={{ textAlign: "left", paddingLeft: "20px" }}
                          >
                            <Box>
                              <Box>
                                <p>{item.goalName}</p>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {previewState?.isEsgEnabled && (
                  <Stack direction="row" spacing={2}>
                    {previewState?.goalIds.filter((goal: any) => {
                      return goal.category === "environmental";
                    }).length > 0 && (
                      <Button
                        variant="contained"
                        style={{
                          height: "30px",
                          backgroundColor: "#8bc34a7d",
                          cursor: "auto",
                        }}
                      >
                        {t("environmental")}
                      </Button>
                    )}
                    {previewState?.goalIds.filter((goal: any) => {
                      return goal.category === "social";
                    }).length > 0 && (
                      <Button
                        variant="contained"
                        style={{
                          height: "30px",
                          backgroundColor: "#ffeb3b99",
                          cursor: "auto",
                        }}
                      >
                        {t("social")}
                      </Button>
                    )}
                    {previewState?.goalIds.filter((goal: any) => {
                      return goal.category === "governance";
                    }).length > 0 && (
                      <Button
                        variant="contained"
                        style={{
                          height: "30px",
                          backgroundColor: "#2196f3a3",
                          cursor: "auto",
                        }}
                      >
                        {t("governance")}
                      </Button>
                    )}
                  </Stack>
                )}
                <Typography
                  variant="h5"
                  className="semibold"
                  align="left"
                  mt={2}
                >
                  {t("fundingPeriod")}
                </Typography>
                <Typography variant="h6" className="light" align="left">
                  {moment().format("MMM Do")} -{" "}
                  {moment(previewState.endDate).format("MMM Do YYYY")}
                </Typography>
                {/* <Typography variant="body1" align="left">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                  blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
                  neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                  quasi quidem quibusdam.
                </Typography>
                <Grid item xs={12} lg={6} xl={7}>
                  <Typography
                    mt={3}
                    variant="h5"
                    className="regular"
                    align="left"
                  >
                    1 Donor&nbsp;&nbsp;&nbsp;$200 Donated
                  </Typography>
                </Grid> */}
                {previewState?.isEsgEnabled && previewState?.rating && (
                  <Grid
                    container
                    spacing={2}
                    style={{
                      backgroundColor: "#dfe1df",
                      borderRadius: "7px",
                      marginTop: "12px",
                      height: "93px",
                    }}
                  >
                    <Grid item xs={6} md={8}>
                      <Typography
                        variant="h5"
                        className="semibold"
                        align="left"
                        style={{ paddingTop: "14px" }}
                      >
                        {t("cashdilloSdgCampaignRatingScore")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} style={{ marginTop: "0" }}>
                      <Button
                        variant="contained"
                        style={{
                          height: "60px",
                          width: "40px",
                          backgroundColor: "#4caf50",
                          color: "#ffffff",
                          borderRadius: "50%",
                          fontSize: "25px",
                          cursor: "auto",
                        }}
                      >
                        {previewState.rating}
                      </Button>
                    </Grid>
                  </Grid>
                )}
                <Box sx={DescriptionTabs} style={{ minHeight: "auto" }}>
                  <Typography
                    variant="h5"
                    className="semibold"
                    align="left"
                    style={{ paddingTop: "14px" }}
                  >
                    {t("description")}
                  </Typography>
                  <p
                    style={{
                      textAlign: "justify",
                      paddingBottom: "15px",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {previewState.description}
                  </p>
                </Box>
                <Box
                  sx={DescriptionTabs}
                  style={{ minHeight: "auto", marginBottom: "15px" }}
                >
                  <TabContext value={value}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      variant="scrollable"
                    >
                      {previewState.fundingCategory.title === "Reward" && (
                        <Tab label="Rewards" value="5" />
                      )}
                    </TabList>
                    {previewState?.fundingCategory?.title === "Reward" &&
                      previewState?.reward && (
                        <Typography variant="h6" align="left">
                          {t("selectedNft")} - {previewState?.reward}
                        </Typography>
                      )}

                    {/* this tabpanel takes you to the fund now tab */}
                    <TabPanel value="4">
                      <FundNowTab
                        refreshData={fetchCampaignData}
                        campaignInfo={previewState}
                      />
                    </TabPanel>
                    <TabPanel value="5">
                      {previewState.rewardType === "NFTs" && (
                        <RewardNftCard data={previewState.reward} />
                      )}
                    </TabPanel>
                  </TabContext>
                </Box>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  sx={{
                    flexDirection: { lg: "inherit", xs: "row-reverse" },
                    width: "100%",
                  }}
                >
                  <Grid item xs={12} lg={6} xl={5}>
                    <Box
                      sx={{
                        textAlign: "left",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "20px",
                      }}
                    >
                      {(isNaN(percentage)
                        ? 0
                        : percentage > 100
                        ? 100
                        : percentage) < 100 &&
                        previewState.status === "active" && (
                          <Button
                            onClick={handleFundNowButton}
                            variant="contained"
                            className="darkButton"
                          >
                            {t("fundNow")}
                          </Button>
                        )}
                      <Box>
                        <IconButton sx={SocialIcon}>
                          <div
                            data-href={window.location.href}
                            data-layout=""
                            data-size=""
                          >
                            <a
                              target="_blank"
                              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&amp;src=sdkpreparse`}
                              rel="noreferrer"
                            >
                              <FacebookIcon />
                            </a>
                          </div>
                        </IconButton>
                        {/* <IconButton
                          sx={SocialIcon}>
                          <InstagramIcon />
                        </IconButton> */}

                        {/* <IconButton sx={SocialIcon} onClick={shareMedium}>
                          <FontAwesomeIcon icon={faMediumM} style={{ color: "#33FF33", fontSize: "20px" }} />
                        </IconButton> */}
                        <IconButton sx={SocialIcon} onClick={shareLinkedIn}>
                          <LinkedInIcon />
                        </IconButton>
                        <IconButton sx={SocialIcon}>
                          <a
                            target="_blank"
                            href={`https://twitter.com/intent/tweet?text=Help me support this campaign. \n ${window.location.href}`}
                            data-size="large"
                          >
                            <TwitterIcon />
                          </a>
                        </IconButton>
                        <IconButton
                          sx={SocialIcon}
                          onClick={handleInitiateChat}
                        >
                          <ChatBubbleIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
}
// export default Campaign;
export default GoogleApiWrapper({
  apiKey: "AIzaSyBaD7l4cML2F5NShPqSlkSzTDKQ4NF6ORQ",
})(Campaign);
