import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataGridWrap, footer } from "./style";
import React, { useEffect, useState } from "react";
import { getMyCampaigns } from "../../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import { toggleAppLoading } from "../../../store/slices/LoadinAndNotifSlice";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { setDeleteCampaignModal } from "../../../store/slices/ModalSlice";
import { useNavigate } from "react-router-dom";
import CustomNoRowsOverlay from "../../common/tables/CustomNoRowsOverlay";
import AddIcon from "@mui/icons-material/Add";
import algo from "../../../assets/images/algo.png";
import { useTranslation } from "react-i18next";

interface myCmpaignRow {
  id: number;
  Title: string;
  Goal: string;
  FundRaised: string;
  Status: string;
  Deadline: string;
  Action: string;
  campaignId: string;
  deleteCampaignAppId: string;
  deleteCampaignNftId: string;
  milestones: any;
}

function MyCampaign() {
  const { t } = useTranslation();
  const [campaign, setCampaign] = useState<Array<myCmpaignRow>>([]);
  const [page, setPage] = useState<number>(1);
  const [endPage, setEndPage] = useState<number>();
  const [loading, setloading] = useState<boolean>(false);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableBack, setDisableBack] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const deleteCampaignModalState = useAppSelector(
    (state) => state.modalReducer.deleteCampaignModal
  );
  const userslice = useAppSelector((state) => state.userReducer);

  const columns: GridColDef[] = [
    {
      field: "Title",
      headerName: "Title",
      minWidth: 150,
      sortable: false,
      flex: 1,
    },
    {
      field: "Goal",
      headerName: "Goal",
      minWidth: 150,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.Goal}{" "}
            <Avatar
              style={{
                width: "18px",
                height: "18px",
                marginLeft: "5px",
                borderRadius: 0,
              }}
              src={algo}
            />
          </>
        );
      },
    },
    {
      field: "FundRaised",
      headerName: "Fund Raised",
      sortable: false,
      minWidth: 110,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.FundRaised}{" "}
            <Avatar
              style={{
                width: "18px",
                height: "18px",
                marginLeft: "5px",
                borderRadius: 0,
              }}
              src={algo}
            />
          </>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      sortable: false,
      minWidth: 110,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Typography
            variant="h6"
            sx={{
              color:
                params.row.Status === "completed" ||
                params.row.Status === "active"
                  ? "#00FF00"
                  : params.row.Status === "pending"
                  ? "#FFA500"
                  : "#000",
              textTransform: "capitalize",
            }}
          >
            {params.row.Status}
          </Typography>
        );
      },
    },
    {
      field: "Deadline",
      headerName: "Deadline",
      sortable: false,
      minWidth: 140,
      flex: 1,
    },
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      minWidth: 110,
      flex: 1,
      renderCell: (params) => {
        const handleEdit = (e: any) => {
          e.stopPropagation();
          navigate(`/user/edit-campaign/${params.row.campaignId}`);
        };

        const handleDelete = (e: any) => {
          e.stopPropagation();
          const payload = {
            deleteCampaignModal: true,
            deleteCampaignId: params.row.campaignId,
            deleteCampaignAppId: params.row.deleteCampaignAppId,
            deleteCampaignNftId: params.row.deleteCampaignNftId,
            milestones: params.row.milestones,
          };
          dispatch(setDeleteCampaignModal(payload));
        };

        const handleView = (e: any) => {
          e.stopPropagation();
          navigate(`/user/my-campaign/${params.row.campaignId}`);
        };
        return (
          <Box>
            {params.row.Status === "pending" ? (
              <IconButton onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            ) : (
              true && (
                <IconButton onClick={handleView}>
                  <VisibilityIcon />
                </IconButton>
              )
            )}
            {params.row.Status !== "active" && (
              <IconButton onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];

  const fetchMyCampaigns = async () => {
    setloading(true);
    const data = {
      page: page,
      limit: 5,
      status: ["active", "completed", "pending"],
    };
    const res = await getMyCampaigns(data);
    setloading(false);
    if (res.data.totalPages === page) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }

    if (page === 1) {
      setDisableBack(true);
    } else {
      setDisableBack(false);
    }

    if (page === 1 && endPage === 1) {
      setDisableNext(true);
      setDisableBack(true);
    }
    setEndPage(res.data.totalPages);
    let tempCampaign: Array<myCmpaignRow> = [];

    res.data.campaigns?.map((campaign: any, index: number) => {
      const payload: myCmpaignRow = {
        Action: "Edit",
        id: index,
        Goal: `${campaign.goalAmount}`,
        FundRaised: campaign.collectedAmount
          ? `${campaign.collectedAmount}`
          : "0",
        Title: campaign.title,
        Deadline: moment(campaign.endDate).format("DD-MMM-YYYY"),
        Status: campaign.status,
        campaignId: campaign.campaignId,
        deleteCampaignAppId: campaign.campaignAppId,
        deleteCampaignNftId: campaign.reward ? campaign.reward : 0,
        milestones: campaign.milestones,
      };

      tempCampaign.push(payload);
    });

    setCampaign([...tempCampaign]);
  };

  const handleNext = () => {
    if (endPage && page < endPage) {
      setPage(page + 1);
    }
  };

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchMyCampaigns();
  }, [page]);

  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);

  useEffect(() => {
    if (!deleteCampaignModalState) {
      fetchMyCampaigns();
    }
  }, [deleteCampaignModalState]);

  return (
    <>
      <Box>
        <Box
          mb={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" align="left">
            {t("listOfAllCampaigns")}
          </Typography>
          {userslice.role === "creator" && (
            <Button
              onClick={() => navigate(`/user/create-campaign`)}
              variant="contained"
              color="primary"
              className="round-button"
              sx={{
                marginLeft: "10px",
                "& .btnText": {
                  display: "none",
                },
                "&:hover": {
                  "& .btnText": {
                    display: "block",
                  },
                },
              }}
            >
              <Typography className="btnText">
                {t("createCampaignOne")}
              </Typography>
              <AddIcon sx={{ color: "#000" }} />
            </Button>
          )}
        </Box>
        <Box sx={DataGridWrap}>
          <DataGrid
            rows={campaign}
            columns={columns}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            hideFooterPagination={true}
            hideFooter={true}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
          <Box sx={footer}>
            <p>
              {page} - {endPage}
            </p>
            <IconButton onClick={handleBack} disabled={disableBack}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton onClick={handleNext} disabled={disableNext}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MyCampaign;
