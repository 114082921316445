import React, { useState } from "react";
import EditDraftCampaign from "./EditDraftCampaign";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useWallet } from "@txnlab/use-wallet";
import { client, peraWallet } from "../../../wallets/algorand";
import {
  bindNftToCampaign,
  createCampaignBlockchain,
} from "../../../blockchain";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import algosdk from "algosdk";
import moment from "moment";
import {
  STAGING_FUNDING_CATEGORY,
  TEST_FUNDING_CATEGORY,
  PRODUCTION_FUNDING_CATEGORY,
} from "../createCampaign/constant";
import { createCampaign } from "../../../requests/authRequest";
import { useNavigate, useParams } from "react-router-dom";
import { InputFiles } from "typescript";
import EditDraftGoals from "./EditDraftGoals";
import EditDraftAdditionalInfo from "./EditDraftAdditionalInfo";
import { Backdrop, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

function DraftMainPage() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const { t } = useTranslation();
  const campaignState = useAppSelector((state) => state.campaignPreviewReducer);
  const [campaignSteps, setCampaignSteps] = React.useState<string>("Create");
  const [currentCampaignId, setCurrentCampaignId] = React.useState<string>("");
  const [preLoading, setPreLoading] = React.useState<boolean>(false);

  interface milestone {
    title: string;
    defination: string;
    deadline: Date | string;
    milestoneId: string;
    delivereble: any;
  }

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { campaignId } = useParams();

  let blockchainCampaignAppId = "";
  let milestoneOneAppId = "";
  let milestoneTwoAppId = "";

  // ---- create campaign -------

  const [title, setTitle] = useState<string>(campaignState.title);
  const [description, setDescription] = useState<string>(
    campaignState.description
  );
  const [youTubeLink, setyouTubeLink] = useState<string>(
    campaignState.youTubeLink
  );
  const [location, setLocation] = useState(campaignState.location);
  const [YearlyOutput, setYearlyOutput] = useState<number>(
    campaignState.YearlyOutput
  );
  const [Results, setResults] = useState<string>(campaignState.Results);
  console.log(campaignState.title, "howareu");

  const [CostPerUnit, setCostPerUnit] = useState<number>(
    campaignState.CostPerUnit
  );

  const [category, setCategory] = useState<string>(
    campaignState.campaignCategory
  );
  const [fundingCategory, setFundingCategory] = useState<string>(
    campaignState.fundingCategory
  );
  const [endDate, setEndDate] = React.useState<Date | string>(
    new Date(campaignState.endDate)
  );
  const [country, setCountry] = useState(campaignState.country);
  const [goalAmount, setGoalAmount] = useState<string>(
    campaignState.goalAmount
  );
  const [image, setImage] = useState<any | undefined>([]);
  const [video, setVideo] = useState<InputFiles | undefined>(
    campaignState.video
  );
  const [videoSelected, setVideoSelected] = useState<boolean>(
    campaignState.videoSelected
  );
  const selectedNft = useAppSelector(
    (state) => state.campaignPreviewReducer.rewardNftId
  );
  const rewardType = useAppSelector(
    (state) => state.campaignPreviewReducer.rewardType
  );

  const [ESG_SDG_status, setESG_SDG_status] = useState<
    boolean | undefined | string
  >("");
  const [monitoringStatus, setMonitoring_status] = useState<{
    title: string;
    status: boolean;
  }>({
    title: "",
    status: false,
  });
  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: campaignState.lat,
    lng: campaignState.lng,
  });
  const [isAdditionalInfo, setIsAdditionalInfo] = useState<
    boolean | string | undefined
  >("");

  const [localMilestoneData, setLocalMilestoneData] = useState(
    localStorage.getItem("milestones")
  );

  const [milestoneOne, setMilestoneOne] = useState<milestone>(
    !localMilestoneData
      ? {
          title: "",
          defination: "",
          deadline: "",
          milestoneId: "",
          delivereble: undefined,
        }
      : {
          title: JSON.parse(localMilestoneData).one.title,
          defination: JSON.parse(localMilestoneData).one.definition,
          deadline: JSON.parse(localMilestoneData).one.deadline,
          milestoneId: JSON.parse(localMilestoneData).one.milestoneId,
          delivereble: JSON.parse(localMilestoneData).one.deliverable,
        }
  );
  const [milestoneTwo, setMilestoneTwo] = useState<milestone>(
    !localMilestoneData
      ? {
          title: "",
          defination: "",
          deadline: "",
          milestoneId: "",
          delivereble: undefined,
        }
      : {
          title: JSON.parse(localMilestoneData).two.title,
          defination: JSON.parse(localMilestoneData).two.definition,
          deadline: JSON.parse(localMilestoneData).two.deadline,
          milestoneId: JSON.parse(localMilestoneData).two.milestoneId,
          delivereble: JSON.parse(localMilestoneData).two.deliverable,
        }
  );

  const [goalIds, setGoalIds] = React.useState<number[]>([]);
  const [additionalData, setAdditionalData] = React.useState<any>({
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    companyName: "",
    website: "",
    projectPitch: "",
    problems: "",
    previousSuccessfulProject: "",
    Hq: "",
    country: "",
    impact: "",
    partnership: "",
    yearOfEstablishment: "",
    stageDesc: "",
    infoImage: "",
    additionalInfo: "",
  });

  // const assignNftToCampaign = async () => {
  //   const myAlgoConnect = new MyAlgoConnect();

  //   if (!walletClient.connected()) {
  //     await walletClient.connect();
  //   }

  //   const assignInfo = {
  //     NFT_asset_id: selectedNft,
  //     campaign_app_id: blockchainCampaignAppId,
  //   };
  //   const unsignedAssignNftTxn = await bindNftToCampaign(assignInfo);

  //   if (!unsignedAssignNftTxn.success) {
  //     const toastPaylaod = {
  //       text: t("unableToBindNftWithCampaign"),
  //       success: false,
  //       active: true,
  //     };
  //     dispatch(setToaster(toastPaylaod));
  //     dispatch(setAppLoadingText(""));
  //     dispatch(toggleAppLoading(false));
  //     return {
  //       success: false,
  //     };
  //   }

  //   try {
  //     dispatch(setAppLoadingText(t("signingTransaction")));
  //     const signedTxns: any = await myAlgoConnect.signTransaction(
  //       [
  //         unsignedAssignNftTxn.data[0].txn,
  //         unsignedAssignNftTxn.data[1].txn,
  //         unsignedAssignNftTxn.data[2].txn,
  //       ].map((txn: any) => txn)
  //     );

  //     let signed = [];
  //     signed.push(signedTxns[0].blob);
  //     signed.push(signedTxns[1].blob);
  //     signed.push(signedTxns[2].blob);
  //     dispatch(setAppLoadingText(t("waitingForConfirmation")));
  //     let tx = await client.sendRawTransaction(signed).do();

  //     let confirmedTxn = await algosdk.waitForConfirmation(client, tx.txId, 4);

  //     dispatch(setAppLoadingText(""));
  //     return {
  //       success: true,
  //     };
  //   } catch (e: any) {
  //     const toastPaylaod = {
  //       text: e.message,
  //       success: false,
  //       active: true,
  //     };
  //     dispatch(setToaster(toastPaylaod));
  //     dispatch(setAppLoadingText(""));
  //     dispatch(toggleAppLoading(false));
  //     return {
  //       success: false,
  //     };
  //   }
  // };

  const assignNftToCampaign = async () => {
    const assignInfo = {
      NFT_asset_id: selectedNft,
      campaign_app_id: blockchainCampaignAppId,
    };
    const unsignedAssignNftTxn = await bindNftToCampaign(assignInfo);

    if (!unsignedAssignNftTxn.success) {
      const toastPaylaod = {
        text: t("unableToBindNftWithCampaign"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(setAppLoadingText(t("signingTransaction")));
      dispatch(toggleAppLoading(false));
      const transactionArr = unsignedAssignNftTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);

      dispatch(toggleAppLoading(true));
      let signed = [];
      signed.push(signedTxn[0]);
      signed.push(signedTxn[1]);
      signed.push(signedTxn[2]);
      dispatch(setAppLoadingText(t("waitingForConfirmation")));
      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );

      // dispatch(setAppLoadingText(""));
      return {
        success: true,
      };
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleCreateCampaignBlockchain = async () => {
    const campaignInfo = {
      creator_wallet_address: activeAccount?.address,
      title: title,
      CostPerUnit: CostPerUnit,
      YearlyOutput: YearlyOutput,
      Results: Results,
      isReforestationProject: monitoringStatus ? 1 : 0,
      location: location,
      lat: coordinates.lat,
      lng: coordinates.lng,
      youTubeLink: youTubeLink,
      // description: description,
      category: category,
      end_time: moment(new Date(endDate)).startOf("day").unix(),
      fund_category: fundingCategory,
      fund_limit: parseInt(goalAmount),
      reward_type:
        fundingCategory === TEST_FUNDING_CATEGORY ||
        fundingCategory === STAGING_FUNDING_CATEGORY ||
        fundingCategory === PRODUCTION_FUNDING_CATEGORY
          ? rewardType
          : "0",
      country: country,
      ESG: ESG_SDG_status ? 1 : 0,
      milestone: {
        milestone_title: {
          "1": milestoneOne.title,
          "2": milestoneTwo.title,
          // "3": milestoneThree.title,
        },
        milestone_number: { "1": 1, "2": 2 },
        end_time_milestone: {
          "1": moment(new Date(milestoneOne.deadline)).startOf("day").unix(),
          "2": moment(new Date(milestoneTwo.deadline)).startOf("day").unix(),
          // "3": moment(milestoneThree.deadline).unix(),
        },
      },
    };

    const unsignedTxn = await createCampaignBlockchain(campaignInfo);
    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: t("unableToSendDataToBlockchain"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(setAppLoadingText("signing transaction"));
      dispatch(toggleAppLoading(false));

      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      dispatch(toggleAppLoading(false));
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);
      dispatch(toggleAppLoading(true));
      const signed = [];
      signed.push(signedTxn[0]);
      signed.push(signedTxn[1]);
      signed.push(signedTxn[2]);
      // signed.push(signedTxns[3].blob);
      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );

      let milestoneOneDataBlockchain = await algosdk.waitForConfirmation(
        client,
        transactionArr[1].txn.txID(),
        4
      );
      let milestoneTwoDataBlockchain = await algosdk.waitForConfirmation(
        client,
        transactionArr[2].txn.txID(),
        4
      );
      // let milestoneThreeDataBlockchain = await algosdk.waitForConfirmation(
      // 	client,
      // transactionArr[3].txn.txID(),
      // 	4
      // );

      // dispatch(setAppLoadingText(""));

      blockchainCampaignAppId = confirmedTxn["application-index"];
      milestoneOneAppId = milestoneOneDataBlockchain["application-index"];
      milestoneTwoAppId = milestoneTwoDataBlockchain["application-index"];
      // milestoneThreeAppId = milestoneThreeDataBlockchain["application-index"];

      if (
        (fundingCategory === TEST_FUNDING_CATEGORY ||
          fundingCategory === STAGING_FUNDING_CATEGORY ||
          fundingCategory === PRODUCTION_FUNDING_CATEGORY) &&
        rewardType === "NFTs" &&
        blockchainCampaignAppId
      ) {
        // i need to bind a nft here
        const bindingRes = await assignNftToCampaign();
        if (bindingRes.success) {
          return {
            success: true,
          };
        } else {
          return {
            success: false,
          };
        }
      } else {
        return {
          success: true,
        };
      }
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleCreateCampaign = async () => {
    setPreLoading(true);
    const blockchainRes = await handleCreateCampaignBlockchain();
    if (!blockchainRes.success) {
      setPreLoading(false);
      return;
    }

    let payload: any = {};

    if (
      fundingCategory === TEST_FUNDING_CATEGORY ||
      fundingCategory === STAGING_FUNDING_CATEGORY ||
      fundingCategory === PRODUCTION_FUNDING_CATEGORY
    ) {
      payload = {
        campaignId: campaignId,
        // title: title,
        // description: description,
        // category: category,
        // fundingCategory: fundingCategory,
        // endDate: moment(endDate).format("YYYY-MM-DD"),
        // goalAmount: goalAmount,
        // country: country,
        // images: images,
        // video: video,
        // videoUrl: "https://youtu.be/ouncVBiye_M",
        rewardBased: true,
        rewardType: rewardType,
        reward: selectedNft,
        campaignAppId: blockchainCampaignAppId,
        milestones: [
          {
            title: milestoneOne.title,
            definition: milestoneOne.defination,
            deadline: milestoneOne.deadline,
            milestoneId: milestoneOne.milestoneId,
            deliverable: "non",
            milestoneAppId: milestoneOneAppId,
          },
          {
            title: milestoneTwo.title,
            definition: milestoneTwo.defination,
            deadline: milestoneTwo.deadline,
            milestoneId: milestoneTwo.milestoneId,
            deliverable: "non",
            milestoneAppId: milestoneTwoAppId,
          },
          // {
          // 	title: milestoneThree.title,
          // 	definition: milestoneThree.defination,
          // 	deadline: milestoneThree.deadline,
          // 	deliverable: "non",
          // 	milestoneAppId: milestoneThreeAppId,
          // },
        ],
      };
    } else {
      payload = {
        campaignId: campaignId,
        // title: title,
        // description: description,
        // category: category,
        // fundingCategory: fundingCategory,
        // endDate: moment(endDate).format("YYYY-MM-DD"),
        // goalAmount: goalAmount,
        // country: country,
        // images: images,
        // video: video,
        // videoUrl: "https://youtu.be/ouncVBiye_M",
        campaignAppId: blockchainCampaignAppId,
        milestones: [
          {
            title: milestoneOne.title,
            definition: milestoneOne.defination,
            deadline: milestoneOne.deadline,
            milestoneId: milestoneOne.milestoneId,
            deliverable: "anything",
            milestoneAppId: milestoneOneAppId,
          },
          {
            title: milestoneTwo.title,
            definition: milestoneTwo.defination,
            deadline: milestoneTwo.deadline,
            milestoneId: milestoneTwo.milestoneId,
            deliverable: "anything",
            milestoneAppId: milestoneTwoAppId,
          },
          // {
          // 	title: milestoneThree.title,
          // 	definition: milestoneThree.defination,
          // 	deadline: milestoneThree.deadline,
          // 	deliverable: "anything",
          // 	milestoneAppId: milestoneThreeAppId,
          // },
        ],
      };
    }

    const res = await createCampaign(payload);
    setPreLoading(false);
    if (res.success) {
      const toastPaylaod = {
        text: t("campaignCreatedSuccessfully"),
        success: true,
        active: true,
      };
      setPreLoading(false);
      dispatch(setToaster(toastPaylaod));

      navigate("/user/my-campaign");
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      setPreLoading(false);
      dispatch(setToaster(toastPaylaod));
    }
    setPreLoading(false);
    localStorage.removeItem("milestones");
  };

  // ---- create campaign -------

  return (
    <>
      {campaignSteps === "Create" ? (
        <EditDraftCampaign
          handleSteps={() => setCampaignSteps("Goal")}
          CostPerUnit={CostPerUnit}
          YearlyOutput={YearlyOutput}
          Results={Results}
          handleCostPerUnit={(vl: number) => setCostPerUnit(vl)}
          handleYearlyOutput={(vl: number) => setYearlyOutput(vl)}
          handleResults={(vl: string) => setResults(vl)}
          // location={location}
          // handleLocation={(vl: any) => setLocation(vl)}
          monitoringStatus={monitoringStatus}
          handleMonitoringStatus={(vl: any) => setMonitoring_status(vl)}
          currentCampaignId={currentCampaignId}
          handleCurrentCampaignId={(vl: string) => setCurrentCampaignId(vl)}
          title={title}
          handleTitle={(vl: string) => setTitle(vl)}
          description={description}
          handleDescription={(vl: string) => setDescription(vl)}
          category={category}
          handleCategory={(vl: string) => setCategory(vl)}
          fundingCategory={fundingCategory}
          handleFundingCategory={(vl: string) => setFundingCategory(vl)}
          endDate={endDate}
          handleEndDate={(vl: Date | string) => setEndDate(vl)}
          country={country}
          handleCountry={(vl: any) => setCountry(vl)}
          goalAmount={goalAmount}
          handleGoalAmount={(vl: string) => setGoalAmount(vl)}
          image={image}
          handleImage={(vl: any | undefined) => setImage(vl)}
          youTubeLink={youTubeLink}
          handleYoutubelink={(vl: string) => setyouTubeLink(vl)}
          video={video}
          handleVideo={(vl: any | undefined) => setVideo(vl)}
          videoSelected={videoSelected}
          handleVideoSelected={(vl: boolean) => setVideoSelected(vl)}
          milestoneOne={milestoneOne}
          handleMilestoneOne={(vl: milestone) => setMilestoneOne(vl)}
          milestoneTwo={milestoneTwo}
          handleMilestoneTwo={(vl: milestone) => setMilestoneTwo(vl)}
          ESG_SDG_status={ESG_SDG_status}
          handleESG_SDG_status={(vl: any) => setESG_SDG_status(vl)}
          handleCampaignCreate={() => handleCreateCampaign()}
          // coordinates={coordinates}
          // setCoordinates={setCoordinates}
        />
      ) : campaignSteps === "Additional" ? (
        <EditDraftAdditionalInfo
          handleSteps={() => setCampaignSteps("Goal")}
          currentCampaignId={currentCampaignId}
          handleCampaignCreate={() => handleCreateCampaign()}
        />
      ) : campaignSteps === "Goal" ? (
        <EditDraftGoals
          handleSteps={(vl: string) => setCampaignSteps(vl)}
          currentCampaignId={currentCampaignId}
          handleCampaignCreate={() => handleCreateCampaign()}
          goalIds={goalIds}
          isAdditionalInfo={isAdditionalInfo}
          handleIsAdditionalInfo={(vl: any) => setIsAdditionalInfo(vl)}
          handleGoalIds={(item: any) =>
            goalIds.some((el) => el === item?.goalId)
              ? setGoalIds(goalIds.filter((el) => el !== item?.goalId))
              : setGoalIds((prev) => [...prev, item?.goalId])
          }
        />
      ) : (
        <EditDraftCampaign
          handleSteps={() => setCampaignSteps("Goal")}
          CostPerUnit={CostPerUnit}
          YearlyOutput={YearlyOutput}
          Results={Results}
          handleCostPerUnit={(vl: number) => setCostPerUnit(vl)}
          handleYearlyOutput={(vl: number) => setYearlyOutput(vl)}
          handleResults={(vl: string) => setResults(vl)}
          // location={location}
          // handleLocation={(vl: any) => setLocation(vl)}
          monitoringStatus={monitoringStatus}
          handleMonitoringStatus={(vl: any) => setMonitoring_status(vl)}
          currentCampaignId={currentCampaignId}
          handleCurrentCampaignId={(vl: string) => setCurrentCampaignId(vl)}
          title={title}
          handleTitle={(vl: string) => setTitle(vl)}
          description={description}
          handleDescription={(vl: string) => setDescription(vl)}
          category={category}
          handleCategory={(vl: string) => setCategory(vl)}
          fundingCategory={fundingCategory}
          handleFundingCategory={(vl: string) => setFundingCategory(vl)}
          endDate={endDate}
          handleEndDate={(vl: Date | string) => setEndDate(vl)}
          country={country}
          handleCountry={(vl: any) => setCountry(vl)}
          goalAmount={goalAmount}
          handleGoalAmount={(vl: string) => setGoalAmount(vl)}
          image={image}
          handleImage={(vl: any | undefined) => setImage(vl)}
          youTubeLink={youTubeLink}
          handleYoutubelink={(vl: string) => setyouTubeLink(vl)}
          video={video}
          handleVideo={(vl: any | undefined) => setVideo(vl)}
          videoSelected={videoSelected}
          handleVideoSelected={(vl: boolean) => setVideoSelected(vl)}
          milestoneOne={milestoneOne}
          handleMilestoneOne={(vl: milestone) => setMilestoneOne(vl)}
          milestoneTwo={milestoneTwo}
          handleMilestoneTwo={(vl: milestone) => setMilestoneTwo(vl)}
          ESG_SDG_status={ESG_SDG_status}
          handleESG_SDG_status={(vl: any) => setESG_SDG_status(vl)}
          handleCampaignCreate={() => handleCreateCampaign()}
          // coordinates={coordinates}
          // setCoordinates={setCoordinates}
        />
      )}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: "99999999999999",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          flexDirection: "column",
        }}
        open={preLoading}
      >
        <CircularProgress color="inherit" />
        <h3>{t("creatingCampaign")}</h3>
      </Backdrop>
    </>
  );
}

export default DraftMainPage;
