import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import notifAndLoadingReducer from "./slices/LoadinAndNotifSlice";
import modalReducer from "./slices/ModalSlice";
import userReducer from "./slices/UserSlice";
import campaignPreviewReducer from "./slices/CreateCampaignSlice";
import socketReducer from "./slices/SocketSlice";
import chatReducer from "./slices/ChatSlice";
import walletReducer from "./slices/WalletSlice";
import managerPreviewReducer from "./slices/CreateManagerSlice";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createTransform } from "redux-persist";
import JSOG from "jsog";

export const JSOGTransform = createTransform(
  (inboundState, key) => JSOG.encode(inboundState),
  (outboundState, key) => JSOG.decode(outboundState)
);

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  // transforms: [JSOGTransform],
  blacklist: ["socketReducer", "chatReducer"],
};
const rootReducer = combineReducers({
  notifAndLoadingReducer,
  modalReducer,
  userReducer,
  campaignPreviewReducer,
  socketReducer,
  chatReducer,
  walletReducer,
  managerPreviewReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
