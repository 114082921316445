import React from "react";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Menu,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import {
  getAllGoals,
  getEditCampaignDetails,
  saveToDraft,
  updateDraft,
} from "../../../requests/authRequest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Wallets from "../../../wallets";

function EditDraftGoals({
  handleSteps,
  currentCampaignId,
  handleCampaignCreate,
  goalIds,
  isAdditionalInfo,
  handleIsAdditionalInfo,
  handleGoalIds,
}: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const [matchFundStatus, setMatchFundStatus] = React.useState<
    boolean | undefined | string
  >("");

  const kyc = useAppSelector((state) => state.userReducer.kyc);
  const [allGoals, setAllGoals] = React.useState<
    { category: string; goalId: string; goalName: string; status: string }[]
  >([]);

  const [campaignInitialData, setCampaignInitialData] = React.useState<any>({});

  const dispatch = useAppDispatch();

  /**
   *
   * menu
   */

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   * menu
   */

  /**
   *
   * menu2
   */

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  /**
   *
   * menu
   */

  /**
   * handle save to draft function
   */

  const handleSaveToDraft = async (
    nextStatus: boolean,
    submitStatus: boolean
  ) => {
    if (matchFundStatus === "" || matchFundStatus === undefined) {
      const toastPaylaod = {
        text: t("pleaseSelectDoYouWantToRecieveMatchFundingOrNot"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return;
    }

    if (goalIds.length === 0 || goalIds.length > 6) {
      const toastPaylaod = {
        text: t("pleaseSelectAtleastOneGoalOrMaxSixGoals"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return;
    }

    if (isAdditionalInfo === "" || isAdditionalInfo === undefined) {
      const toastPaylaod = {
        text: t("pleaseSelectDoYouWantToShareMoreInfoOrNot"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return;
    }

    dispatch(toggleAppLoading(true));
    dispatch(setAppLoadingText(t("updatingDraft")));

    let payload: any = {};

    if (campaignId === "") {
      dispatch(toggleAppLoading(false));
      return;
    } else {
      payload = {
        campaignId: campaignId,
        campaign: {
          title: campaignInitialData?.title,
          description: campaignInitialData?.description,
          category: campaignInitialData?.category?.categoryId,
          fundingCategory:
            campaignInitialData?.fundingCategory?.fundingCategoryId,
          endDate: campaignInitialData?.endDate,
          goalAmount: campaignInitialData?.goalAmount,
          country: campaignInitialData?.country,
          images: campaignInitialData?.images,
          video: campaignInitialData?.video,
          videoUrl: "",
          milestones: [
            {
              milestoneId: campaignInitialData?.milestones[0]?.milestoneId,
              title: campaignInitialData?.milestones[0].title
                ? campaignInitialData?.milestones[0].title
                : "",
              definition: campaignInitialData?.milestones[0].defination
                ? campaignInitialData?.milestones[0].defination
                : "",
              deadline: campaignInitialData?.milestones[0].deadline
                ? campaignInitialData?.milestones[0].deadline
                : "",
              deliverable: "non",
              milestoneAppId:
                campaignInitialData?.milestones[0]?.milestoneAppId,
            },
            {
              milestoneId: campaignInitialData?.milestones[1]?.milestoneId,
              title: campaignInitialData?.milestones[1].title
                ? campaignInitialData?.milestones[1].title
                : "",
              definition: campaignInitialData?.milestones[1].defination
                ? campaignInitialData?.milestones[1].defination
                : "",
              deadline: campaignInitialData?.milestones[1].deadline
                ? campaignInitialData?.milestones[1].deadline
                : "",
              deliverable: "non",
              milestoneAppId:
                campaignInitialData?.milestones[1]?.milestoneAppId,
            },
          ],
          matchFunding: matchFundStatus,
          goalIds: goalIds,
          rewardBased: campaignInitialData?.rewardBased,
          reward: campaignInitialData?.reward,
          rewardType: campaignInitialData?.rewardType,
        },
        isEsgEnabled: campaignInitialData?.isEsgEnabled,
        additionalInfo: isAdditionalInfo,
        additionalInfoData: {
          additionalInfoId:
            campaignInitialData?.additionalInfoId?.additionalInfoId,
          consent: campaignInitialData?.additionalInfoId?.consent,
          contactName: campaignInitialData?.additionalInfoId?.contactName,
          contactEmail: campaignInitialData?.additionalInfoId?.contactEmail,
          contactPhone: campaignInitialData?.additionalInfoId?.contactPhone,
          companyOrProjectName:
            campaignInitialData?.additionalInfoId?.companyOrProjectName,
          website: campaignInitialData?.additionalInfoId?.website,
          projectPitch: campaignInitialData?.additionalInfoId?.projectPitch,
          problems: campaignInitialData?.additionalInfoId?.problems,
          previousSuccessfulProjects:
            campaignInitialData?.additionalInfoId?.previousSuccessfulProjects,
          hq: campaignInitialData?.additionalInfoId?.hq,
          impact: campaignInitialData?.additionalInfoId?.impact,
          partnerships: campaignInitialData?.additionalInfoId?.partnerships,
          yearOfEstablishment:
            campaignInitialData?.additionalInfoId?.yearOfEstablishment,
          image: campaignInitialData?.additionalInfoId?.image,
          companyBio: campaignInitialData?.additionalInfoId?.companyBio,
          reachoutRegardingSubmission:
            campaignInitialData?.additionalInfoId?.reachoutRegardingSubmission,
          geographies: campaignInitialData?.additionalInfoId?.geographies,
          additionalInformation:
            campaignInitialData?.additionalInfoId?.additionalInformation,
        },
      };
    }

    const res = await updateDraft(payload);

    if (res.success) {
      const toastPaylaod = {
        text: t("updatedDraftSuccessfully"),
        success: true,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setAppLoadingText(""));

      dispatch(setToaster(toastPaylaod));
      nextStatus && handleSteps("Additional");
      if (submitStatus) {
        dispatch(toggleAppLoading(true));
        dispatch(setAppLoadingText(t("creatingCampaign")));
        handleCampaignCreate();
      }
      // cleanFormState();
      // navigationStatus && navigate("/user/my-campaign");
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setAppLoadingText(""));

      dispatch(setToaster(toastPaylaod));
      dispatch(toggleAppLoading(false));
    }
    dispatch(toggleAppLoading(false));
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(false));
    localStorage.removeItem("milestones");
  };

  /**
   * handle save to draft function end
   */

  const fetchGoals = async () => {
    const res = await getAllGoals();
    setAllGoals(res?.data?.goals);
  };

  const fetchInitialValues = async () => {
    dispatch(toggleAppLoading(true));
    const res = await getEditCampaignDetails(campaignId);
    dispatch(toggleAppLoading(false));
    if (res.success) {
      res.data?.campaign?.goalIds?.map((item: any) => {
        handleGoalIds(item);
      });
      handleIsAdditionalInfo(res.data.campaign.additionalInfo);
      setMatchFundStatus(res.data.campaign.matchFunding);
      setCampaignInitialData(res.data.campaign);
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      navigate(-1);
    }
  };

  React.useEffect(() => {
    fetchGoals();
    fetchInitialValues();
  }, []);

  return (
    <div>
      <Typography style={{ textAlign: "left", fontSize: "2rem" }} variant="h3">
        {t("chooseYourCampaignsEsgOrSdgGoals")}
      </Typography>
      <Box sx={{ p: 2, mt: 2 }} style={{ textAlign: "left" }}>
        <Grid>
          <Box sx={{ mt: 2 }} style={{ textAlign: "left" }}>
            <Typography
              style={{ textAlign: "left", fontSize: "1rem" }}
              variant="h5"
            >
              {t("pleaseChooseAtleastOneGoalAndUptoSixGoals")}
            </Typography>

            <Box sx={{ marginTop: "20px" }}>
              <InputLabel id="demo-simple-select-helper-label">
                {t("doYouWantToReceiveMatchFunding")}
              </InputLabel>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ color: "#000", border: "1px solid gray" }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {matchFundStatus === "" || matchFundStatus === undefined
                  ? t("pleaseSelectOption")
                  : matchFundStatus === true
                  ? t("yes")
                  : t("no")}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <Box sx={{ width: "200px" }}>
                  <MenuItem
                    onClick={() => {
                      setMatchFundStatus(true);
                      handleClose();
                    }}
                  >
                    {t("yes")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setMatchFundStatus(false);
                      handleClose();
                    }}
                  >
                    {t("no")}
                  </MenuItem>
                </Box>
              </Menu>
            </Box>
            {/* {invalidEsgOption && (
          <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
            {errorInvalidEsgTitle}
          </FormHelperText>
        )} */}
            <FormHelperText>
              {t("thisWillIncreaseYourChancesOfReceivingAdditionalFunding")}
            </FormHelperText>
          </Box>
        </Grid>
        <Typography variant="h3" align="left" mt={3} mb={3}>
          {t("environmental")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          {allGoals
            ?.filter((el) => el?.category === "environmental")
            ?.map((item: any) => (
              <FormControlLabel
                sx={{ width: "30%" }}
                key={item?.goalId}
                control={
                  <Checkbox
                    checked={goalIds.some((el: any) => el === item?.goalId)}
                    onChange={() => handleGoalIds(item)}
                    name={item?.goalName}
                    color="primary"
                  />
                }
                label={item?.goalName}
              />
            ))}
        </Box>
        <Typography variant="h3" align="left" mt={3} mb={3}>
          {t("social")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          {allGoals
            ?.filter((el) => el?.category === "social")
            ?.map((item: any) => (
              <FormControlLabel
                sx={{ width: "30%" }}
                key={item?.goalId}
                control={
                  <Checkbox
                    checked={goalIds.some((el: any) => el === item?.goalId)}
                    onChange={() => handleGoalIds(item)}
                    name={item?.goalName}
                    color="primary"
                  />
                }
                label={item?.goalName}
              />
            ))}
        </Box>
        <Typography variant="h3" align="left" mt={3} mb={3}>
          {t("governance")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          {allGoals
            ?.filter((el) => el?.category === "governance")
            ?.map((item: any) => (
              <FormControlLabel
                sx={{ width: "30%" }}
                key={item?.goalId}
                control={
                  <Checkbox
                    checked={goalIds.some((el: any) => el === item?.goalId)}
                    onChange={() => handleGoalIds(item)}
                    name={item?.goalName}
                    color="primary"
                  />
                }
                label={item?.goalName}
              />
            ))}
        </Box>
      </Box>

      <Box sx={{ p: 2, mt: 2 }} style={{ textAlign: "left" }}>
        <Typography
          style={{ textAlign: "left", fontSize: "1rem" }}
          variant="h5"
        >
          {t("doYouWantToShareMoreInformationAboutYourCampaignWithUs")}
        </Typography>
        <Box sx={{ marginTop: "20px" }}>
          <InputLabel id="demo-simple-select-helper-label">
            {t("pleaseSelectOption")}
          </InputLabel>
          <Button
            id="basic-button2"
            aria-controls={open2 ? "basic-menu2" : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? "true" : undefined}
            onClick={handleClick2}
            sx={{ color: "#000", border: "1px solid gray" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {isAdditionalInfo === "" || isAdditionalInfo === undefined
              ? t("pleaseSelectOption")
              : isAdditionalInfo === true
              ? t("yes")
              : t("no")}
          </Button>
          <Menu
            id="basic-menu2"
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button2",
            }}
          >
            <Box sx={{ width: "200px" }}>
              <MenuItem
                onClick={() => {
                  handleIsAdditionalInfo(true);
                  handleClose2();
                }}
              >
                {t("yes")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleIsAdditionalInfo(false);
                  handleClose2();
                }}
              >
                {t("no")}
              </MenuItem>
            </Box>
          </Menu>
        </Box>
        {/* {invalidEsgOption && (
          <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
            {errorInvalidEsgTitle}
          </FormHelperText>
        )} */}
        <FormHelperText>
          {t("thisWillIncreaseYourChancesOfReceivingAdditionalFunding")}
        </FormHelperText>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "60px",
          marginTop: 3,
          marginBottom: 3,
        }}
      >
        <Button
          variant="contained"
          color="error"
          className="round-button"
          onClick={() => handleSteps("Create")}
        >
          {t("back")}
        </Button>

        <Button
          variant="contained"
          color="primary"
          className="round-button"
          onClick={() => handleSaveToDraft(false, false)}
        >
          {t("saveToDraft")}
        </Button>
        {kyc === "approved" && isAdditionalInfo === false && (
          <>
            <Button
              variant="contained"
              color="primary"
              className="round-button"
              onClick={() => setShowWalletButtons(true)}
            >
              {t("submit")}
            </Button>
            {showWalletButtons && (
              <Wallets
                open={showWalletButtons}
                handleClose={() => setShowWalletButtons(false)}
                handleSubmit={() => handleSaveToDraft(false, true)}
              />
            )}
          </>
        )}

        {isAdditionalInfo === true && (
          <Button
            variant="contained"
            color="primary"
            className="round-button"
            onClick={() => {
              handleSaveToDraft(true, false);
            }}
          >
            {t("next")}
          </Button>
        )}
      </Box>
    </div>
  );
}
export default EditDraftGoals;
