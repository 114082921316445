import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getEditCampaignDetails } from "../../../requests/authRequest";
import { useAppDispatch } from "../../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import moment from "moment";
import {
  checkInitialFundedPayment,
  checkInitialPaymentClaimed,
  claimMilestoenOneMoney,
} from "../../../blockchain";
import Wallets from "../../../wallets";
import { useWallet } from "@txnlab/use-wallet";
import { client, peraWallet } from "../../../wallets/algorand";
import algosdk from "algosdk";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import algo from "../../../assets/images/algo.png";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";

const URL = process.env.REACT_APP_URL;
var getYouTubeID = require("get-youtube-id");
const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function ViewMyCampaign() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const { t } = useTranslation();
  const [campaignData, setCampaignData] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { campaignId } = useParams();
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const [milestoneDisablity, setMilestoneDisablity] = useState({
    milestoneOne: true,
    milestoneTwo: true,
    // milestoneThree: true,
  });
  const [disableClaimInitialPayment, setDisableClaimInitialPayment] =
    useState<boolean>(true);
  const [checkInitialFund, setCheckInitialFund] = useState<boolean>(false);

  const handleDisableClaimInitialPayment = async () => {
    if (campaignData.status === "completed") {
      const info = {
        campaignAppId: campaignData.campaignAppId,
      };
      const res = await checkInitialPaymentClaimed(info);

      if (res.success) {
        if (res.data.initial_payment_claimed === "FALSE") {
          setDisableClaimInitialPayment(false);
        }
      }
    }
  };
  const handleCheckInitialFund = async () => {
    if (campaignData.status === "completed") {
      const info = {
        campaignAppId: campaignData.campaignAppId,
      };
      const res = await checkInitialFundedPayment(info);

      if (res.success) {
        let data = res.data.totalInvested;
        if (data > 0) {
          setCheckInitialFund(true);
        }
      }
    }
  };

  const fetchInitialValues = async () => {
    dispatch(toggleAppLoading(true));
    const res = await getEditCampaignDetails(campaignId);
    dispatch(toggleAppLoading(false));
    if (res.success) {
      setCampaignData(res.data.campaign);
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      navigate(-1);
    }
  };

  const handleGoBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate("/user/my-campaign");
  };

  useEffect(() => {
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(false));
    fetchInitialValues();
  }, []);

  //   const handleStartMilestoneOne = async () => {
  //     const myAlgoConnect = new MyAlgoConnect();

  //     if (!walletClient.connected()) {
  //       await walletClient.connect();
  //     }
  //     const info = {
  //       campaign_app_id: campaignData.campaignAppId,
  //       milestone_app_id: campaignData.milestones[0].milestoneAppId,
  //       milestone_title: campaignData.milestones[0].title,
  //       milestone_number: 1,
  //     };
  //     dispatch(toggleAppLoading(true));
  //     const unsignedTxn = await startMilestoneOne(info);
  //     if (!unsignedTxn.success) {
  //       const toastPaylaod = {
  //         text: "Unable to send data to blockchain",
  //         success: false,
  //         active: true,
  //       };
  //       dispatch(setToaster(toastPaylaod));
  //       dispatch(setAppLoadingText(""));
  //       dispatch(toggleAppLoading(false));
  //       return {
  //         success: false,
  //       };
  //     }

  //     try {
  //       dispatch(setAppLoadingText("signing transaction"));

  //       const signedTxns: any = await myAlgoConnect.signTransaction(
  //         unsignedTxn.data.map((txn: any) => txn.txn)
  //       );
  //       const signed = [];
  //       signed.push(signedTxns[0].blob);
  //       signed.push(signedTxns[1].blob);

  //       let tx = await client.sendRawTransaction(signed).do();
  //       dispatch(setAppLoadingText("waiting for transaction confirmation"));
  //       let confirmedTxn = await algosdk.waitForConfirmation(client, tx.txId, 4);
  //       dispatch(setAppLoadingText(""));
  //       dispatch(toggleAppLoading(false));
  //       return {
  //         success: true,
  //       };
  //     } catch (e) {
  //       const toastPaylaod = {
  //         text: "Unable to send data to blockchain",
  //         success: false,
  //         active: true,
  //       };
  //       dispatch(setToaster(toastPaylaod));
  //       dispatch(setAppLoadingText(""));
  //       dispatch(toggleAppLoading(false));
  //       return {
  //         success: false,
  //       };
  //     }
  //   };

  // const handleCalimMilestoneOneMoney = async () => {
  //   const myAlgoConnect = new MyAlgoConnect();
  //   dispatch(toggleAppLoading(true));
  //   if (!walletClient.connected()) {
  //     await walletClient.connect();
  //   }

  //   const info = {
  //     campaign_app_id: campaignData.campaignAppId,
  //     creator_wallet_address: walletClient.getDefaultAccount(),
  //   };

  //   const unsignedTxn = await claimMilestoenOneMoney(info);

  //   if (!unsignedTxn.success) {
  //     const toastPaylaod = {
  //       text: t("initialPaymentIsAlreadyClaimed"),
  //       success: false,
  //       active: true,
  //     };
  //     dispatch(setToaster(toastPaylaod));
  //     dispatch(setAppLoadingText(""));
  //     dispatch(toggleAppLoading(false));
  //     return {
  //       success: false,
  //     };
  //   }

  //   try {
  //     const txnId = await myAlgoConnect.signTransaction(
  //       unsignedTxn.data[0].txn
  //     );

  //     // Submit the transaction
  //     await client.sendRawTransaction(txnId.blob).do();

  //     // Wait for confirmation
  //     dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
  //     let confirmedTxn = await algosdk.waitForConfirmation(
  //       client,
  //       txnId.txID,
  //       4
  //     );
  //     const toastPaylaod = {
  //       text: t("initialPaymentClaimSuccessful"),
  //       success: true,
  //       active: true,
  //     };
  //     dispatch(toggleAppLoading(false));
  //     dispatch(setToaster(toastPaylaod));
  //     dispatch(setAppLoadingText(""));
  //     setDisableClaimInitialPayment(true);
  //     return;
  //   } catch (e: any) {
  //     const toastPaylaod = {
  //       text: e.message,
  //       success: false,
  //       active: true,
  //     };
  //     dispatch(setToaster(toastPaylaod));
  //     dispatch(setAppLoadingText(""));
  //     dispatch(toggleAppLoading(false));
  //   }
  // };
  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };
  const handleCalimMilestoneOneMoney = async () => {
    dispatch(toggleAppLoading(true));

    const info = {
      campaign_app_id: campaignData.campaignAppId,
      creator_wallet_address: activeAccount?.address,
    };

    const unsignedTxn = await claimMilestoenOneMoney(info);

    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: t("initialPaymentIsAlreadyClaimed"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      dispatch(toggleAppLoading(false));
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);

      // Submit the transaction
      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      // Wait for confirmation
      dispatch(setAppLoadingText(t("waitingForTransactionConfirmation")));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );
      const toastPaylaod = {
        text: t("initialPaymentClaimSuccessful"),
        success: true,
        active: true,
      };
      dispatch(toggleAppLoading(false));
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      setDisableClaimInitialPayment(true);
      return;
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
    }
  };

  const handleSubmitMilestone = async (key: number) => {
    if (key === 0) {
      // const res = await handleStartMilestoneOne();
      // if (res.success) {
      navigate(
        `/user/my-campaign/${campaignId}/submit-report/${campaignData.milestones[0].milestoneId}`
      );
      // }
    } else {
      navigate(
        `/user/my-campaign/${campaignId}/submit-report/${campaignData.milestones[key].milestoneId}`
      );
    }
  };
  // MILESTONE_STATUS = ["pending", "in-progress", "completed", "rejected", "emergency", "expired"];
  // useEffect to handle active state of submit report buttons
  useEffect(() => {
    if (campaignData && campaignData.endDate) {
      // to set the active status of submit campaign one button
      if (
        campaignData.status === "completed" &&
        campaignData.milestones[0].status === "in-progress"
      ) {
        if (!disableClaimInitialPayment) {
          setMilestoneDisablity({
            milestoneOne: true,
            milestoneTwo: true,
          });
        } else {
          setMilestoneDisablity({
            milestoneOne: false,
            milestoneTwo: true,
          });
        }
      }
      if (
        campaignData.status === "completed" &&
        campaignData.milestones[0].status === "emergency" &&
        moment(campaignData.milestones[0].deadline).add(3, "months") >
          moment() &&
        disableClaimInitialPayment
      ) {
        setMilestoneDisablity({
          ...milestoneDisablity,
          milestoneOne: false,
        });
      }

      // to set the active status of submit campaign two button
      if (campaignData.milestones[0].status !== "in-progress") {
        if (campaignData.milestones[1].status === "in-progress") {
          setMilestoneDisablity({
            ...milestoneDisablity,
            milestoneTwo: false,
          });
        }
        if (
          campaignData.milestones[1].status === "emergency" &&
          moment(campaignData.milestones[0].deadline).add(3, "months") >
            moment()
        ) {
          setMilestoneDisablity({
            ...milestoneDisablity,
            milestoneTwo: false,
          });
        }
      }

      // to set the active status of submit campaign three button
      // if (
      // 	campaignData.milestones[1].status !== "in-progress" &&
      // 	campaignData.milestones[2].status === "in-progress"
      // ) {
      // 	setMilestoneDisablity({
      // 		...milestoneDisablity,
      // 		milestoneThree: false,
      // 	});
      // }

      handleDisableClaimInitialPayment();
      handleCheckInitialFund();
    }
  }, [campaignData, disableClaimInitialPayment]);

  return (
    <>
      {campaignData ? (
        <Box mb={3}>
          <Typography variant="h4" align="left" mb={4}>
            {t("viewCampaignInfo")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={4} md={6}>
              <Stack spacing={2}>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("title")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.title}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <div
                    style={{
                      minHeight: "50%",
                      fontSize: "20px",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                  >
                    <Typography variant="h5" fontWeight={"600"} align="left">
                      {t("description")}
                    </Typography>
                    <Typography variant="h6" align="left">
                      {campaignData.description}
                    </Typography>
                  </div>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("status")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.status}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("endDate")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {moment(campaignData.endDate).format("MMM DD YYYY")}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("country")}
                  </Typography>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{ textTransform: "capitalize" }}
                  >
                    {campaignData.country}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("category")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.category.title}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("goalAmount")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.goalAmount}{" "}
                    <img
                      style={{ width: "16px", marginBottom: "-3px" }}
                      src={algo}
                    ></img>
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("isCampaignaReforestationProject")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.isReforestationProject ? "Yes" : "No"}
                  </Typography>
                </Box>
                {campaignData.isReforestationProject === true ? (
                  <>
                    <Box mb={1}>
                      <Typography variant="h5" fontWeight={"600"} align="left">
                        {t("yearlyOutput")}
                      </Typography>
                      <Typography variant="h6" align="left">
                        {campaignData.expectedYearlyoutput}
                      </Typography>
                    </Box>
                    <Box mb={1}>
                      <Typography variant="h5" fontWeight={"600"} align="left">
                        {t("costPerUnit($)")}
                      </Typography>
                      <Typography variant="h6" align="left">
                        {campaignData.expectedCostPerUnit}
                      </Typography>
                    </Box>
                    <Box mb={1}>
                      <Typography variant="h5" fontWeight={"600"} align="left">
                        {t("location")}
                      </Typography>
                      <Typography variant="h6" align="left">
                        <Typography variant="h6" align="left">
                          {campaignData.location ||
                            campaignData.lat + ", " + campaignData.lng}
                        </Typography>
                      </Typography>
                    </Box>
                    <Box mb={1}>
                      <Typography variant="h5" fontWeight={"600"} align="left">
                        {t("expectedResults")}
                      </Typography>
                      <div
                        style={{
                          minHeight: "50%",
                          fontSize: "20px",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }}
                      >
                        <Typography variant="h6" align="left">
                          {campaignData.expectedResults}
                        </Typography>
                      </div>
                    </Box>
                  </>
                ) : (
                  ""
                )}

                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    {t("fundingCategory")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.fundingCategory.title}
                  </Typography>
                  {campaignData?.fundingCategory?.title === "Reward" &&
                    campaignData?.reward && (
                      <Typography variant="h6" align="left">
                        {t("selectedNft")} - {campaignData?.reward}
                      </Typography>
                    )}
                </Box>
              </Stack>
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "primary",
                    mt: 4,
                    mr: 2,
                    "&:hover": {
                      backgroundColor: "primary",
                    },
                    "&:focus": {
                      backgroundColor: "primary",
                    },
                  }}
                  disabled={disableClaimInitialPayment || !checkInitialFund}
                  onClick={() => handleShowWalletOptions()}
                >
                  {t("claimInitialPayment")}
                </Button>
                {showWalletButtons && (
                  <Wallets
                    open={showWalletButtons}
                    handleClose={() => setShowWalletButtons(false)}
                    handleSubmit={handleCalimMilestoneOneMoney}
                  />
                )}
              </>
            </Grid>
            <Grid item lg={8} md={6}>
              <Box
                sx={{
                  border: "1px solid #cecbcb",
                  padding: "20px",
                  width: "100%",
                  borderRadius: "4px",
                  mb: 2,
                }}
              >
                <Typography variant="h5" fontWeight={"600"} align="left" mb={1}>
                  {t("image")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xl={10} lg={10}>
                    <Carousel
                      swipeable={true}
                      draggable={false}
                      showDots={campaignData?.images?.length > 1 ? true : false}
                      responsive={responsive}
                      ssr={false} // means to render carousel on server-side.
                      infinite={false}
                      autoPlay={false}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="carousel-container preview-campaign"
                      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                    >
                      {campaignData?.images?.map((item: any) => (
                        <Box
                          sx={{
                            backgroundImage: `url('${URL}/api/file/image/${item}')`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            width: "500px",
                            height: "500px",
                            cursor: "pointer",
                          }}
                          id={
                            item.length > 0
                              ? `${URL}/api/file/image/${item}`
                              : ""
                          }
                        />
                      ))}
                    </Carousel>
                  </Grid>
                </Grid>
              </Box>
              {campaignData.video ? (
                <Box
                  sx={{
                    border: "1px solid #cecbcb",
                    padding: "20px",
                    width: "100%",
                    borderRadius: "4px",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight={"600"}
                    align="left"
                    mb={1}
                  >
                    {t("video")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    <video
                      style={
                        campaignData.video
                          ? { maxWidth: "98%" }
                          : { display: "none" }
                      }
                      src={`${URL}/api/file/video/${campaignData.video}`}
                      controls
                      width={"500px"}
                      height={"auto"}
                      autoPlay={false}
                    />
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              {campaignData.youTubeLink ? (
                <Box
                  sx={{
                    border: "1px solid #cecbcb",
                    padding: "20px",
                    width: "100%",
                    borderRadius: "4px",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight={"600"}
                    align="left"
                    mb={1}
                  >
                    {t("youtubeoptional")}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignData.youTubeLink &&
                      (() => {
                        const id = getYouTubeID(campaignData.youTubeLink);
                        return (
                          <YouTube
                            videoId={id}
                            opts={{
                              height: "300",
                              width: "75%",
                              playerVars: {
                                autoplay: 0,
                              },
                            }}
                            style={
                              campaignData.youTubeLink
                                ? { maxWidth: "98%", height: "auto" }
                                : { display: "none" }
                            }
                          />
                        );
                      })()}
                  </Typography>
                </Box>
              ) : (
                " "
              )}
            </Grid>
            {campaignData.milestones && campaignData.milestones.length > 0 ? (
              <Grid item xs={12}>
                <Typography variant="h4" align="left" mb={4}>
                  {t("milestoneInfo")}
                </Typography>
                {campaignData.milestones.map((item: any, index: any) => {
                  return (
                    <Stack spacing={2} key={item.milestoneId}>
                      <Box mb={1}>
                        <Typography
                          variant="h5"
                          fontWeight={"600"}
                          align="left"
                          sx={{
                            marginBottom: "20px",
                          }}
                        >
                          {t("milestone")} {index + 1}
                        </Typography>
                        <Typography variant="h6" mb={1} align="left">
                          <b>{t("title")} - </b>
                          {item.title}
                        </Typography>

                        <div
                          style={{
                            minHeight: "50%",
                            fontSize: "20px",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                          }}
                        >
                          <Typography variant="h6" mb={1} align="left">
                            <b>{t("description")} - </b>
                            {item.definition}
                          </Typography>
                        </div>

                        <Typography
                          variant="h6"
                          mb={1}
                          align="left"
                          style={{ display: "flex" }}
                        >
                          <b>{t("submissionStatus")} - </b>
                          <Box
                            sx={{
                              paddingLeft: "0.175rem",
                              color:
                                item.status === "pending" ||
                                item.status === "in-progress"
                                  ? "#FFA500"
                                  : item.status === "completed" ||
                                    item.status === "report-submitted"
                                  ? "#00FF00"
                                  : item.status === "rejected"
                                  ? "#ff0000"
                                  : "#000",
                              textTransform: "capitalize",
                            }}
                          >
                            {item.status}
                          </Box>
                        </Typography>
                        <Typography variant="h6" mb={1} align="left">
                          <b>{t("submissionDeadline")} - </b>
                          {moment(item.deadline).format("MMM DD YYYY")}
                        </Typography>
                        {item.isReforestationProject === true && (
                          <Box>
                            <Typography variant="h6" mb={1} align="left">
                              <b>{t("yearlyOutput")} - </b>
                              {item.expectedYearlyoutput}
                            </Typography>
                            <Typography variant="h6" mb={1} align="left">
                              <b>{t("costPerUnit($)")} - </b>
                              {item.expectedCostPerUnit}
                            </Typography>
                            <Box>
                              <Typography variant="h6" mb={1} align="left">
                                <b>{t("expectedResults")} - </b>
                              </Typography>
                              <div
                                style={{
                                  minHeight: "50%",
                                  fontSize: "20px",
                                  whiteSpace: "pre-wrap",
                                  wordWrap: "break-word",
                                }}
                              >
                                <Typography variant="h6" mb={1} align="left">
                                  {item.expectedResults}
                                </Typography>
                              </div>{" "}
                            </Box>
                          </Box>
                        )}

                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "primary",
                            mt: 4,
                            mr: 2,
                            "&:hover": {
                              backgroundColor: "primary",
                            },
                            "&:focus": {
                              backgroundColor: "primary",
                            },
                          }}
                          disabled={Object.values(milestoneDisablity)[index]}
                          onClick={() => handleSubmitMilestone(index)}
                        >
                          {t("submitMilestone")} {index + 1} {t("report")}
                        </Button>
                        {/* {index === 0 &&
                        !Object.values(milestoneDisablity)[index] ? (
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            fontSize={10}
                            mt={1}
                          >
                            * Before submitting milestone one report you have to
                            sign a start milestone transaction
                          </Typography>
                        ) : (
                          ""
                        )} */}
                      </Box>
                    </Stack>
                  );
                })}
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={12}>
              <Box sx={{ textAlign: "left" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.buttongrey",
                    mt: 4,
                    mr: 2,
                    "&:hover": {
                      backgroundColor: "primary.buttongreydark",
                    },
                    "&:focus": {
                      backgroundColor: "primary.buttongreydark",
                    },
                  }}
                  onClick={handleGoBack}
                >
                  {t("back")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

export default ViewMyCampaign;
