import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { InputFiles } from "typescript";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import { filename, PhotoUpload } from "./mintNftCreatorStyles";
import { useAppDispatch, useAppSelector } from "../../../../store";
import Wallets from "../../../../wallets";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import { mintNft, handleIPFSUpload } from "../../../../requests/authRequest";
import { useWallet } from "@txnlab/use-wallet";
import { client, peraWallet } from "../../../../wallets/algorand";
import { create } from "ipfs-http-client";
import { createNftUser } from "../../../../blockchain";
import algosdk from "algosdk";
import { useTranslation } from "react-i18next";

/// @ts-ignore
const ipfsClient = create("https://ipfs.infura.io:5001/api/v0");

function MintNftCreator() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const { t } = useTranslation();
  // related to image upload
  const [file, setFile] = useState<InputFiles | undefined>();
  const [fileName, setFileName] = useState<string>("");
  const [fileSelected, setFileSelected] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const fileRef = useRef<HTMLElement>();

  const user = useAppSelector((state) => state.userReducer);

  // related to nft title
  const [title, setTitle] = useState<string>("");

  const [invalidTitle, setInvalidTitle] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>("");

  // related to nft description
  const [description, setDescription] = useState<string>("");
  const [invalidDescription, setInvalidDescription] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<string>("");

  // related to nft price
  const [goalAmount, setGoalAmount] = useState<string>("");
  const [invalidGoalAmount, setInvalidGoalAmount] = useState<boolean>(false);
  const [errorGoalAmount, setErrorGoalAmount] = useState<string>("");

  // related to img
  const [emptyImage, setEmptyImage] = useState<boolean>();

  const dispatch = useAppDispatch();

  const handleUploadChange = (e: any) => {
    const tempFile = e.target.files;
    let tempFileType: string = tempFile[0].name
      .split(".")
      [tempFile[0].name.split(".").length - 1].toLowerCase();
    let isImage: boolean = false;
    // this part set the file type to display
    if (tempFileType && tempFileType.length > 0) {
      if (
        tempFileType === "jpeg" ||
        tempFileType === "jpg" ||
        tempFileType === "png" ||
        tempFileType === "gif" ||
        tempFileType === "svg"
      ) {
        setFileType("image");
        isImage = true;
      } else if (tempFileType === "mp4" || tempFileType === "webm") {
        setFileType("video");
        isImage = false;
      } else {
        clearfileData();
        const toastPaylaod = {
          text: t("pleaseSelectJpegJpgPngGifSvgMp4WebmFile"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        return;
      }
    }
    // this part actually accpet and set the file data for the form
    if (
      tempFile[0] !== undefined &&
      tempFile[0].name.length > 0 &&
      Math.floor(tempFile[0].size / 1000000) < 10
    ) {
      setFileSelected(true);
      setFile(tempFile[0]);
      setFileName(tempFile[0].name);
      if (isImage && fileRef && fileRef.current) {
        fileRef.current.style.backgroundImage = `url(${URL.createObjectURL(
          tempFile[0]
        )})`;
      } else {
        setVideoUrl(URL.createObjectURL(tempFile[0]));
      }
    }

    // this one checks for a valid size 10mb
    if (tempFile[0].size / 1000000 > 10) {
      clearfileData();
      const toastPaylaod = {
        text: t("pleaseSelectFileLessThanTenMb"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      return;
    }
  };

  const clearfileData = () => {
    setFile(undefined);
    setFileName("");
    setFileSelected(false);
    setFileType("");
    if (fileRef && fileRef.current) {
      fileRef.current.style.backgroundImage = "none";
    }
  };

  const validateForm = () => {
    let validity = true;
    // for title
    if (title.trim().length <= 0) {
      validity = false;
      setInvalidTitle(true);
      setErrorTitle("Please enter the title");
    } else if (title.length > 5) {
      validity = false;
      setInvalidTitle(true);
      setErrorTitle("Title must be 5 char or smaller");
    } else if (!/^[a-zA-Z]+$/.test(title)) {
      validity = false;
      setInvalidTitle(true);
      setErrorTitle("Title must be all characters");
    } else {
      setInvalidTitle(false);
      setErrorTitle("");
    }

    // for description
    if (description.trim().length <= 0 || description.trim().length > 3000) {
      validity = false;
      setInvalidDescription(true);
      setErrorDescription("Please enter the description of length 1 to 3000");
    } else {
      setInvalidDescription(false);
      setErrorDescription("");
    }

    // for goal amount
    // if (
    //   isNaN(parseInt(goalAmount)) ||
    //   parseInt(goalAmount) <= 0 ||
    //   parseInt(goalAmount) > 1000000
    // ) {
    //   validity = false;
    //   setInvalidGoalAmount(true);
    //   setErrorGoalAmount("Please enter a amount between 0 and 1000000");
    // } else {
    //   setInvalidGoalAmount(false);
    //   setErrorGoalAmount("");
    // }

    // for file selected
    if (!fileSelected || file === undefined) {
      validity = false;
      setEmptyImage(true);
    } else {
      setEmptyImage(false);
    }

    return validity;
  };

  // const handleBlockchainSubmit = async () => {
  //   const valid = validateForm();
  //   if (!valid) {
  //     return;
  //   }
  //   dispatch(toggleAppLoading(true));
  //   dispatch(setAppLoadingText(t("creatingNft")));
  //   const myAlgoConnect = new MyAlgoConnect();

  //   if (!walletClient.connected()) {
  //     await walletClient.connect();
  //   }
  //   dispatch(setAppLoadingText(t("uplodingToIpfs")));
  //   // @ts-ignore
  //   // const ipfsHash = await ipfsClient.add(file);

  //   // const url = `https://ipfs.infura.io/ipfs/${ipfsHash.path}`;

  //   //------------

  //   let payload = {
  //     file: file,
  //     userAppId: user.userAppId,
  //   };
  //   const response = await handleIPFSUpload(payload);

  //   const url = response.data.url;

  //   //------------

  //   const info = {
  //     app_id: user.userAppId,
  //     user_name: user.name,
  //     user_type: user.role,
  //     unit_name: title,
  //     asset_name: "Cashdillo Reward",
  //     image_hash: url,
  //     description: description,
  //   };

  //   dispatch(setAppLoadingText(t("sendingDataToBlockchain")));
  //   const unsignedTxnRes = await createNftUser(info);

  //   if (unsignedTxnRes.success) {
  //     try {
  //       dispatch(setAppLoadingText(t("signingTransaction")));

  //       const signedTxns: any = await myAlgoConnect.signTransaction(
  //         unsignedTxnRes.data[0].txn
  //       );

  //       dispatch(setAppLoadingText(t("waitingForConfirmation")));
  //       let tx = await client.sendRawTransaction(signedTxns.blob).do();
  //       let confirmedTxn = await algosdk.waitForConfirmation(
  //         client,
  //         tx.txId,
  //         4
  //       );
  //       let assetID = confirmedTxn["asset-index"];
  //       dispatch(setAppLoadingText(""));
  //       dispatch(toggleAppLoading(false));
  //       const toastPaylaod = {
  //         text: t("nftMintedSuccessfully"),
  //         success: true,
  //         active: true,
  //       };
  //       dispatch(setToaster(toastPaylaod));
  //       clearfileData();
  //       setTitle("");
  //       setDescription("");
  //       setGoalAmount("");
  //     } catch (err) {
  //       dispatch(setAppLoadingText(""));
  //       dispatch(toggleAppLoading(false));
  //       console.log("error is :", err);
  //       const toastPaylaod = {
  //         text: t("errorMintingNft"),
  //         success: false,
  //         active: true,
  //       };
  //       dispatch(setToaster(toastPaylaod));
  //     }
  //   } else {
  //     dispatch(toggleAppLoading(false));
  //     dispatch(setAppLoadingText(""));
  //     const toastPaylaod = {
  //       text: t("errorMintingNft"),
  //       success: false,
  //       active: true,
  //     };
  //     dispatch(setToaster(toastPaylaod));
  //   }
  // };

  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };

  const handleBlockchainSubmit = async () => {
    const valid = validateForm();
    if (!valid) {
      return;
    }
    dispatch(toggleAppLoading(true));
    dispatch(setAppLoadingText(t("creatingNft")));
    dispatch(setAppLoadingText(t("uplodingToIpfs")));
    // @ts-ignore
    // const ipfsHash = await ipfsClient.add(file);

    // const url = `https://ipfs.infura.io/ipfs/${ipfsHash.path}`;

    //------------

    let payload = {
      file: file,
      userAppId: user.userAppId,
    };
    const response = await handleIPFSUpload(payload);

    const url = response.data.url;

    //------------

    const info = {
      app_id: user.userAppId,
      user_name: user.name,
      user_type: user.role,
      unit_name: title,
      asset_name: "Cashdillo Reward",
      image_hash: url,
      description: description,
    };

    dispatch(setAppLoadingText(t("sendingDataToBlockchain")));
    const unsignedTxnRes = await createNftUser(info);

    if (unsignedTxnRes.success) {
      try {
        dispatch(setAppLoadingText(t("signingTransaction")));
        dispatch(toggleAppLoading(false));
        const transactionArr = unsignedTxnRes.data.map((data: any) => {
          return {
            txn: algosdk.decodeUnsignedTransaction(
              Buffer.from(data.txn, "base64")
            ),
          };
        });
        const currentProvider = providers?.find(
          (wallet: any) => wallet.isActive
        );
        await currentProvider?.reconnect();
        const encodedTxns = transactionArr.map((txn: any) => {
          return algosdk.encodeUnsignedTransaction(txn.txn);
        });
        const signedTxn = await signTransactions(encodedTxns);
        dispatch(toggleAppLoading(true));

        dispatch(setAppLoadingText(t("waitingForConfirmation")));
        const transationRes = await client
          .sendRawTransaction(
            signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
          )
          .do();

        let confirmedTxn = await algosdk.waitForConfirmation(
          client,
          transationRes.txId,
          4
        );
        let assetID = confirmedTxn["asset-index"];
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        const toastPaylaod = {
          text: t("nftMintedSuccessfully"),
          success: true,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        clearfileData();
        setTitle("");
        setDescription("");
        setGoalAmount("");
      } catch (err) {
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        console.log("error is :", err);
        const toastPaylaod = {
          text: t("errorMintingNft"),
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
      }
    } else {
      dispatch(toggleAppLoading(false));
      dispatch(setAppLoadingText(""));
      const toastPaylaod = {
        text: t("errorMintingNft"),
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
  };

  const handleSubmit = async () => {
    const valid = validateForm();
    if (!valid) {
      return;
    }
    const info = {
      file: file,
      title: title,
      description: description,
      price: goalAmount,
    };
    dispatch(toggleAppLoading(true));
    const res = await mintNft(info);
    dispatch(toggleAppLoading(false));
    if (res.success) {
      const toastPaylaod = {
        text: t("nftMintedSuccessfully"),
        success: true,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      clearfileData();
      setTitle("");
      setDescription("");
      setGoalAmount("");
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
  };

  return (
    <>
      <Box mb={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h5" align="left" mb={2}>
            {t("mintNft")}
          </Typography>
        </Box>
        <Box>
          <Box ref={fileRef} sx={PhotoUpload} style={{ position: "relative" }}>
            {fileName?.length > 0 && (
              <IconButton onClick={clearfileData}>
                <CancelIcon />
              </IconButton>
            )}
            {fileType === "video" && file !== undefined && (
              <video width={"100%"} src={videoUrl} controls />
            )}
          </Box>
          {emptyImage && (
            <FormHelperText
              sx={{ marginBottom: 3 }}
              className="css-l8pll-MuiFormHelperText-root Mui-error"
            >
              {t("pleaseSelectImageToUpload")}
            </FormHelperText>
          )}
          <Typography sx={filename}>
            {fileName.length > 30 ? `${fileName.slice(0, 27)}...` : fileName}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            <label htmlFor="mint-nft-button">
              <input
                style={{ display: "none" }}
                className="inputfile"
                accept=".jpeg, .png, .mp4, .webm, .jpg, .svg, .gif"
                id="mint-nft-button"
                onChange={handleUploadChange}
                onClick={(e: any) => {
                  e.target.value = "";
                }}
                multiple={false}
                type="file"
              />
              <Button
                variant="text"
                component="span"
                className="uploadButton"
                startIcon={<FileUploadIcon />}
              >
                {t("fileUpload")}
              </Button>
            </label>
            <Typography>{t("supportedFileTypes")}</Typography>
          </Stack>

          <TextField
            sx={{ marginBottom: 3 }}
            fullWidth
            hiddenLabel
            type={"text"}
            placeholder={t("unitNameNftSymbol") || ""}
            variant="outlined"
            onChange={(e) => {
              setTitle(e.target.value?.toUpperCase());
            }}
            value={title?.toUpperCase()}
            error={invalidTitle}
            helperText={invalidTitle && errorTitle}
            inputProps={{ maxLength: 5 }}
          />
          <TextField
            sx={{ marginBottom: 3 }}
            fullWidth
            hiddenLabel
            multiline
            minRows={4}
            type={"text"}
            placeholder={t("description") || ""}
            variant="outlined"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
            error={invalidDescription}
            helperText={invalidDescription && errorDescription}
          />
          {/* <TextField
          fullWidth
          hiddenLabel
          type={"number"}
          placeholder="NFT price(ALGO)"
          variant="outlined"
          onChange={(e) => {
            if (parseInt(e.target.value) > 1000000) {
              setGoalAmount("1000000");
            } else {
              setGoalAmount(e.target.value);
            }
          }}
          value={goalAmount}
          error={invalidGoalAmount}
          helperText={invalidGoalAmount && errorGoalAmount}
        /> */}

          <Button
            sx={{ margin: "20px 0px" }}
            // onClick={handleSubmit}
            onClick={() => setShowWalletButtons(true)}
            variant="contained"
          >
            {t("mint")}
          </Button>
        </Box>
      </Box>
      {showWalletButtons && (
        <Wallets
          open={showWalletButtons}
          handleClose={() => setShowWalletButtons(false)}
          handleSubmit={handleBlockchainSubmit}
        />
      )}
    </>
  );
}

export default MintNftCreator;
